/* eslint-disable eol-last */
export const statusMap = [
  {
    value: 'takeHome',
    label: 'Take Home',
    color: '#657889',
  },
  {
    value: 'takeHomeRejected',
    label: 'Take Home Rejected',
    color: '#E74C3C',
  },
  {
    value: 'blankTakeHome',
    label: 'Blank Take Home',
    color: '#BDC3C7',
  },
  {
    value: 'rejectNoVisa',
    label: 'Reject No Visa',
    color: '#2ECC71',
  },
  {
    value: 'finalRoundScheduled',
    label: 'Final Round Scheduled',
    color: '#ED7D51',
  },
  {
    value: 'rejectBelowTechnicalBar',
    label: 'Reject - Below Technical Bar',
    color: '#DB4B4C',
  },
  {
    value: 'queuedForDirectorChat',
    label: 'Queued for Director Chat',
    color: '#97C1D9',
  },
  {
    value: 'hired',
    label: 'Hired',
    color: '#8CBA80',
  },
  {
    value: 'rejectEricInterview',
    label: 'Eric Rejected',
    color: '#DB4B4C',
  },
  {
    value: 'waitingOnFinalDecision',
    label: 'Waiting on Final Round Decision',
    color: 'grey',
  },
  {
    value: 'rejectGhosted',
    label: 'Reject - Ghosted',
    color: '#FFF7AC',
    textColor: 'black',
  },
  {
    value: 'queuedForFinalRound',
    label: 'Queued for Final Round',
    color: '#658E9C',
  },
  {
    value: 'takeHomeCooldown',
    label: 'Take Home Cooldown',
    color: '#F39C12',
  },
  {
    value: 'finalRoundCooldown',
    label: 'Final Round Cooldown',
    color: '#2980B9',
  },
  {
    value: 'declinedInterview',
    label: 'Reject - Declined Interview',
    color: 'grey',
  },
];

export const archivedSubmissionStatusMap = [
  'takeHomeRejected',
  'rejectNoVisa',
  'rejectBelowTechnicalBar',
  'rejectEricInterview',
  'rejectGhosted',
];