import { sendGetRequest } from '../../utils/network';
import dayjs from 'dayjs';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function checkAuthentication() {
  // Skip authentication when developing locally
  if (process.env.NODE_ENV !== 'production') {
    localStorage.removeItem('tokenExpired');
    return true;
  }

  // /checkLogin returns true if the token is still valid
  let retries = 0;
  let checkLoginResponse;

  // Retry up to 4 times if the server is slow to respond
  while (retries < 4) {
    checkLoginResponse = await sendGetRequest(`/checklogin`);
    if (checkLoginResponse?.data) {
      break;
    }
    retries++;

    // Wait 3 seconds before retrying
    await wait(3000);
  }

  // Check if token is stale
  const isTokenStale = !checkLoginResponse?.data?.logged_in;

  // Get token expiration from local storage
  const tokenExpiration = localStorage.getItem('tokenExpiration');

  // Return true if token is within 1 minutes of expiration
  const tokenNearingExpiration = tokenExpiration ? dayjs.utc().isAfter(dayjs.utc(tokenExpiration).subtract(1, 'minute')) : true;

  // Authentication does not pass if token is invalid or we are within 1 minutes of expiration
  if (isTokenStale || tokenNearingExpiration) {
    return false;
  } else {
    localStorage.removeItem('tokenExpired');
    return true;
  }
}
