import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton } from "material-react-table";
import { useMemo, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';

//Material UI Imports
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Box, lighten } from '@mui/material';

// Component and variable imports
import { statusMap } from '../helpers/constants';
import EditableCell from '../helpers/EditableCell';
import { handleSubmissionChange, handleCandidateInfoChange } from '../helpers/hiringHelpers';
import ArchivedTableDetailPanel from "./ArchivedTableDetailPanel";
import { unarchiveSingleSubmission } from '../api';
import Toast from '../../Layout/Toast';

function ArchivedTable({ data }) {
  const [toast, setToast] = useState(<></>);
  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );

  const handleUnArchiveSubmission = async (rows) => {
    let result = await unarchiveSingleSubmission({ id: rows[0].original.id });

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success
      ? `Submission(s) unarchived.`
      : 'Something went wrong! Unable to unarchive submission(s).';

    setToast(<Toast title={title} message={message} isSuccess={success} />);

    await queryClient.refetchQueries({ queryKey: ['combinedData'] });
    await table.resetRowSelection();
  };

  const columns = useMemo(() => {
    return [
      // ========================= CANDIDATE NAME =========================
      {
        header: 'Candidate Name',
        accessorKey: 'candidateName',
        accessorFn: (row) => {
          return ({
            candidate: row.candidate,
            firstName: row.candidate.firstName,
            lastName: row.candidate.lastName,
          });
        },
        Cell: ({ cell }) => {
          const { candidate, firstName, lastName } = cell.getValue();
          const initialValue = `${firstName} ${lastName}`;

          // Handle saving the new name
          const handleSave = (newValue) => {
            const [newFirstName, newLastName] = newValue.split(/\s(.+)/);
            handleCandidateInfoChange(
              candidate.id,
              {
                firstName: newFirstName || '',
                lastName: newLastName || '',
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Candidate Name"
            />
          );
        },
      },
      // ========================= CANDIDATE EMAIL =========================
      {
        accessorFn: (row) => row?.candidate.email,
        id: 'email',
        header: 'Candidate Email',
        enableClickToCopy: true,
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.email || '';

          // Handle saving the new email
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                email: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Email"
            />
          );
        },
      },
      // ========================= CANDIDATE LOCATION =========================
      {
        accessorFn: (row) => row.candidate.location ? row.candidate.location : "N/A",
        id: 'location',
        header: 'Location',
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.location || '';

          // Handle saving the new location
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                location: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Location"
            />
          );
        },
      },
      // ========================= INTERVIEW STATUS =========================
      {
        accessorFn: (row) => ({
          submission: row,
          status: row.status,
        }), //accessorFn used to join multiple data into a single cell
        id: 'statusId', //id is still required when using accessorFn instead of accessorKey
        header: 'Status',

        Cell: ({ cell, row }) => {
          const { submission, status } = cell.getValue();

          const matchedStatus = statusMap.find((s) => s.value === status);

          return (
            <>
              <select
                id="status"
                name="status"
                style={{
                  backgroundColor: matchedStatus?.color || 'blue',
                  color: matchedStatus?.textColor || 'white',
                  textAlign: 'center',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  overflow: 'clip',
                  fontSize: '14px',
                }}
                value={status}
                onChange={(e) => {
                  handleSubmissionChange(
                    submission.id,
                    { status: e.target.value },
                    setValue,
                    queryClient,
                    row,
                  );
                }}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
    ];
  }, [data, value]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    autoResetPageIndex: false,
    enableRowSelection: true,
    enableExpanding: true,

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: ({ row }) => <ArchivedTableDetailPanel row={row} data={data} />,
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #D3D3D3',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* ===============HEADER HIDE COLS BUTTON=============== */}
            <MRT_ShowHideColumnsButton table={table} />

            <button
              disabled={!table.getIsSomeRowsSelected()}
              style={{
                cursor: table.getIsSomeRowsSelected() ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                handleUnArchiveSubmission(table.getSelectedRowModel().rows, table);
              }}
            >
              <UnarchiveIcon
                style={{
                  color: !table.getIsSomeRowsSelected() ? 'rgb(235, 235, 228)' : 'gray',
                }}
              />
            </button>
          </Box>
        </Box>
      );
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      {toast}
    </div>
  );
}

export default ArchivedTable;
