import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/tanstackNetwork';
import { Box } from '@mui/material';
import { Table } from './Table';
import { CompetitionLeaderboard } from './CompetitionLeaderboard';
import './HowlUtilization.css';
import { Header } from './Header';
import WolfLoader from '../WolfLoader/WolfLoader';
import { HowlUtilizationData } from './types';

export default function HowlUtilization() {
  const { data, isFetching, isError, error } = useQuery<HowlUtilizationData>({
    queryKey: ['howlUtilization'],
    queryFn: () => sendGetRequest('/howl-utilization'),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (isFetching) return <WolfLoader customText="Loading howl utilization data..." />;
  if (isError) return <div>Error: {error.message}</div>;
  if (!data) return <div>No data</div>;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Header startDate={data.currentCycleStartDate} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%', height: { xs: 'auto', xl: '100%' } }}>
          <CompetitionLeaderboard data={data} />
        </Box>
        <Box sx={{ width: '100%', height: { xs: 'auto', xl: '100%' } }}>
          <Table data={data.topUsersByTestCount} />
        </Box>
      </Box>
    </Box>
  );
}
