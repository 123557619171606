import { Disclosure, Menu, Transition } from '@headlessui/react';
import { useMutationState } from '@tanstack/react-query';
import {
  ArrowPathRoundedSquareIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
  EnvelopeOpenIcon,
  InboxArrowDownIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  TrashIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

import { parseISO, differenceInMinutes } from 'date-fns';
import { Fragment } from 'react';
import { classNames, getBackgroundColor, getElapsed, plural } from '../helpers';

/**
 * Renders a message card component.
 * @param {object} props - The component props.
 * @param {import('../../../types').QAWTask} props.task - The task object.
 * @param {Function} props.toggleClaimed - The function to toggle the claimed status of the task.
 * @param {object} props.user - The user object.
 * @param {Function} props.suiteAction - The function to perform an action on the task.
 * @param {boolean} [props.nested=false] - Indicates if the card is nested within another component.
 * @param {Function} props.setNewTaskModalData - The function to set data for the new task modal.
 * @param {Function} props.setShowNewTaskModal - The function to set the visibility of the new task modal.
 * @returns {import('react').JSX.Element} The rendered message card component.
 */
export function MessageCard({ task, toggleClaimed, user, suiteAction, nested = false, setNewTaskModalData, setShowNewTaskModal }) {
  let platform = 'Slack';
  if (task?.name?.includes('MS Teams')) {
    platform = 'MS Teams';
  }

  const title = `${task.team.name} ${platform} Message`;
  const thisSuiteAge = getElapsed(parseISO(task?.createdAt));
  const claimedByAnyone = task.assignedTo?.name ? true : false;
  const claimedByCurrentUser = task.assignedTo?.id === user.qawId;
  const { url: slackUrl, message } = task.notes;

  let timeSinceClaimed;
  let overAnHourTriage = false;
  if (!nested) {
    let now = new Date();

    // How long has this particular task has been claimed for? Has it been over an hour?
    let timeFromActivityLog = task.lastAssignedAt;
    if (timeFromActivityLog) {
      let isoTime = parseISO(timeFromActivityLog);
      let diffInMinutes = differenceInMinutes(now, isoTime);
      overAnHourTriage = diffInMinutes > 60;
      timeSinceClaimed = getElapsed(isoTime);
    }
  }

  async function openFollowUpModal() {
    setNewTaskModalData({ team: task.team, taskId: task.id, user: user, message: { url: slackUrl, text: message }, task: task });
    setShowNewTaskModal(true);
  }

  function ClaimButton({ task }) {
    const mutations = useMutationState({
      filters: { mutationKey: ['taskMutation'] },
      select: (mutation) => mutation.state,
    });

    const isPending = mutations.find((x) => x.variables?.task?.id === task.id && x.status === 'pending' && x.variables?.endpoint === '/claim');
    const isSuccess = mutations.find((x) => x.variables?.task?.id === task.id && x.status === 'success' && x.variables?.endpoint === '/claim');

    return (
      <button
        className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
        onClick={() => toggleClaimed(task, true)}
        disabled={isPending || isSuccess}
      >
        {isPending ? 'Claiming...' : isSuccess ? 'Success!' : 'Claim'}
      </button>
    );
  }

  function ThreadCompleteButton({ task }) {
    const mutations = useMutationState({
      filters: { mutationKey: ['taskMutation'] },
      select: (mutation) => mutation.state,
    });

    const isPending = mutations.find((x) => x.variables?.task?.id === task.id && x.status === 'pending' && x.variables?.endpoint === '/done');
    const isSuccess = mutations.find((x) => x.variables?.task?.id === task.id && x.status === 'success' && x.variables?.endpoint === '/done');

    return (
      <button
        className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20 hover:ring-2 hover:bg-green-100"
        onClick={() => suiteAction(task, '/done')}
        disabled={isPending || isSuccess}
      >
        {isPending ? 'Updating...' : isSuccess ? 'Success!' : 'Thread Complete'}
      </button>
    );
  }

  const threadCompleteButton = <ThreadCompleteButton task={task} />;

  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <div className={classNames('rounded-t-xl flex items-center gap-x-4 border-b border-gray-900/5 p-4', getBackgroundColor(task))}>
        <div className="w-full grid grid-cols-5 ">
          {/* Team image & Client name  */}
          <div className="overflow-hidden flex justify-start text-sm font-medium col-span-2">
            <img
              className="h-6 w-6 rounded-full mr-2"
              src={task.primaryQaTeam?.imageUrl || 'https://cdn-icons-png.flaticon.com/512/3524/3524335.png'}
              alt={`${task.primaryQaTeam?.teamName} Team - ${task.primaryQaTeam?.name}`}
              title={`${task.primaryQaTeam?.teamName} Team - ${task.primaryQaTeam?.name}`}
            />
            <p className="truncate flex ext-gray-900 max-w-2/5" title={`${title} | ID ${task.id}`}>
              {title}
            </p>
          </div>
          <div className="flex justify-end col-span-3 xl:gap-1 2xl:gap-2">
            {!claimedByAnyone ? (
              // Only show claim button if unclaimed & not nested
              !nested && <ClaimButton task={task} />
            ) : (
              <>
                {/* Assigned user deatils if claimed */}
                <div className="flex justify-right xl:gap-1 2xl:gap-2">
                  <img className="h-6 w-6 rounded-full" src={task.assignedTo?.avatarUrl} alt={task.assignedTo?.name} title={task.assignedTo?.name} />
                  {/* Only show the user's name if it's not the current user to save space */}
                  {claimedByCurrentUser || <h3 className={classNames('truncate text-sm', 'font-medium text-gray-900')}>{task.assignedTo?.name}</h3>}
                </div>

                {/* Done buttons if not nested & claimed by current user */}
                {!nested && claimedByCurrentUser && (
                  <>
                    {threadCompleteButton}
                    <button
                      className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-blue-700 bg-blue-50 ring-blue-600/20 hover:ring-2 hover:bg-blue-100"
                      onClick={openFollowUpModal}
                    >
                      Followup Required
                    </button>
                  </>
                )}
              </>
            )}

            {/* 'See' button always visible */}
            <a
              href={slackUrl}
              target="_blank"
              rel="noreferrer"
              className="rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              See
            </a>

            {/* Action Menu */}
            <Menu
              as="div"
              className="relative rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/20 hover:ring-2 hover:bg-gray-100"
            >
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <EllipsisHorizontalIcon className="h-4 w-4" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-24 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  {claimedByAnyone &&
                    (claimedByCurrentUser ? (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                            onClick={() => toggleClaimed(task, false)}
                          >
                            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Unclaim
                          </button>
                        )}
                      </Menu.Item>
                    ) : (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-yellow-900')}
                            onClick={() => toggleClaimed(task, true, slackUrl)}
                          >
                            <InboxArrowDownIcon className="h-5 w-5 pt-1 mr-1" /> Claim
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(active ? 'bg-gray-100' : '', 'w-full flex px-3 py-1 text-sm leading-6 text-red-600')}
                        onClick={() => suiteAction(task, '/ignore')}
                      >
                        <TrashIcon className="h-5 w-5 pt-1 mr-1" /> Delete
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
        <div className="py-2">
          <dt className="flex justify-between gap-x-3 text-gray-500 -ml-1">
            <div className="flex w-1/2">
              <div className="w-1/10">
                <EnvelopeOpenIcon className="mt-0.5 w-5 h-5 text-red-500" />
              </div>
              <p className="truncate ml-2 text-gray-500" title={message}>
                {message}
              </p>
            </div>
            <div className={classNames('flex', 'text-gray-500')}>
              <ClockIcon className={classNames('w-5 h-5  mt-0.5 mr-1', 'text-gray-500')} title={`Message recieved: ${thisSuiteAge}`} />
              <p title={`Message recieved: ${thisSuiteAge}`}>{thisSuiteAge}</p>
              {timeSinceClaimed && (
                <div className={classNames('flex ml-4', overAnHourTriage ? 'text-yellow-500' : 'text-gray-500')}>
                  <ArrowPathRoundedSquareIcon
                    className={classNames('w-5 h-5  mt-0.5 mr-1', overAnHourTriage ? 'text-yellow-500' : 'text-gray-500')}
                    title={`Time since claimed: ${timeSinceClaimed}`}
                  />
                  <p title={`Time since claimed: ${timeSinceClaimed}`}>{timeSinceClaimed}</p>
                </div>
              )}
              {task.handovers > 1 && (
                <div className={classNames('flex ml-4 text-yellow-500')}>
                  {task.handovers < 3 ? (
                    <UsersIcon
                      className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                      title={`This task was claimed and unclaimed ${task.handovers} times.`}
                    />
                  ) : (
                    <UserGroupIcon
                      className={classNames('w-5 h-5  mt-0.5 mr-1 text-yellow-500')}
                      title={`This task was claimed and unclaimed ${task.handovers} times.`}
                    />
                  )}
                  <p title={`This task was claimed and unclaimed ${task.handovers} times.`}>{task.handovers}</p>
                </div>
              )}
            </div>
          </dt>
        </div>
      </dl>
      {task.childTasks?.length > 0 && (
        <div className="rounded-b-xl bg-gray-200 text-center">
          <div className="text-sm">
            <Disclosure as="div" key={`${task.id}-disclosure`}>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500">
                      <span>
                        {task.childTasks.length} Previous Thread Message{plural(task.childTasks.length)}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? <MinusSmallIcon className="h-6 w-6" aria-hidden="true" /> : <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2">
                    {task.childTasks.map((prevMsgTask) => (
                      <div key={prevMsgTask.id} className="p-2">
                        <MessageCard
                          task={prevMsgTask}
                          toggleClaimed={toggleClaimed}
                          user={user}
                          nested={true}
                          suiteAction={suiteAction}
                          setShowNewTaskModal={setShowNewTaskModal}
                          setNewTaskModalData={setNewTaskModalData}
                        />
                      </div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      )}
    </div>
  );
}
