import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);

export default function ExportSuitesDetailsToCsvButton({ filteredSuiteData, customerNameOrId }) {

  // Define the headers
  const headers = [
    "ID",
    "Trigger Name",
    "Start Time (PT)",
    "Initial Run Completed At (PT)",
    "Last Updated At Time (PT)",
    "Run Time (Minutes)",
    "Investigation Time (Minutes)",
    "Total Time (Minutes)",
    "Superseded Total Time (Minutes)",
    "CI/CD Overhead",
    "Bugged Workflows (New)",
    "Flake Percent",
    "Success Percent",
    "Workflows Run",
  ];

  const downloadCsv = () => {
    let csvContent = headers.join(",") + "\n";

    // Helper function to escape double quotes and wrap in double quotes
    const escapeForCsv = (value) => {
      if (value === null || value === undefined) return '';
      const escapedValue = value.toString().replace(/"/g, '""');
      return `"${escapedValue}"`;
    };

    // Process each suite to generate the CSV rows
    filteredSuiteData.forEach((suite) => {

      const id = escapeForCsv(suite.id);
      const triggerName = escapeForCsv(suite.triggerName);
      const startTime = escapeForCsv(suite.startTimeString);
      const initialRunCompletedAt = escapeForCsv(
        dayjs.tz(suite.startTime, 'America/Los_Angeles')
          .add(suite.runTimeInMinutes, 'minute')
          .tz('America/Los_Angeles')
          .format('MMMM D, YYYY, h:mm A'),
      );
      const lastUpdatedAtTime = escapeForCsv(
        dayjs.tz(suite.startTime, 'America/Los_Angeles')
          .add(suite.totalTimeInMinutes, 'minute')
          .tz('America/Los_Angeles')
          .format('MMMM D, YYYY, h:mm A'),
      );
      const runTime = escapeForCsv(suite.runTimeInMinutes);
      const triageTime = escapeForCsv(suite.triageTimeInMinutes);
      const totalTime = escapeForCsv(suite.totalTimeInMinutes);
      const supersededTotalTime = escapeForCsv(suite.supersededTotalTimeInMinutes);
      const ciCdOverhead = escapeForCsv(suite.ciCdOverhead);
      const numberOfRunsTriagedAsBugged = escapeForCsv(suite.numberOfRunsTriagedAsBug);
      const flakePercent = escapeForCsv(suite.flakePercent);
      const successPercent = escapeForCsv(suite.successPercent);
      const runs = escapeForCsv(suite.numberOfRuns);

      // Create a row string
      const row = [
        id,
        triggerName,
        startTime,
        initialRunCompletedAt,
        lastUpdatedAtTime,
        runTime,
        triageTime,
        totalTime,
        supersededTotalTime,
        ciCdOverhead,
        numberOfRunsTriagedAsBugged,
        flakePercent,
        successPercent,
        runs,
      ].join(",");

      csvContent += row + "\n";
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerNameOrId} Suites Runs Details.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        marginLeft: '10px',
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}
      startIcon={<FileDownload />}
      onClick={downloadCsv}
    >
      Runs Details CSV
    </Button>
  );
}
