import { JSX } from 'react';

function tiktokAuth() {
  // The URL for the TikTok OAuth flow
  const rootUrl = `https://business-api.tiktok.com/portal/auth`;

  // The query string parameters for the TikTok OAuth flow
  const options = {
    app_id: '7431044858736803856',
    state: 'your_custom_params',
    redirect_uri: window.location.origin + '/tiktok/code',
  };

  // Convert the query string parameters to a query string
  const qs = new URLSearchParams(options);

  // Redirect the user to the TikTok OAuth flow
  // At the end of the flow, the user will be redirected to the redirect_uri
  window.location.replace(`${rootUrl}?${qs.toString()}`);
}

export default function TikTokConnector(): JSX.Element {
  return (
    <button
      type="button"
      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
      onClick={() => tiktokAuth()}
    >
      Connect to TikTok
    </button>
  );
}
