import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';

export default function ExportQaeCostButton({ qaEngineerData, qaBenefitsAmount }) {
  const headers = ["Team", "Name", "Position", "Salary", "Benefits"];
  
  // Helper function to escape double quotes and wrap in double quotes
  const escapeForCsv = (value) => {
    if (value === null || value === undefined) return '';
    const escapedValue = value.toString().replace(/"/g, '""');
    return `"${escapedValue}"`;
  };
  
  const downloadCsv = () => {
    let csvContent = headers.join(",") + "\n";
    const annualQaeBenefitsCosts = qaBenefitsAmount;

    qaEngineerData.forEach((user) => {
      if (!user.salary) return;
      const teamName = user.team?.name? escapeForCsv(user.team.name) : '';
      const qaeName = user.name? escapeForCsv(user.name) : '';
      const qaePosition = user.position? escapeForCsv(user.position) : '';
      const salary = user.salary? escapeForCsv(user.salary.toLocaleString('en-US', { style: 'currency', currency: 'USD' })) : '';
      const benefits = escapeForCsv(annualQaeBenefitsCosts.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
      const row = [
        teamName,
        qaeName,
        qaePosition,
        salary,
        benefits,
      ];
      csvContent += row.join(",") + "\n";
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `QAE_Cost_Data.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="absolute top-0 right-0 pt-1 w-10">
        <Button
          startIcon={<FileDownload />}
          onClick={downloadCsv}
        >
        </Button>
    </div>
  );
}
