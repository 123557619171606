import { Tabs, Tab, Paper } from '@mui/material';
import _ from 'lodash';
import { MaterialReactTable, MRT_ColumnDef, MRT_TableInstance, useMaterialReactTable } from 'material-react-table';
import { SyntheticEvent, useMemo, useState } from 'react';
import { UserWithUniqueActiveTestCount } from './types';
import { QaTeam, User } from '../Planning/Summary/types';
import { FilterFn } from '@tanstack/react-table';

export function Table({ data }: { data: UserWithUniqueActiveTestCount[] }) {
  // Get all the unique packs from the data
  const packs = useMemo(
    () =>
      _.uniqBy(
        data.filter((u) => u.team?.pack !== undefined).map((u) => u.team?.pack),
        (x) => x?.id,
      ) as NonNullable<QaTeam['pack']>[],
    [data],
  );

  const storedUser = useMemo<User | null>(() => {
    const userString = localStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  }, []);

  const columns = useMemo<MRT_ColumnDef<UserWithUniqueActiveTestCount>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        enableSorting: false,
      },
      {
        accessorKey: 'pack',
        accessorFn: ({ team }) => team?.pack?.name || 'Other',
        header: 'Pack',
        enableSorting: false,
      },
      {
        accessorKey: 'uniqueActiveTestCount',
        header: 'Tests Activated',
      },
      {
        accessorKey: 'howlUtilizations',
        header: 'Uses',
        accessorFn: ({ howlUtilizations }) => howlUtilizations.length,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    data,
    columns,
    renderTopToolbar: ({ table }) => <PackFilterTabs table={table} packs={packs} />,
    filterFns: {
      packIdFilter,
    },
    initialState: {
      rowPinning: {
        top: storedUser ? [`${storedUser.id}`] : [],
      },
      sorting: [{ id: 'uniqueActiveTestCount', desc: true }],
    },
    displayColumnDefOptions: {
      'mrt-row-pin': {
        header: '',
        size: 50,
      },
    },
    getRowId: (row) => `${row.id}`,
    enableRowPinning: (row) => row.original.id === storedUser?.id,
    keepPinnedRows: false,
    enableStickyHeader: true,
    enablePagination: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    enableFilterMatchHighlighting: false,
    rowPinningDisplayMode: 'top',
    globalFilterFn: 'packIdFilter',
    positionActionsColumn: 'last',
  });

  return <MaterialReactTable table={table} />;
}

function PackFilterTabs({ table, packs }: { table: MRT_TableInstance<UserWithUniqueActiveTestCount>; packs: NonNullable<QaTeam['pack']>[] }) {
  const [selectedPack, setSelectedPack] = useState('');

  const handlePackChange = (_event: SyntheticEvent, value: string) => {
    table.setGlobalFilter(value);
    setSelectedPack(value);
  };

  return (
    <Paper variant="outlined" square>
      <Tabs value={selectedPack} onChange={handlePackChange} aria-label="Pack filter tabs" variant="fullWidth">
        <Tab label="All QAW" value="" disableRipple />
        {packs.map((pack) => (
          <Tab key={pack.id} label={pack.name} value={`${pack.id}`} disableRipple />
        ))}
        <Tab label="Other" value="0" disableRipple />
      </Tabs>
    </Paper>
  );
}

const packIdFilter: FilterFn<UserWithUniqueActiveTestCount> = (row, _columnId, filterValue) => {
  const rowData = row.original;

  // No filter
  if (!filterValue) return true;
  // 'Other' filter (no pack)
  if (filterValue === '0') return !rowData.team?.pack;
  // Filter by pack id
  return `${rowData.team?.pack?.id}` === filterValue;
};
