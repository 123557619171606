import { useState } from 'react';

/**
 * @typedef {object} Shift
 * @property {string} qawId
 * @property {string} name
 * @property {string} [avatar48]
 * @property {string[]} teamIds
 */

/**
 * @typedef {object} Team
 * @property {string} qawId
 * @property {string} teamName
 */

/**
 * @typedef {object} User
 * @property {string} qawId
 */

/**
 * @param {object} props
 * @param {Shift[]} [props.shifts]
 * @param {Team[]} [props.teams]
 * @param {User} props.user
 */

export default function ActiveShiftsDisplay({ shifts, teams, user }) {
  if (!teams?.length) return null;

  const [isExpanded, setIsExpanded] = useState(false);

  // Create a map of teamId -> shifts[] for multiple users per team
  const shiftsByTeamId = {};
  shifts?.forEach((shift) => {
    shift.teamIds.forEach((teamId) => {
      if (!shiftsByTeamId[teamId]) {
        shiftsByTeamId[teamId] = [];
      }
      shiftsByTeamId[teamId].push(shift);
    });
  });

  // Sort teams alphabetically
  const sortedTeams = [...teams].sort((a, b) => a.teamName.localeCompare(b.teamName));

  // Filter teams to only show current user's teams when collapsed
  let teamsToShow = isExpanded
    ? sortedTeams
    : sortedTeams.filter((team) => {
        const teamShifts = shiftsByTeamId[team.qawId] || [];
        return teamShifts.some((shift) => shift.qawId === user.qawId);
      });

  if (!isExpanded && teamsToShow.length === 0) {
    teamsToShow = sortedTeams;
  }

  if (!sortedTeams.length) return null;

  return (
    <div className={`bg-white shadow rounded-lg overflow-hidden flex flex-col min-w-[300px] ${isExpanded ? 'max-h-[400px]' : 'max-h-[150px]'} transition-all duration-100`}>
      <div 
        className="px-3 py-2 bg-gray-50 border-b border-gray-200 cursor-pointer hover:bg-gray-100 select-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center justify-between">
          <h3 className="text-xs font-medium text-gray-500 tracking-wider uppercase">Currently On Shift</h3>
          <span className="text-xs text-gray-400">{isExpanded ? 'Click to collapse' : 'Click to expand'}</span>
        </div>
      </div>
      <div className="overflow-y-auto flex-1">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 top-0 z-10">
            <tr>
              <th scope="col" className="px-3 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2">
                Team(s)
              </th>
              <th scope="col" className="px-3 py-1.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/2">
                QAE
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {teamsToShow.map((team) => {
              const teamShifts = shiftsByTeamId[team.qawId] || [];
              return (
                <tr key={team.qawId} className="hover:bg-gray-50">
                  <td className="px-3 py-1.5 whitespace-nowrap">
                    <div className="flex flex-wrap gap-1">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-50 text-indigo-600">
                        {team.teamName}
                      </span>
                    </div>
                  </td>
                  <td className="px-3 py-1.5">
                    {teamShifts.length > 0 ? (
                      <div className="flex flex-col gap-1">
                        {teamShifts.map((shift) => (
                          <div key={shift.qawId} className="flex items-center">
                            <div className="h-5 w-5 rounded-full bg-gray-300 flex items-center justify-center text-xs text-white">
                              {shift.avatar48 ? (
                                <img src={shift.avatar48} alt={`${shift.name}'s avatar`} className="h-5 w-5 rounded-full" />
                              ) : (
                                `${shift.name.split(' ')[0][0]}${shift.name.split(' ')[1][0]}`
                              )}
                            </div>
                            <span className="ml-2 text-xs text-gray-900">{shift.name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span className="text-xs text-gray-400">None</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
