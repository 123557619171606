import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChartBarIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { TimeTrackingCustomerRow } from './TimeTrackingCustomerRow';

import { trackedCategories } from './timeTrackingHelpers';

export function TrackedTableModal({ title, breakdown, handleShowTrackedDetails }) {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          handleShowTrackedDetails();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="min-w-max relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      handleShowTrackedDetails();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="flex sm:pl-6 lg:pl-8 items-center space-x-2">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ChartBarIcon className="h-6 w-6 grayscale" aria-hidden="true" />
                      </div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {title || 'Tracked Insights'}
                      </Dialog.Title>
                    </div>
                    <div className="mt-2">
                      <table>
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="z-1 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                            >
                              Customer
                            </th>
                            {trackedCategories.map((category, idx) => (
                              <th
                                key={idx}
                                scope="col"
                                className="bg-opacity-75 py-3.5 px-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                              >
                                {category}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {breakdown &&
                            Object.entries(breakdown).map(([customer, data], idx) => (
                              <TimeTrackingCustomerRow
                                key={idx}
                                customer={customer}
                                data={data}
                                categories={trackedCategories}
                                isLastPart={idx !== Object.keys(breakdown).length - 1}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
