import { Fragment, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Switch } from '@headlessui/react';

import WolfLoader from '../../WolfLoader/WolfLoader';
import { classNames } from '../../InvestigationBoard/helpers';

import { NewWeeklyCreationChart, WeeklyCreationChart } from '../charts';
import { WeeklyCreationComparisonTable } from '../tables';
import { getCreationOutlineMaintenanceInsights, handleChangeTeamOnPack, protectFilters, sumAllCreationOutliningMaintenanceTasks } from '../helpers';

import { taskTypeMap } from '../types';
import { WOLF_LOGO } from '../../../constants';

/**
 *
 * @param {object} props
 * @param {import('../types').TeamInsightsFilter} props.filters
 * @param {import('react').Dispatch<import('react').SetStateAction<{team: import('../types').TeamInsightsFilter, qae: import('../types').QAEInsightsFilter, pack: import('../types').PackInsightsFilter}>>} props.setFilters
 * @returns {import('react').JSX.Element}
 */
export default function TeamCreationView({ filters, setFilters }) {
  const [includePack, setIncludePack] = useState(JSON.parse(sessionStorage.getItem('creationIncludePack')) ?? false);

  // protect team filters from potential stale state
  filters = protectFilters(filters);

  // set filter types to ['testCreation', 'outline']
  filters.types = ['testCreation', 'outline'];

  const {
    data: creationInsights,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['creationInsights', filters],
    queryFn: () => getCreationOutlineMaintenanceInsights(filters),
  });

  const {
    data: packCreationInsights,
    fetchStatus: packFetchStatus,
    isError: isPackError,
    error: packError,
  } = useQuery({
    queryKey: ['packCreationInsights', filters],
    queryFn: () =>
      getCreationOutlineMaintenanceInsights({
        entity: {
          type: 'pack',
          data: {
            name: creationInsights.team.packId,
          },
        },
        types: filters.types,
        gte: filters.gte,
        lte: filters.lte,
      }),
    enabled: includePack && creationInsights?.team?.packId !== undefined,
  });

  const handleIncludePack = () => {
    sessionStorage.setItem('creationIncludePack', JSON.stringify(!includePack));
    setIncludePack(!includePack);
  };

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const teamSums = sumAllCreationOutliningMaintenanceTasks(creationInsights.teamTasksByWeekAndType);
  const packSums = packCreationInsights ? sumAllCreationOutliningMaintenanceTasks(packCreationInsights.teamTasksByWeekAndType) : null;

  return (
    <div className="min-h-screen px-4 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-6 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          {filters.entity.type === 'team' && <img src={creationInsights.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />}
          <span className="font-semibold">{creationInsights[filters.entity.type].name}</span>
        </div>
        <Switch.Group as="div" className="flex flex-1 justify-end items-center pr-4">
          {packFetchStatus === 'fetching' && (
            <Switch.Label as="span" className="flex items-center justify-center h-10 mr-2 text-sm">
              <img src={WOLF_LOGO} alt="loadingTeam" className="h-6 w-auto animate-spin-slow" />
            </Switch.Label>
          )}
          {isPackError && (
            <Switch.Label as="span" className="h=10 ml-3 text-sm">
              Error: {packError.message}
            </Switch.Label>
          )}
          <Switch
            checked={includePack}
            onClick={handleIncludePack}
            className={classNames(
              includePack ? 'bg-blue-400' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                includePack ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3 text-sm">
            <span className="font-medium text-gray-900">Include Pack</span>
          </Switch.Label>
        </Switch.Group>
      </div>
      <div className="isolate flex-col divide-y divide-gray-200 border">
        <div className="isolate flex divide-x divide-gray-200">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tests Completed: {teamSums.sumTests}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tasks/Workflows Completed: {teamSums.sumTasks}
          </span>
          {includePack && packSums && (
            <>
              <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
                Pack Total Tests Completed: {packSums.sumTests}
              </span>
              <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
                Pack Total Tasks/Workflows Completed: {packSums.sumTasks}
              </span>
            </>
          )}
        </div>
        {(teamSums || (includePack && packSums)) && (
          <div className="isolate flex divide-x divide-gray-200">
            {[teamSums, includePack ? packSums : null].filter(Boolean).map((sumGroup, sumGroupIdx) => (
              <Fragment key={sumGroupIdx}>
                {['sumTests', 'sumTasks'].map((sumType, sumTypeIdx) => (
                  <span
                    key={sumTypeIdx}
                    className="flex-col space-y-2 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-sm"
                  >
                    <p>
                      Creation - {sumType === 'sumTests' ? 'Tests' : 'Workflows'} {sumGroup.sumsByType.testCreation?.[sumType] || 0}
                    </p>
                    <p>
                      Outline - {sumType === 'sumTests' ? 'Tests' : 'Tasks'} {sumGroup.sumsByType.outline?.[sumType] || 0}
                    </p>
                  </span>
                ))}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="py-4">
        <NewWeeklyCreationChart data={creationInsights} />
      </div>
      <div className="border p-4 grid 2xl:grid-cols-2 4xl:grid-cols-3 grid-flow-row gap-4">
        {creationInsights.team &&
          creationInsights.team.members
            .filter((m) => !m.isManager)
            .sort((a, b) => {
              // Put the lead at the end of the list
              if (a.isLead && !b.isLead) return 1;
              if (!a.isLead && b.isLead) return -1;

              return a.name.localeCompare(b.name);
            })
            .map((member, memberIndex) => {
              const qaeCreationInsights = {
                qae: member,
                qaeTasksByWeekAndType: creationInsights.teamTasksByWeekAndType.filter((x) => x.completed_by_qaw_id === member.qawId),
              };
              const qaeSums = sumAllCreationOutliningMaintenanceTasks(qaeCreationInsights.qaeTasksByWeekAndType);
              return (
                <div key={memberIndex} className="shadow">
                  <WeeklyCreationComparisonTable
                    creationInsights={qaeCreationInsights}
                    qaeSums={qaeSums}
                    teamCreationInsights={creationInsights}
                    teamSums={teamSums}
                  />
                </div>
              );
            })}
      </div>
      {includePack && packCreationInsights && (
        <div>
          <div className="flex space-x-4 py-4 items-center justify-start">
            <div className="flex flex-col justify-center items-center">
              <img src={packCreationInsights.pack.members.find((m) => m.isManager)?.avatar48} alt="avatar" className="h-14 w-auto rounded-full" />
              <span className="font-semibold">{packCreationInsights.pack.name}</span>
            </div>
            <div className="flex flex-col flex-1 justify-end items-end">
              <span className="text-sm text-gray-600">Other teams in this pack:</span>
              <div className="flex gap-x-2">
                {packCreationInsights.packTeams
                  .filter((t) => t.name !== creationInsights.team.name)
                  .map((t, tIdx) => (
                    <span key={tIdx} className="text-xs text-gray-400">
                      <button onClick={() => handleChangeTeamOnPack(t.name, setFilters)}>{t.name}</button>
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className="isolate flex-col divide-y divide-gray-200 border">
            <div className="isolate flex divide-x divide-gray-200">
              <span className="group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium">
                Total Tasks Completed: {packSums.sumTasks}
              </span>
              <span className="group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium">
                Total Tests Completed: {packSums.sumTests}
              </span>
            </div>
            {packSums.sumsByType && (
              <div className="isolate flex divide-x divide-gray-200">
                {Object.entries(packSums.sumsByType).map(([type, sums]) => (
                  <span
                    key={type}
                    className="flex-col space-y-2 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium"
                  >
                    <p>
                      {taskTypeMap[type]} - {type === 'testCreation' ? 'Workflows' : 'Tasks'} Completed: {sums.sumTasks}
                    </p>
                    <p>
                      {taskTypeMap[type]} - Tests {type === 'testCreation' ? 'Completed' : 'Outlined'}: {sums.sumTests}
                    </p>
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col 2xl:flex-row py-4">
            <div className="2xl:w-2/3">
              <WeeklyCreationChart creationInsights={packCreationInsights} comparisonInsights={creationInsights} isPack={true} />
            </div>
            <div className="flex-1 flex items-start justify-between border 2xl:border-l-0 border-b-0">
              {/* We might put some sort of Pack/Team comparison table here in the future */}
              <span className="w-full h-full text-center">Some sort of pack -:- team comparison</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
