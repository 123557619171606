import { classNames } from '../../InvestigationBoard/helpers';

export function TimeTrackingCustomerRow({ customer, data, categories, isLastPart }) {
  if (customer && data) {
    return (
      <tr>
        <td
          className={classNames(
            isLastPart ? 'border-b border-gray-200' : '',
            'bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 bg-opacity-90 backdrop-blur backdrop-filter',
          )}
        >
          {customer}
        </td>
        {data &&
          categories.map((category, idx) => (
            <td
              className={classNames(
                isLastPart ? 'border-b border-gray-200' : '',
                'bg-white whitespace-nowrap px-3 py-4 text-sm text-gray-500 bg-opacity-90',
              )}
              key={idx}
            >
              {data[category]}
            </td>
          ))}
      </tr>
    );
  }
}
