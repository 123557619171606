import { Box, Button, Tooltip } from '@mui/material';
import { StoredQaTeam, usePlanningContext } from '../../context/PlanningContext';
import { sendPostRequest } from '../../../utils/tanstackNetwork';
import { QueryClient, useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { ClientSummaryTableRow } from './types';

export default function ApproveAllButton() {
  const queryClient = useQueryClient();
  const { clientSummaries, isMoreThanOneQaTeamSelected, selectedQaTeamIds, qaTeams, selectedWeek } = usePlanningContext();
  const selectedQaTeam = qaTeams.find((team) => selectedQaTeamIds.includes(team.id)) as StoredQaTeam;

  const projectPlanTasks = clientSummaries.data?.filter((s) => s.incompleteProjectPlanTask).map((s) => s.incompleteProjectPlanTask);

  const approveAllMutation = useMutation({
    mutationKey: ['approveAllProjectPlanTasks', selectedQaTeam.id],
    mutationFn: ({ qaTeamId }: { qaTeamId: number; weekString: string }) => sendPostRequest(`/project-plan-tasks/${qaTeamId}/approve-all`),
    onSuccess: (data: { successTaskIds: string[] }, variables: { qaTeamId: number; weekString: string }) => {
      updateClientSummariesCache(queryClient, data.successTaskIds, variables.weekString);
    },
  });

  const handleApproveAll = () => {
    if (selectedQaTeam) {
      // Mutate with the qaTeamId and weekString so cache can be updated
      approveAllMutation.mutate({ qaTeamId: selectedQaTeam.id, weekString: selectedWeek.format('YYYY-MM-DD') });
    }
  };

  const isLoading = clientSummaries.isLoading || clientSummaries.isPending || clientSummaries.isFetching;
  const isApproving = useIsMutating({ mutationKey: ['approveAllProjectPlanTasks', selectedQaTeam?.id] });
  const hasNoProjectPlanTasks = projectPlanTasks?.length === 0;

  const disableApproveAllButton = isLoading || isMoreThanOneQaTeamSelected || !!isApproving || hasNoProjectPlanTasks;

  const tooltipText = hasNoProjectPlanTasks
    ? `All ${selectedQaTeam?.teamName} project plans are already approved`
    : isMoreThanOneQaTeamSelected
    ? 'Please select a single QA team to approve all project plans'
    : isLoading || isApproving
    ? 'Loading...'
    : `Approve all project plans for ${selectedQaTeam?.teamName} (${projectPlanTasks?.length})`;

  const buttonText = isApproving ? 'Approving...' : 'Approve Project Plans';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Tooltip title={tooltipText}>
        <span>
          <Button
            sx={{ width: '15rem' }}
            variant="contained"
            size="medium"
            disabled={disableApproveAllButton}
            disableRipple
            onClick={handleApproveAll}
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}

/**
 * Updates the React Query cache for client summaries after project plans are approved
 */
function updateClientSummariesCache(queryClient: QueryClient, successTaskIds: string[], weekString: string) {
  queryClient.setQueryData(['clientSummaries', weekString], (data: ClientSummaryTableRow[] | undefined) => {
    if (!data) return [];
    const updatedData = data.map((clientSummary) => {
      const projectPlanTaskId = clientSummary.incompleteProjectPlanTask?.id;
      // If the project plan task is in the successTaskIds array
      // set incompleteProjectPlanTask to undefined and set isProjectPlanComplete to true
      if (projectPlanTaskId && successTaskIds.includes(projectPlanTaskId)) {
        return { ...clientSummary, incompleteProjectPlanTask: undefined, isProjectPlanComplete: true };
      }
      return clientSummary;
    });
    return updatedData;
  });
}
