import { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/network';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Avatar, Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import UserSelect from './UserSelect';
import CustomerSelect from './CustomerSelect';
import dayjs from 'dayjs';

/**
 * @typedef {object} User
 * @property {number} teamId
 * @property {string} [avatar48]
 * @property {string} [avatarUrl]
 */

/**
 * @typedef {object} Customer
 * @property {number} qaTeamId
 * @property {string} officialName
 */

/**
 * @typedef {object} QueryResults
 * @property {object} [data]
 * @property {Customer[]} [data.data]
 * @property {boolean} isLoading
 * @property {boolean} isError
 */

/**
 * @param {object} props
 * @param {(task: {taskName: string, assignee: User, dueDate: string, customer: Customer | null}) => Promise<void>} props.onTaskCreate
 * @param {{id: number}[]} [props.enabledTeams]
 * @returns {import('react').JSX.Element}
 */
export default function CreateNewTaskModal ({ onTaskCreate, enabledTeams }) {
  const [open, setOpen] = useState(false);
  const defaultUser = JSON.parse(localStorage.getItem('user'));
  const [showErrors, setShowErrors] = useState(false);
  const [formData, setFormData] = useState({
    taskName: '',
    assignee: defaultUser,
    dueDate: dayjs(),
    customer: null,
  });

  const results = useQueries({
    queries: [
      {
        queryKey: ['getAllQAWUsers'],
      },
      {
        queryKey: ['customers'],
        queryFn: () => sendGetRequest('/client-teams/get-clients'),
      },
    ],
  });

  const isLoading = results.some((result) => result.isLoading) ? true : false;
  const isError = results.some((result) => result.isError) ? true : false;

  if (isLoading) return <span>Loading...</span>;
  if (isError) return <span>Error: {'Failed to load data'}</span>;

  const [users, customers] = results.map((result) => result.data);

  const filteredCustomers = customers?.data?.filter((customer) => 
    customer.qaTeamId === defaultUser?.teamId || 
    enabledTeams?.some((team) => team.id === customer.qaTeamId),
  );

  if (!filteredCustomers.length) filteredCustomers[0] = { officialName: 'No customers found, please select a team' };

  const isFormValid = formData.taskName !== '' && 
    formData.customer !== null && 
    formData.dueDate !== null &&
    formData.assignee !== null;

  const handleSubmit = async () => {
    setShowErrors(true);
    if (!isFormValid) return;
    await onTaskCreate({
      ...formData,
      dueDate: formData.dueDate?.format('YYYY-MM-DD'),
    });
    setFormData({ taskName: '', assignee: defaultUser, dueDate: dayjs(), customer: null });
    setShowErrors(false);
    setOpen(false);
  };

  return (
    <>
      <Button 
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        className="w-1/5"
      >
        New Task
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create New Generic Task</DialogTitle>
        <DialogContent>
          <Stack spacing={2} padding="6px">
            <TextField
              autoFocus
              label="Task Name"
              fullWidth
              required
              error={showErrors && formData.taskName.trim() === ''}
              helperText={showErrors && formData.taskName.trim() === '' ? 'Task name is required' : ''}
              value={formData.taskName}
              onChange={(e) => setFormData({ ...formData, taskName: e.target.value })}
            />
            <CustomerSelect
              customers={filteredCustomers}
              selectedCustomer={formData.customer}
              setSelectedCustomer={(value) => setFormData({ ...formData, customer: value })}
              required
              error={showErrors && !formData.customer}
              helperText={showErrors && !formData.customer ? 'Customer is required' : ''}
            />
            <DatePicker
              label="Due Date"
              required
              value={formData.dueDate}
              minDate={dayjs()}
              format="LL"
              onChange={(date) => setFormData({ ...formData, dueDate: date })}
              slotProps={{ 
                textField: { 
                  fullWidth: true,
                  required: true,
                  error: showErrors && !formData.dueDate,
                  helperText: showErrors && !formData.dueDate ? 'Due date is required' : '',
                },
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%', padding: '4px' }}>
              {formData.assignee && (
                <Avatar 
                  src={formData.assignee?.avatar48 || formData.assignee?.avatarUrl} 
                  sx={{ width: 50, height: 50 }}
                >
                  QA
                </Avatar>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <UserSelect
                  users={users}
                  selectedPerson={formData.assignee}
                  setSelectedPerson={(value) => setFormData({ ...formData, assignee: value })}
                  required
                  error={showErrors && !formData.assignee}
                  helperText={showErrors && !formData.assignee ? 'Assignee is required' : ''}
                />
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
