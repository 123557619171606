import { ArrowBack, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, Typography } from '@mui/material';
import { useState } from 'react';
import { Content, Inner, Header, Title, ScrollBox, StyledDrawer, SubDrawerBtn } from './subComponents';
import { DataCard } from './DataCard';
import SubMilestone from './SubMilestone';
import { getIsCurrent, getRequiredVelocity } from './utils';
import dayjs from 'dayjs';
import { Milestone, ClientSummaryTableRow } from '../types';

export function MsDrawer({ milestone, closeParents, client }: { milestone: Milestone; closeParents: () => void; client: ClientSummaryTableRow }) {
  // Set to open if it is the current milestone
  const [isOpen, setIsOpen] = useState(getIsCurrent(milestone));

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeAll = () => {
    toggleSelf();
    closeParents();
  };

  const isCurrent = getIsCurrent(milestone);
  const pastWeeks = milestone.subMilestones.filter((sm) => dayjs().isAfter(dayjs(sm.dueDate)));
  const totalTestsCompleted = pastWeeks.at(-1) ? pastWeeks.at(-1)?.actualTests : milestone.subMilestones.at(-1)?.actualTests;
  const remainingWeeks = milestone.subMilestones.filter((sm) => dayjs(sm.dueDate).isAfter(dayjs().startOf('day')));
  const requiredVelocity = getRequiredVelocity(milestone, totalTestsCompleted as number, remainingWeeks.length);

  return (
    <Box>
      <SubDrawerBtn onClick={toggleSelf} text={`${milestone.name} ${isCurrent ? `(Current)` : ``}`} />
      <StyledDrawer anchor="right" open={isOpen} onClose={closeAll} variant="persistent">
        <Inner>
          <Header>
            <IconButton onClick={toggleSelf} disableRipple>
              <ArrowBack />
            </IconButton>
            <Title text={`${client.name}`} />
          </Header>
          <Content>
            <DataCard milestone={milestone} requiredVelocity={requiredVelocity} />
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', p: '.5em' }}>
              <Box sx={{ width: '100%' }}></Box>
              <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                <Typography variant="caption">Tests Created / Req'd</Typography>
              </Box>
              <Box sx={{ width: '100%', textAlign: 'center', mx: 0.5 }}>
                <Typography variant="caption">Total Active / Expected</Typography>
              </Box>
            </Box>
            <ScrollBox sx={{ height: '60vh' }}>
              {!!pastWeeks.length && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>Past Weeks</AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {pastWeeks.map((sm, i) => {
                      const previousWeek = pastWeeks[i - 1];
                      const initialTestCountThisWeek = previousWeek ? previousWeek.actualTests : milestone.initialTestCount;
                      const thisWeeksTestCount = sm.actualTests - (initialTestCountThisWeek as number);

                      // Calculate the required velocity for this week, using the previous week's actual tests as a baseline
                      const requiredVelocityThisWeek =
                        i === 0
                          ? Math.round(getRequiredVelocity(milestone, milestone.initialTestCount as number, milestone.subMilestones.length - i))
                          : Math.round(getRequiredVelocity(milestone, previousWeek.actualTests, milestone.subMilestones.length - i));

                      return (
                        <SubMilestone
                          key={sm.id}
                          subMilestone={sm}
                          thisWeeksTestCount={thisWeeksTestCount}
                          requiredVelocityThisWeek={requiredVelocityThisWeek}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}
              <List>
                {remainingWeeks.map((sm) => {
                  // Calculate the number of tests created this week, using the last week's actual tests as a baseline or
                  // the milestone's initial test count if this is the first week
                  const lastWeek = pastWeeks.at(-1);
                  const initialTestCountThisWeek = lastWeek
                    ? lastWeek.actualTests
                    : !isNaN(milestone.initialTestCount as number)
                    ? milestone.initialTestCount
                    : sm.actualTests;
                  const thisWeeksTestCount = sm.actualTests - (initialTestCountThisWeek as number);
                  const requiredVelocityThisWeek = Math.round(
                    getRequiredVelocity(milestone, lastWeek?.actualTests || sm.actualTests, remainingWeeks.length),
                  );

                  return (
                    <SubMilestone
                      key={sm.id}
                      subMilestone={sm}
                      thisWeeksTestCount={thisWeeksTestCount}
                      requiredVelocityThisWeek={requiredVelocityThisWeek}
                    />
                  );
                })}
              </List>
            </ScrollBox>
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
