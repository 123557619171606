import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';

export default function CustomerSelect({ 
  customers, 
  selectedCustomer, 
  setSelectedCustomer, 
  required, 
  error, 
  helperText,
}) {
  const sortedCustomers = useMemo(() => 
    [...customers].sort((a, b) => a.officialName.localeCompare(b.officialName))
  , [customers]);

  return (
    <Autocomplete
      value={selectedCustomer}
      onChange={(event, newValue) => setSelectedCustomer(newValue ? newValue : null)}
      options={sortedCustomers}
      getOptionLabel={(option) => option.officialName}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Customer" 
          size="small"
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
} 
