import { Navigate, useLocation } from 'react-router-dom';
import { checkAuthentication } from './helpers';
import { logout } from '../LoginPage/authActions';
import React from 'react';
import WolfLoader from '../WolfLoader/WolfLoader';

export default function ProtectedRoute(props) {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);
  const location = useLocation();

  React.useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await checkAuthentication();
      setIsAuthenticated(authStatus);
    };
    checkAuth();
  }, [location]);

  if (isAuthenticated === null) {
    return <WolfLoader />; // or return null
  }

  if (!isAuthenticated) {
    console.log('User is no longer authenticated');
    logout('true');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
