import { Autocomplete, TextField, Avatar } from '@mui/material';

export default function UserSelector({ users, selectedPerson, setSelectedPerson, required, error, helperText }) {
  const sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));
  
  return (
    <Autocomplete
      value={selectedPerson}
      onChange={(event, newValue) => setSelectedPerson(newValue)}
      options={sortedUsers}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option.qawId === value.qawId}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Assignee" 
          size="small" 
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {option?.avatar48 || option?.avatarUrl ? (
              <Avatar src={option.avatar48 || option.avatarUrl} sx={{ width: 24, height: 24 }} />
            ) : (
              <Avatar sx={{ width: 24, height: 24, bgcolor: '#3D3DF5' }}>QA</Avatar>
            )}
            <span>{option.name}</span>
          </div>
        </li>
      )}
    />
  );
}
