import {
  Modal,
  Paper,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { sendPostRequest, sendPutRequest } from '../../utils/network';
import Toast from '../Layout/Toast';
import WolfLoader from '../WolfLoader/WolfLoader';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import dayjs from 'dayjs';

const initialBlockerReason = `**Blocked Because:**\n\n**What We Need to Get Unblocked:**\n\n**Date of Last Follow Up:** ${dayjs().format(
  'MM/DD/YYYY',
)}\n\n[Link to Last Follow Up](url)`;

function BlockedReasonModal({ showBlockedReasonModal, setShowBlockedReasonModal, originalBlockedTask, setOriginalBlockedTask, setToast }) {
  // State for error handling
  const [titleError, setTitleError] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [followUpError, setFollowUpError] = useState(false);

  // State for mutation/API request data
  const [blockerTitle, setBlockerTitle] = useState('');
  const [blockerReason, setBlockerReason] = useState(initialBlockerReason);
  const [followUpDate, setFollowUpDate] = useState(dayjs().add(1, 'week').format('YYYY-MM-DD'));
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedExistingBlocker, setSelectedExistingBlocker] = useState(null);
  const [selectedExistingCrIssueId, setSelectedExistingCrIssueId] = useState(null);

  // State for disabling name input when the CR is fully blocked (all tasks are selected)
  const [isFullyBlocked, setIsFullyBlocked] = useState(false);
  const [splitCr, setSplitCr] = useState(false);

  // Resets all state vars
  const resetState = () => {
    // Reset show modal state
    setShowBlockedReasonModal(false);

    // Reset error state
    setTitleError(false);
    setReasonError(false);
    setFollowUpError(false);

    // Reset data state
    setBlockerTitle('');
    setBlockerReason(initialBlockerReason);
    setFollowUpDate('');
    setBlockerTitle('');
    setOriginalBlockedTask(null);
    setSelectedTaskIds([]);
    setSelectedExistingBlocker(null);
    setSelectedExistingCrIssueId(null);
    setIsFullyBlocked(false);
    setSplitCr(false);
  };

  useEffect(() => {
    if (originalBlockedTask && (originalBlockedTask.type === 'testCreation' || originalBlockedTask.type === 'testMaintenance')) {
      setSelectedTaskIds([originalBlockedTask.id]);
    }

    // Handle setting outline task to blocked
    if (originalBlockedTask && originalBlockedTask.type === 'outline') {
      // If the blocked task is an outline, it should be fully blocked
      setIsFullyBlocked(true);
    }

    // Handle CRs with 1 test creation task (should be fully blocked)
    if (originalBlockedTask && originalBlockedTask.type === 'testCreation' && crWorkflowTasks.length === 1) {
      setIsFullyBlocked(true);
    }
  }, [originalBlockedTask]);

  // Tasks that could be added to the blocked CR
  const crWorkflowTasks = originalBlockedTask?.parentTask?.childTasks?.filter((t) => t.type === 'testCreation') || [];
  const maintenanceWorkflowTasks =
    originalBlockedTask?.parentTask?.childTasks?.filter((t) => t.type === 'testMaintenance') ||
    originalBlockedTask?.childTasks?.filter((t) => t.type === 'testMaintenance') ||
    [];

  // Get queryClient to mutate cached task data
  const queryClient = useQueryClient();

  // Get existing customer blockers
  /**@type {import('../../types').QAWTask[]} */
  const allTasks = queryClient.getQueryData(['allTasks', originalBlockedTask?.team?.id]) || queryClient.getQueryData(['allTasks']) || [];
  const customerTasks = allTasks.filter((t) => t.team.id === originalBlockedTask?.team?.id);
  const parentTaskType = originalBlockedTask?.type === 'testMaintenance' ? 'testMaintenance' : 'testCoverageRequest';
  const tasksWithBlockers = customerTasks.filter((t) => t.type === parentTaskType && (t.blocker || t.childTasks?.some((t) => t.blocker)));

  const blockerIds = new Set();
  const customerBlockers = [];

  for (const task of tasksWithBlockers) {
    // Find all of the blockers affecting the current task
    const blockers = task.blocker ? [task.blocker] : task.childTasks?.filter((t) => t.blocker).map((t) => t.blocker);

    for (const blocker of blockers.filter((b) => !blockerIds.has(b.id))) {
      blockerIds.add(blocker.id);

      // Find CR issues affected by the blocker
      const crIssuesAffectedByBlocker = tasksWithBlockers
        .filter(
          (t) =>
            t.blocker?.blockedCrIssueId === blocker.blockedCrIssueId ||
            t.childTasks?.find((t) => t.blocker?.blockedCrIssueId === blocker.blockedCrIssueId),
        )
        .map((t) => t.issue);

      // Add the blocker to the list of blockers
      customerBlockers.push({ ...blocker, crIssuesAffectedByBlocker });
    }
  }

  // Mutation to update the blocked workflow tasks/create blocked CR
  const createBlockerMutation = useMutation({
    /**
     * @param {import('../../types').blockedWorkflowData} blockedWorkflowData
     * @returns {Promise}
     */
    mutationFn: async (blockedWorkflowData) => await sendPostRequest(`/set-tasks-to-blocked`, blockedWorkflowData),
    onSettled: (data) => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] }).finally(() => {
        resetState();
        if (data.success === true) {
          return setToast(
            <Toast
              title={'Blocker Created Successfully 🎉'}
              message={'Task statuses have been updated'}
              key={new Date().toISOString()}
              isSuccess={true}
            />,
          );
        } else {
          console.log('Task update failed:', data.error);
          return setToast(
            <Toast
              title={'Updating tasks failed'}
              message={'Sorry, there was an problem! The Dragons team has been notified'}
              key={new Date().toISOString()}
              isSuccess={false}
            />,
          );
        }
      });
    },
  });

  // Mutation to add workflows to an existing blocked CR
  const addToBlockerMutation = useMutation({
    /**
     * @param {object} updateBlockerData
     * @param {import('../../types').existingBlocker} updateBlockerData.existingBlocker
     * @param {import('../../types').QAWTask[]} updateBlockerData.blockedChildTasks
     * @param {import('../../types').QAWTask} updateBlockerData.parentTask
     * @returns {Promise}
     */
    mutationFn: async (updateBlockerData) => await sendPutRequest(`/add-tasks-to-blocker`, updateBlockerData),
    onSettled: (data) => {
      queryClient.refetchQueries({ queryKey: ['allTasks'] }).finally(() => {
        resetState();
        if (data.success === true) {
          const message = selectedExistingCrIssueId ? 'Tasks have been added to the selected existing blocked CR' : 'Task statuses have been updated';
          return setToast(<Toast title={'Blocker Updated Successfully 🎉'} message={message} key={new Date().toISOString()} isSuccess={true} />);
        } else {
          console.log('Task update failed:', data.error);
          return setToast(
            <Toast
              title={'Updating tasks failed'}
              message={'Sorry, there was an problem! The Dragons team has been notified'}
              key={new Date().toISOString()}
              isSuccess={false}
            />,
          );
        }
      });
    },
  });

  const handleSelectBlockedTask = (e) => {
    const filteredSelectValues = e.target.value.filter((taskId) => taskId !== 'select-all-tasks');
    const childTasks = originalBlockedTask.type === 'testMaintenance' ? maintenanceWorkflowTasks : crWorkflowTasks;
    const hasSelectedAllTasks = filteredSelectValues.length === childTasks.length;

    // Handle select all
    if (e.target.value.includes('select-all-tasks') && !hasSelectedAllTasks) {
      setIsFullyBlocked(true);
      return setSelectedTaskIds(childTasks.map((t) => t.id));
    }

    // Handle unselect all
    if (e.target.value.includes('select-all-tasks') && hasSelectedAllTasks) {
      setIsFullyBlocked(false);
      return setSelectedTaskIds([originalBlockedTask.id]);
    }

    // Handle individual selection/deselection
    setIsFullyBlocked(hasSelectedAllTasks);
    setSplitCr(false);
    return setSelectedTaskIds(filteredSelectValues);
  };

  const handleClose = () => {
    // Reset the blocked task status back to it's original value
    queryClient.setQueryData(
      ['allTasks'],
      /**
       * @param {import('../../types').QAWTask[]} oldData
       * @returns {import('../../types').QAWTask[]} - Updated customer task data
       */
      (oldData) => {
        if (!oldData) return oldData;
        const remappedData = oldData.map((task) => {
          // Find the parent task
          if (task.id === originalBlockedTask.parentTask.id && task.childTasks) {
            // Find the child task and reset its status
            const newChildTasks = task.childTasks.map((childTask) => {
              if (childTask.id === originalBlockedTask.id) {
                return { ...childTask, status: originalBlockedTask.status };
              }
              return childTask;
            });

            return { ...task, status: originalBlockedTask.status, childTasks: newChildTasks };
          }
          return task;
        });
        return remappedData;
      },
    );

    // Reset the data and modal state
    return resetState();
  };

  const handleSelectExistingCrIssue = (e) => {
    setSelectedExistingCrIssueId(e.target.value);
  };

  const handleSubmit = () => {
    // Get the blocked child tasks based on the blocked task type
    const blockedChildTasks = (originalBlockedTask.type === 'testMaintenance' ? maintenanceWorkflowTasks : crWorkflowTasks).filter((bt) =>
      selectedTaskIds.includes(bt.id),
    );

    // Handle creating a new blocked CR or updating the original if fully blocked
    if (!selectedExistingBlocker) {
      // Validate the form data
      if (!blockerTitle.length || !blockerReason || blockerReason === initialBlockerReason || !followUpDate) {
        setTitleError(!blockerTitle.length);
        setReasonError(!blockerReason.length || blockerReason === initialBlockerReason);
        setFollowUpError(!followUpDate.length);
        return;
      }

      return createBlockerMutation.mutate({
        blockedChildTasks,
        teamName: originalBlockedTask.parentTask.team.name,
        blockerTitle,
        blockerReason,
        followUpDate,
        parentTask: originalBlockedTask.parentTask,
        isOutlineTask: originalBlockedTask.type === 'outline',
        isMaintenanceTask: originalBlockedTask.type === 'testMaintenance',
        splitCr,
        existingBlockedCrIssueId: selectedExistingCrIssueId,
      });
    }

    // Handle adding tasks to an existing blocked CR or the original
    return addToBlockerMutation.mutate({
      existingBlocker: selectedExistingBlocker,
      existingBlockedCrIssueId: selectedExistingCrIssueId,
      blockedChildTasks,
      parentTask: originalBlockedTask.parentTask,
      isMaintenanceTask: originalBlockedTask.type === 'testMaintenance',
    });
  };

  // Show loading modal while the API request is being made TODO: Update these
  const loadingMessage = splitCr ? 'Creating New Blocked CR and Updating task statuses...' : 'Updating Task Statuses...';
  const isLoading = createBlockerMutation.isPending || addToBlockerMutation.isPending;

  return isLoading ? (
    <LoadingModal open={true} handleClose={handleClose} message={loadingMessage} />
  ) : (
    <Modal open={showBlockedReasonModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {selectedExistingBlocker
            ? `Add Tasks to Existing Blocker`
            : originalBlockedTask?.type !== 'outline'
            ? `Create New Blocker`
            : `Block this CR/Outline`}
        </Typography>
        {!selectedExistingBlocker && <Typography variant="caption">Please provide additional details before setting tasks to blocked.</Typography>}
        {
          <Autocomplete
            freeSolo
            options={customerBlockers.map((b) => ({ ...b, label: b.title }))}
            value={selectedExistingBlocker ?? ''}
            onChange={(_event, newValue) => {
              setSelectedExistingBlocker(newValue);
              setTitleError(false);
              setReasonError(false);
              setFollowUpError(false);
              setSelectedExistingCrIssueId(originalBlockedTask?.parentIssue?.id);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={`${option.label}-${option.id}`}>
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                value={blockerTitle}
                label="Create blocker title or Select existing blocker"
                placeholder={'e.g. "Missing user data"'}
                fullWidth
                required
                error={titleError}
                onChange={(e) => {
                  setTitleError(false);
                  setBlockerTitle(e.target.value);
                  setSelectedExistingBlocker(null);
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mt: 2 }}
              />
            )}
          />
        }

        {/* Select menu with existing affected CRs - Defaults to current CR, includes all other CRs affected by selected blocker */}
        {originalBlockedTask?.type !== 'testMaintenance' && selectedExistingBlocker && !isFullyBlocked && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="existing-blocked-cr-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select existing affected CR to add tasks to
            </InputLabel>
            <Select labelId="existing-blocked-cr-label" value={selectedExistingCrIssueId} onChange={handleSelectExistingCrIssue}>
              <MenuItem value={originalBlockedTask.parentIssue.id}>
                {originalBlockedTask.parentIssue.name}
                <i>{' - Current CR'}</i>
              </MenuItem>
              {selectedExistingBlocker.crIssuesAffectedByBlocker
                ?.filter((crIssue) => crIssue.id !== originalBlockedTask.parentIssue.id)
                ?.map((crIssue) => (
                  <MenuItem key={crIssue.id} value={crIssue.id}>
                    {crIssue.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        <MDEditor
          value={!selectedExistingBlocker ? blockerReason : selectedExistingBlocker.description}
          onChange={(val) => {
            setReasonError(false);
            setBlockerReason(val || '');
          }}
          preview={selectedExistingBlocker ? 'preview' : 'edit'}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          height={200}
          data-color-mode="light"
          textareaProps={{
            placeholder: 'Enter detailed reason for blocked status',
            disabled: selectedExistingBlocker !== null,
          }}
          style={{
            marginTop: 10,
            boxSizing: 'border-box',
            outline: reasonError ? '1px solid red' : 'none',
          }}
          hideToolbar={selectedExistingBlocker}
        />
        <TextField
          label="Next Follow-up Date"
          type="date"
          fullWidth
          required
          disabled={selectedExistingBlocker !== null}
          error={followUpError}
          value={!selectedExistingBlocker ? followUpDate : ''}
          onChange={(e) => {
            setFollowUpError(false);
            setFollowUpDate(e.target.value);
          }}
          inputProps={{
            min: dayjs().format('YYYY-MM-DD'),
          }}
          sx={{ mt: 2 }}
          InputLabelProps={{ shrink: true }}
        />
        {maintenanceWorkflowTasks.length > 0 && originalBlockedTask.type !== 'outline' && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="additional-blocked-workflow-tasks-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select workflow creation tasks affected by this blocker
            </InputLabel>
            <Select
              multiple
              labelId="additional-blocked-workflow-tasks-label"
              value={selectedTaskIds}
              onChange={handleSelectBlockedTask}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((taskId) => (
                    <Chip key={taskId} label={maintenanceWorkflowTasks.find((t) => t.id === taskId).workflow.name} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="select-all-tasks">
                <Checkbox checked={selectedTaskIds.length === maintenanceWorkflowTasks.length} />
                {isFullyBlocked ? 'Unselect all' : 'Select all'}
              </MenuItem>
              {maintenanceWorkflowTasks.map((task) => {
                return (
                  <MenuItem key={task.id} value={task.id} disabled={task.id === originalBlockedTask.id}>
                    <Checkbox checked={selectedTaskIds.includes(task.id)} />
                    {task.workflow.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {crWorkflowTasks.length > 0 && originalBlockedTask.type !== 'outline' && (
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="additional-blocked-workflow-tasks-label" disableAnimation shrink sx={{ backgroundColor: '#fff', ml: -1, pl: 1, pr: 1 }}>
              Select workflow creation tasks affected by this blocker
            </InputLabel>
            <Select
              multiple
              labelId="additional-blocked-workflow-tasks-label"
              value={selectedTaskIds}
              onChange={handleSelectBlockedTask}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((taskId) => (
                    <Chip key={taskId} label={crWorkflowTasks.find((t) => t.id === taskId).workflow.name} />
                  ))}
                </Box>
              )}
            >
              <MenuItem value="select-all-tasks">
                <Checkbox checked={selectedTaskIds.length === crWorkflowTasks.length} />
                {isFullyBlocked ? 'Unselect all' : 'Select all'}
              </MenuItem>
              {crWorkflowTasks.map((task) => {
                return (
                  <MenuItem key={task.id} value={task.id} disabled={task.id === originalBlockedTask.id}>
                    <Checkbox checked={selectedTaskIds.includes(task.id)} />
                    {task.workflow.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        {/* Checkbox for splitting CR - Only shown when creating a new blocker for Creation tasks */}
        {!['testMaintenance', 'outline'].includes(originalBlockedTask?.type) && !selectedExistingBlocker && !isFullyBlocked && (
          <FormControlLabel control={<Checkbox checked={splitCr} onChange={() => setSplitCr((prev) => !prev)} />} label={'Split CR?'} />
        )}

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          {
            // Case 1: Outline task - No changes to the CR, update Outline and CR task statuses to blocked
            originalBlockedTask?.type === 'outline'
              ? 'Update CR and Outline task statuses to blocked'
              : originalBlockedTask?.type === 'testMaintenance'
              ? 'Update Maintenance Task Statuses'
              : // Case 3: Tasks are remaining in the current CR
              (selectedExistingBlocker && selectedExistingCrIssueId === originalBlockedTask?.parentIssue?.id) ||
                (!originalBlockedTask?.type !== 'outline' && !selectedExistingBlocker && !splitCr)
              ? 'Keep tasks in current CR and update statuses'
              : // Case 4: Tasks are being moved to an existing blocked CR
              selectedExistingBlocker && selectedExistingCrIssueId !== originalBlockedTask?.parentIssue?.id
              ? 'Move selected tasks to existing blocked CR'
              : // Case 5: Tasks are being moved to a new CR
                'Move selected tasks to new blocked CR'
          }
        </Button>
      </Paper>
    </Modal>
  );
}

function LoadingModal({ open, handleClose, message }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mt: -1 }}>
          {message}
        </Typography>
        <WolfLoader customStyles={{ height: '100%' }} />
      </Paper>
    </Modal>
  );
}

export default BlockedReasonModal;
