import { Paper, ListItem, ListItemText, List } from '@mui/material';
import { useQueries, useMutationState } from '@tanstack/react-query';
import { useEffect } from 'react';
import { sendGetRequest } from '../../../../utils/tanstackNetwork';
import { usePlanningContext } from '../../../context/PlanningContext';
import { ClientSummaryTableRow } from '../types';
import ProjectPlanButton from '../ProjectPlanButton';

export function CapacityPanel({ client }: { client: ClientSummaryTableRow }) {
  const { selectedQaTeamIds, selectedTaskWeek } = usePlanningContext();
  const [qaTeamCapacity, clientSummaries] = useQueries({
    queries: [
      {
        queryKey: ['qaTeamCapacityIndividual', selectedQaTeamIds, selectedTaskWeek],
        queryFn: () => {
          const params = new URLSearchParams();
          selectedQaTeamIds.forEach((id) => params.append('qaTeamIds', id.toString()));
          if (selectedTaskWeek) {
            params.append('selectedWeek', selectedTaskWeek?.format('YYYY-MM-DD'));
          }
          return sendGetRequest(`/client-summaries/qa-team-capacity?${params.toString()}`);
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['clientSummariesIndividual', selectedQaTeamIds, selectedTaskWeek],
        queryFn: () => {
          const params = new URLSearchParams();
          selectedQaTeamIds.forEach((id) => params.append('qaTeamIds', id.toString()));
          if (selectedTaskWeek) {
            params.append('selectedWeek', selectedTaskWeek?.format('YYYY-MM-DD'));
          }
          return sendGetRequest(`/client-summaries?${params.toString()}`);
        },
        placeholderData: [],
        refetchOnWindowFocus: false,
      },
    ],
  });

  // Refetch whenever a task mutation occurs
  const taskMutations = useMutationState({
    filters: {
      mutationKey: ['updateTask'],
      status: 'success',
    },
  });

  useEffect(() => {
    if (taskMutations.length > 0) {
      clientSummaries.refetch();
      qaTeamCapacity.refetch();
    }
  }, [taskMutations]);

  const capacityData = qaTeamCapacity.data;
  const clientSummaryData = clientSummaries.data;

  const testsForClientScheduledThisWeek =
    clientSummaryData?.find((row: ClientSummaryTableRow) => row.id === client.id)?.testsScheduledForCurrentWeek || 0;

  const totalScheduledForCurrentWeek = clientSummaryData?.reduce(
    (acc: number, curr: ClientSummaryTableRow) => acc + curr.testsScheduledForCurrentWeek,
    0,
  );
  const remainingCapacity = (capacityData?.estCreationCapacity || 0) - (totalScheduledForCurrentWeek || 0);

  return (
    <Paper sx={{ mt: 1, px: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary="Tests Scheduled For Selected Week"
            secondary={clientSummaries.isLoading ? 'Loading...' : testsForClientScheduledThisWeek}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Tests Req'd For Milestone" secondary={client.activeMilestone ? Math.max(client.requiredVelocity, 0) : 'N/A'} />
        </ListItem>
      </List>
      <List>
        <ListItem disablePadding>
          <ListItemText primary="Remaining QA Team Capacity" secondary={clientSummaries.isLoading ? 'Loading...' : remainingCapacity} />
        </ListItem>
        <ListItem disablePadding>
          <ProjectPlanButton teamId={client.id} />
        </ListItem>
      </List>
    </Paper>
  );
}
