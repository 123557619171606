import { Box, Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import { getSubmilestoneStyles } from './utils';
import { MilestoneProgress } from '../types';

export default function SubMilestone({
  subMilestone,
  thisWeeksTestCount,
  requiredVelocityThisWeek,
}: {
  subMilestone: MilestoneProgress;
  thisWeeksTestCount: number;
  requiredVelocityThisWeek: number;
}) {
  const weekStr = dayjs(subMilestone.dueDate).startOf('week').format('M/D');
  const isCurrWeek = dayjs(subMilestone.dueDate).isSame(dayjs(), 'week');
  const isFutureWeek = dayjs(subMilestone.dueDate).isAfter(dayjs(), 'week');
  const weekType = isCurrWeek ? 'present' : isFutureWeek ? 'future' : 'past';
  const hasMetTotalGoal = subMilestone.targetTests <= subMilestone.actualTests;
  const hasMetThisWeekGoal = requiredVelocityThisWeek <= thisWeeksTestCount;
  const { weekFontWt, weekFontClr, rowBgColor } = getSubmilestoneStyles(hasMetTotalGoal, weekType);
  const { testsBgColor: createdBgColor } = getSubmilestoneStyles(hasMetThisWeekGoal, weekType);
  const { testsBgColor: activeBgColor } = getSubmilestoneStyles(hasMetTotalGoal, weekType);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        p: '.5em',
        borderRadius: '5px',
        justifyContent: 'space-evenly',
        bgcolor: rowBgColor,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <Typography lineHeight={1.1} fontWeight={weekFontWt} color={weekFontClr}>
          {`Week ${subMilestone.weekNumber}`}
        </Typography>
        <Typography variant="caption" lineHeight={1} color={weekFontClr}>
          {`${weekStr} - ${dayjs(subMilestone.dueDate).format('M/D')}`}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: createdBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {!isFutureWeek && `${thisWeeksTestCount} / ${Math.max(requiredVelocityThisWeek, 0)}`}
          </Typography>
        </Paper>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right', mx: 0.5 }}>
        <Paper
          sx={{
            bgcolor: activeBgColor,
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
          }}
        >
          <Typography color="white" fontSize={14} fontWeight="bold">
            {`${subMilestone.actualTests} / ${subMilestone.targetTests}`}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}
