import { useState } from 'react';
import QAWTask from '../../../../types';
import { MRT_TableState } from 'material-react-table';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { usePlanningContext } from '../../../context/PlanningContext';
import { flattenTaskData } from '../../../Tasks/helpers';
import TasksTable from '../../../shared/TasksTable';

const defaultTableState: Partial<MRT_TableState<QAWTask> & { groupCRandMRs: boolean }> = {
  groupCRandMRs: true,
  globalFilterFn: 'contains',
  pagination: {
    pageIndex: 0,
    pageSize: 100,
  },
  rowSelection: {},
  columnFilters: [],

  columnOrder: [
    'mrt-row-select',
    'customer',
    'type',
    'steps',
    'name',
    'parentTask',
    'assigneeName',
    'dueDate',
    'status',
    'progress',
    'priority',
    'age',
    'notes',
    'workflowStatus',
    'childCount',
  ],
};

const blockedStatuses = ['blocked', 'needHelp', 'bugged'];
const unblockedStatuses = ['inProgress', 'done', 'draft', 'changesRequested', 'readyForReview', 'toDo', 'inReview', 'scheduled'];

export function PlanningTasksTable({
  clientTasks,
  isFetching,
  isLoading,
  isError,
  error,
  selectedView,
}: {
  clientTasks: QAWTask[];
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  selectedView: 'testCoverageRequest' | 'testMaintenance' | 'blockers';
}) {
  const [toast, setToast] = useState(<></>);
  const [tableState, setTableState] = useState(defaultTableState);
  const { selectedTaskWeek } = usePlanningContext();
  const queryClient = useQueryClient();

  // Update the table filter state when selectedView or selectedTaskWeek changes
  useEffect(() => {
    const weekFilter = !selectedTaskWeek ? [] : [{ id: 'dueDate', value: [selectedTaskWeek, selectedTaskWeek.endOf('week')] }];
    const taskTypeFilter =
      selectedView === 'blockers'
        ? [{ id: 'status', value: blockedStatuses }]
        : [
            { id: 'type', value: [selectedView] },
            { id: 'status', value: unblockedStatuses },
          ];

    setTableState((prev) => ({
      ...prev,
      columnFilters: [...taskTypeFilter, ...weekFilter],
    }));
  }, [selectedView, selectedTaskWeek]);

  if (isError)
    return (
      <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
        <h1>{'Error loading client tasks...'}</h1>
        <p>{error?.message}</p>
      </div>
    );

  return (
    <>
      {toast}
      <TasksTable
        data={flattenTaskData(clientTasks, !!tableState.groupCRandMRs)}
        initialInstanceState={{
          columnVisibility: {
            customer: false,
            name: true,
            workflowStatus: false,
            type: false,
            childCount: false,
          },
        }}
        setToast={setToast}
        showSkeletons={isFetching || isLoading}
        showProgressBars={isFetching || isLoading}
        tableState={tableState}
        setTableState={setTableState}
        saveViewButton={undefined}
        clearSelectedView={undefined}
        selectedView={undefined}
        defaultTableState={defaultTableState}
        queryClient={queryClient}
      />
    </>
  );
}
