import { XCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { classNames } from './helpers';
import Toast from '../Layout/Toast';
import { sendPostRequest } from '../../utils/network';
import { marked } from 'marked';

export function EnvNotesModal({
  showNotesModal,
  setShowNotesModal,
  notesModalData,
  setNotesModalData,
  editingNotes,
  setEditingNotes,
  setToast,
  tasks,
  refetch,
}) {
  const [draftNotes, setDraftNotes] = useState(notesModalData?.notes || {});
  const [dismissedOnce, setDismissedOnce] = useState(false);

  useEffect(() => {
    setDraftNotes(notesModalData.notes);
  }, [notesModalData]);

  const handleChanges = (e) => {
    setDraftNotes({ ...draftNotes, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { qawId, env, taskId, notes: oldNotes } = notesModalData;

    try {
      await sendPostRequest('/update-notes', { qawId, env, oldNotes, newNotes: draftNotes, taskId });
      setToast(<Toast title={'Success!'} message={`Saved Env Notes.`} key={new Date().toISOString()} isSuccess={true} />);
      setNotesModalData('');
      setEditingNotes(false);
      setShowNotesModal(!showNotesModal);
      await refetch();
    } catch (e) {
      setToast(
        <Toast title={'Ruh-roh'} message={`Something went wrong: ${e.message} ${tasks.length}`} key={new Date().toISOString()} isSuccess={false} />,
      );
    } finally {
      setDismissedOnce(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center ${showNotesModal ? 'visible bg-black/40' : 'invisible'}`}
      onClick={() => {
        if (!editingNotes) setShowNotesModal(!showNotesModal);
      }}
    >
      <div className={`flex flex-col min-w-[20%] bg-white rounded-xl shadow p-6 ${showNotesModal ? 'opacity-100' : 'opacity-0'}`}>
        <div className="hover:cursor-pointer flex justify-end">
          {dismissedOnce ? (
            <div
              className="min-w-max h-6 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/20 hover:ring-2 hover:bg-red-100"
              onClick={() => {
                setEditingNotes(false);
                setShowNotesModal(!showNotesModal);
                setDismissedOnce(false);
              }}
            >
              Discard changes?
            </div>
          ) : (
            <XCircleIcon
              className="w-11 h-6 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-red-700 bg-red-50 ring-red-600/20 hover:ring-2 hover:bg-red-100"
              onClick={() => {
                if (
                  editingNotes &&
                  (draftNotes?.triageNotes !== notesModalData?.notes.triageNotes || draftNotes?.bugNotes !== notesModalData?.notes.bugNotes)
                ) {
                  setDismissedOnce(true);
                  setTimeout(() => {
                    setDismissedOnce(false);
                  }, 3000);
                } else {
                  setEditingNotes(false);
                  setShowNotesModal(!showNotesModal);
                }
                setDraftNotes({});
              }}
            />
          )}
        </div>
        <section className={classNames('flex row justify-between', showNotesModal ? 'visible' : 'invisible')}>
          <div className="pb-10 w-full">
            {editingNotes ? (
              <>
                <div className="py-2">
                  <h1 className="font-bold">Investigation Notes:</h1>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      name="triageNotes"
                      id="triageNotes"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={draftNotes?.triageNotes}
                      onChange={handleChanges}
                    />
                    <div className="prose" dangerouslySetInnerHTML={{ __html: marked(draftNotes?.triageNotes || '') }}></div>
                  </div>
                </div>
                <div className="py-2">
                  <h1 className="font-bold">Bug Notes:</h1>
                  <div className="mt-2 w-full">
                    <textarea
                      rows={3}
                      name="bugNotes"
                      id="comment"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={draftNotes?.bugNotes}
                      onChange={handleChanges}
                    />
                    <div className="prose" dangerouslySetInnerHTML={{ __html: marked(draftNotes?.bugNotes || '') }}></div>
                  </div>
                </div>
                <button
                  type="button"
                  className="inline-flex justify-center gap-x-2 rounded-md bg-indigo-600 mt-5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleSubmit}
                >
                  Save Notes
                  <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </>
            ) : (
              <>
                <div className="py-2 pr-40">
                  <h1 className="font-bold">Investigation Notes:</h1>
                  <div
                    className="prose"
                    dangerouslySetInnerHTML={{ __html: notesModalData.notes && marked(notesModalData?.notes.triageNotes || '') }}
                  ></div>
                </div>
                <div className="py-2 pr-40">
                  <h1 className="font-bold">Bug Notes:</h1>
                  <div
                    className="prose"
                    dangerouslySetInnerHTML={{ __html: notesModalData.notes && marked(notesModalData?.notes.bugNotes || '') }}
                  ></div>
                </div>
              </>
            )}
          </div>
        </section>
        {editingNotes ? (
          <p>
            Text fields support{' '}
            <a href="https://www.markdownguide.org/cheat-sheet/" target={'_blank'} rel="noreferrer" className="underline font-semibold">
              Markdown
            </a>{' '}
            for formatting.
          </p>
        ) : (
          <p>Edit notes from the task's action menu</p>
        )}
      </div>
    </div>
  );
}
