import { useMutation } from '@tanstack/react-query';
import { sendPostRequest } from '../../../utils/network';
import { JSX, useState } from 'react';

interface TikTokResponseData {
  access_token: string;
  scope: number[];
  advertiser_ids: string[];
}

interface TikTokOAuthResponse {
  message: string;
  code: number;
  data: TikTokResponseData;
  request_id: string;
}

export default function TikTokCodeConverter(): JSX.Element {
  const [token, setToken] = useState('');

  const codeMutation = useMutation<TikTokOAuthResponse, unknown, string | null>({
    mutationFn: async (authCode: string | null) => {
      const response = await sendPostRequest('/oauth/code/tiktok', { authCode });
      if (!response?.success) throw response?.error;
      return response.data as TikTokOAuthResponse;
    },
    onSuccess: (response) => {
      if (!response) {
        reportError('Failed to fetch new auth.');
        return;
      }

      const token = response.data?.access_token;

      setToken(token ? token : '');
    },
    onError: (error) => {
      reportError(error);
    },
  });

  /**
   * Generate the access token
   */
  function convertCode() {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Get the auth_code, code, and state from the URL parameters
    const authCode = urlParams.get('auth_code');

    // Mutate the data to the server
    codeMutation.mutate(authCode);
  }

  return (
    <>
      <button
        type="button"
        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        onClick={() => {
          convertCode();
        }}
      >
        Convert Code on Server
      </button>
      <div className="pt-8">
        <a href="/tiktok/auth" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
          Restart the Auth Flow
        </a>
      </div>
      <div className="mt-4">{token ? <>{token}</> : <>No token</>}</div>
    </>
  );
}
