// SumObject and SumsByType
export interface SumObject {
  sumTasks: number;
  sumTests: number;
  sumsByType: SumsByType;
}

export interface SumsByType {
  testCreation?: SumObject;
  outline?: SumObject;
  testMaintenance?: SumObject;
}

// QaeCreationOutlineMaintenanceTaskInsights and TeamCreationOutlineMaintenanceTasksInsights
export interface QaeCreationOutlineMaintenanceTaskInsights {
  qae: User;
  qaeTasksByWeekAndType: WeeklyTaskBreakdown[];
}

export interface TeamCreationOutlineMaintenanceTasksInsights {
  team: QaTeam;
  teamTasksByWeekAndType: WeeklyTaskBreakdown[];
}

// User
export interface User {
  id: number;
  slackId: string;
  name: string;
  email: string;
  secure: string;
  is_qawolf: boolean;
  isQAE: boolean;
  qawId: string;
  avatar48: string;
  teamId: number;
  isLead: boolean;
  isManager: boolean;
  isCSM: boolean;
  position: string;
  salary: number;
  startDate: string;
}

// WeeklyTaskBreakdown
export interface WeeklyTaskBreakdown {
  'Week Number': string;
  type: string;
  total_tasks: string;
  total_steps: string;
  name: string;
  completed_by_qaw_id: string;
}

// QAEInsightsFilter, TeamInsightsFilter, and PackInsightsFilter
export interface QAEInsightsFilter {
  entity: {
    type: 'qae';
    data: {
      id: string;
    };
  };
  investigationOptions: InvestigationOptions;
  types: string[];
  gte: string;
  lte: string;
}

export interface TeamInsightsFilter {
  entity: {
    type: 'team';
    data: {
      name: string;
    };
  };
  investigationOptions: InvestigationOptions;
  types: string[];
  gte: string;
  lte: string;
}

export interface PackInsightsFilter {
  entity: {
    type: 'pack';
    data: {
      id: number | string;
    };
  };
  investigationOptions: InvestigationOptions;
  types: string[];
  gte: string;
  lte: string;
}

// InvestigationOptions
export interface InvestigationOptions {
  includeRuns: boolean;
  includeEvents: boolean;
  includeFlatOrderedData: boolean;
  includeRunsByLastAttemptStatus: boolean;
}

// InvestigationTask
export interface InvestigationTask {
  id: string;
  customerId: string;
  customerSlug: string;
  triggerName: string;
  startTimeString: string;
  taskClaimedBy: string;
  supportedBy: object[];
  numberOfRuns: number;
  numberOfRunAttempts: number;
  numberOfRunsPassingOnFirstAttempt: number;
  numberOfRunsPassingOnSecondAttempt: number;
  numberOfRunsPassingOnThirdAttempt: number;
  numberOfRunsPassingOnQaeFlake: number;
  numberOfRunsPassingOnQaeFix: number;
  numberOfRunsAutoInvestigation: number;
  numberOfRunsTriagedAsBug: number;
  numberOfRunsTriagedAsMaintenance: number;
  numberOfRunsCanceled: number;
  numberOfRunsTriagedAsDoNotInvestigate: number;
  numberOfSupersededRuns: number;
  successRate: number;
  failureRate: number;
  flakeRate: number;
  runTimeInMinutes: number;
  timeToCompleteSecondAttemptInMinutes: number;
  timeToCompleteThirdAttemptInMinutes: number;
  timeToCompleteAllAutomaticAttemptsInMinutes: number;
  investigationTimeInMinutes: number;
  totalTimeInMinutes: number;
  totalSuiteExecutionTimeInMinutes: number;
  minutesUntilFirstQaeAttempt: number;
  minutesUntilInvestigationStarted: number;
  supersededSuiteIds: object[];
  cleanVersions: object[];
  environmentId: string;
  startTime: string;
  customerName: string;
  deterministicTestsPercent: number;
  flakePercent: number;
  isCanceled: boolean;
  isCompleted: boolean;
  isMovedToNewerSuite: boolean;
  isSuperseded: boolean;
  successPercent: number;
  runs?: object[];
}

// Deprecated Types
export interface WeeklyInvestigationAggregates {
  sumFailures: number;
  sumTests: number;
  sumInvestigatedAsBug: number;
  sumInvestigatedAsMaint: number;
  sumPassedOnFlake: number;
  sumPassedOnFix: number;
  avgMinsUntilInvestigationStart: number;
  avgMinsUntilFirstQaeAttempt: number;
  avgInvestigationTime: number;
}

// Investigation Data Types
export interface InvestigationData {
  team: QaTeam;
  teamAggregateData: {
    teamRawSuites: InvestigationTask[];
    teamAssistedRawSuites: InvestigationTask[];
    weekly: { [key: string]: TeamInvestigationData };
    totals: TeamInvestigationData;
  } & { [key: string]: QAEInvestigationDataObject };
  teamAssistedInvestigationDataGroupedByWeek: { [key: string]: InvestigationTask[] };
  teamInvestigationDataGroupedByWeek: { [key: string]: InvestigationTask[] };
}

export interface QAEInvestigationDataObject {
  weekly: { [key: string]: QAEInvestigationData };
  totals: QAEInvestigationData;
}

export interface QAEInvestigationData {
  numberOfTestsInvestigated: number;
  numberOfRunsInvestigated: number;
  numberOfSuitesInvestigated: number;
  numberOfRunsPassingOnFix: number;
  numberOfRunsPassingOnFlake: number;
  numberOfRunsReportedAsBugs: number;
  numberOfRunsReportedAsMaintenance: number;
  numberOfRunsWithDoNotInvestigate: number;
}

export interface TeamInvestigationData {
  teamNumberOfTestsInvestigated: number;
  teamNumberOfRunsInvestigated: number;
  teamNumberOfSuitesInvestigated: number;
  teamAverageTestsInvestigated: number;
  teamAverageRunsInvestigated: number;
  teamAverageSuitesInvestigated: number;
  teamMedianSuitesInvestigated: number;
  teamAverageRunsPassingOnFix: number;
  teamAverageRunsPassingOnFlake: number;
  teamAverageRunsReportedAsBugs: number;
  teamAverageRunsReportedAsMaintenance: number;
  teamAverageRunsWithDoNotInvestigate: number;
  teamNumberOfRunsPassingOnFix: number;
  teamNumberOfRunsReportedAsBugs: number;
  teamNumberOfRunsPassingOnFlake: number;
  teamNumberOfRunsReportedAsMaintenance: number;
  teamNumberOfRunsWithDoNotInvestigate: number;
  teamMaxSuitesInvestigated: number;
  teamMinSuitesInvestigated: number;
}

export type LegendMapFunction = (name: string) => string;

export type QAELegendMap = { [k in keyof QAEInvestigationData]: LegendMapFunction };

export type TeamLegendMap = { [k in keyof TeamInvestigationData]: LegendMapFunction };

// QATeam and related types
export interface QaTeam {
  active: boolean;
  emoji: string | null;
  endOfDayInLocalTime: string;
  id: number;
  imageUrl: string | null;
  isPack: boolean;
  leadId: number;
  members?: User[];
  name: string;
  packId: number | null;
  primaryChannelId: string | null;
  slackSubteamId: string | null;
  startOfDayInLocalTime: string;
  timeZone: string;
}

export interface QATeamFromLocalStorage {
  id: number;
  name: string;
  teamName: string;
  packId: number;
  active: boolean;
  email: string;
  qawId: string;
  imageUrl: string;
  selectedInSettings: boolean;
}

export interface QAEFromLocalStorage {
  qawId: string;
  name: string;
  email: string;
  avatar48: string;
  teamId: number;
  isQAE: boolean;
  isLead: boolean;
  isManager: boolean;
  startDate: string;
  position: string;
}

// ParsedEvent and EventType
export interface ParsedEvent {
  id: string;
  type: EventType;
  date: string;
  start: string;
  end: string;
  durationInMinutes: number;
  customer?: string;
  timeTrackingCategory?: string;
}

export type EventType = 'OOO' | 'Other' | 'Tracked' | 'Triage';

// TimeTrackingTeamResponse
export interface TimeTrackingTeamResponse {
  qaTeam: QaTeam;
  events: { [key: number]: ParsedEvent[] };
}

// Velocity & Impact Metrics Types
export interface InvestigationTrackedTimeAggregate {
  user_id: string;
  user_email: string;
  week: string;
  hours_tracked: number;
  group_total_hours_tracked: number;
  completed_snapshots: number;
  group_total_completed_snapshots: number;
  total_failures_investigated_this_week: number;
  group_total_failures_investigated_this_week: number;
  total_tests_investigated_this_week?: number;
  group_total_tests_investigated_this_week?: number;
  suite_ids: string[];
  suites: Suite[];
}

export interface Suite {
  suite_id: string;
  task_claimed_by_id: string;
  task_claimed_by: string;
  customer_name: string;
  number_of_runs: number;
  number_of_runs_passing_on_qae_flake: number;
  number_of_runs_passing_on_qae_fix: number;
  number_of_runs_triaged_as_bug: number;
  number_of_runs_triaged_as_maintenance: number;
  total_failures_investigated: number;
  week: string;
  runs?: Run[];
}

export interface Run {
  id: string;
  suite_id: string;
  workflow_id: string;
  workflow_name: string;
  triage_time: string;
  triaged_by: string;
  last_run_status: string;
  triage_type: string;
  issue_reported_on_run: boolean;
  issue_id: string;
  issue_name: string;
  workflow_tests: WorkflowTest[];
}

export interface WorkflowTest {
  id: string;
  name: string;
  environment_id: string;
  environment_name: string;
}

// InvestigationAggregateResponse and related types
export interface PackInvestigationAggregateResponse {
  pack: QaTeam;
  packTeams: QaTeam[];
  packInvestigationAggregatedWithTrackedTime: InvestigationTrackedTimeAggregate[];
}

export interface TeamInvestigationAggregateResponse {
  team: QaTeam;
  teamInvestigationAggregatedWithTrackedTime: InvestigationTrackedTimeAggregate[];
}

export interface QAEInvestigationAggregateResponse {
  qae: User;
  qaeInvestigationAggregatedWithTrackedTime: InvestigationTrackedTimeAggregate[];
}

export type InvestigationAggregateResponse =
  | PackInvestigationAggregateResponse
  | TeamInvestigationAggregateResponse
  | QAEInvestigationAggregateResponse;

// TaskSnapshotTrackedTimeAggregate and related types
export interface TaskSnapshotTrackedTimeAggregate {
  user_id: string;
  user_email: string;
  week: string;
  hours_tracked: number;
  group_total_hours_tracked: number;
  workflows_completed_this_week: number;
  group_workflows_completed_this_week: number;
  tests_completed_this_week: number;
  group_tests_completed_this_week: number;
  workflow_ids: string[];
  workflows: TaskSnapshotWorkflow[];
}

export interface TaskSnapshotDetail {
  id: string;
  type: string;
  dueAt: string;
  status: string;
  priority: string;
  workflow: TaskSnapshotWorkflowDetail;
  createdAt: string;
  assignedTo: TaskSnapshotAssignedTo;
  completedAt: string;
}

export interface TaskSnapshotWorkflowDetail {
  id: string;
  name: string;
  status: string;
  stepCount: number;
}

export interface TaskSnapshotAssignedTo {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
}

export interface TaskSnapshotWorkflow {
  workflow_id: string;
  details: TaskSnapshotDetail;
  task_claimed_by_id: string;
  email: string;
  customer_name: string;
  week: string;
}

// TaskSnapshotAggregateResponse and related types
export interface PackTaskSnapshotAggregateResponse {
  pack: QaTeam;
  packTeams: QaTeam[];
  packTaskSnapshotsAggregatedWithTrackedTime: TaskSnapshotTrackedTimeAggregate[];
}

export interface TeamTaskSnapshotAggregateResponse {
  team: QaTeam;
  teamTaskSnapshotsAggregatedWithTrackedTime: TaskSnapshotTrackedTimeAggregate[];
}

export interface QAETaskSnapshotAggregateResponse {
  qae: User;
  qaeTaskSnapshotsAggregatedWithTrackedTime: TaskSnapshotTrackedTimeAggregate[];
}

export type TaskSnapshotAggregateResponse = PackTaskSnapshotAggregateResponse | TeamTaskSnapshotAggregateResponse | QAETaskSnapshotAggregateResponse;

// Type Maps
export const taskTypeMap: Record<string, string> = {
  outline: 'Outlining',
  testCreation: 'Creation',
};
