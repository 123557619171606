import { Box, Divider, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';

export function Header({ startDate }: { startDate: string }) {
  const cycleDateString = `${dayjs(startDate).format('ddd, MMM D')} - ${dayjs(startDate).add(11, 'day').format('ddd, MMM D')}`;
  return (
    <Box sx={{ px: 3, py: 2 }}>
      {/* Title */}
      <Typography variant="h5" component="div" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
        Howl Creation Leaderboard
      </Typography>
      <Typography variant="caption" component="div" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
        {cycleDateString}
      </Typography>

      {/* Links */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          href="https://www.notion.so/qawolf/Tips-Best-Practices-for-Howl-Creation-20dff47604e748d4ad8c87cc560fcd07"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          Tips & Best Practices
        </Link>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Link
          href="https://www.loom.com/share/4dd9c073c0414170b54525b55e8b0731?sid=3d7967fc-396f-4efc-86b3-64bbdb3d7301"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          Loom Demo
        </Link>
      </Box>
    </Box>
  );
}
