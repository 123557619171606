import { WOLF_LOGO } from '../../../constants';

function facebookAuth() {
  const userId = localStorage.getItem('userId');
  const rootUrl = `https://www.facebook.com/v20.0/dialog/oauth`;

  const options = {
    redirect_uri: window.location.origin + '/facebook/code',
    client_id: '1739579036856839',
    scope: 'public_profile',
    response_type: 'code',
    state: userId || '',
  };

  const qs = new URLSearchParams(options);
  window.location.replace(`${rootUrl}?${qs.toString()}`);
}

/**
 *
 * @returns {import('react').JSX.Element}
 */
export default function FacebookConnector() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div>
        <div className="mb-4 text-xl font-bold flex items-end gap-2">
          <img src={WOLF_LOGO} alt="wolf_logo" className="w-8 h-8 rounded-full" />
          <span>Use this page to:</span>
        </div>
        <ul className="list-disc list-inside mb-4">
          <li>Log in with Facebook</li>
          <li>Receive auth code to exchange for access token</li>
        </ul>
        <button
          type="button"
          className="rounded-md w-full bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={() => facebookAuth()}
        >
          Connect to Facebook
        </button>
      </div>
    </div>
  );
}
