import { classNames } from '../../InvestigationBoard/helpers.jsx';
import {
  computeComparisonInsightsForCreationAndOutliningCompTable,
  computeQAEInsightsForCreationAndOutliningCompTable,
  computeTeamInsightsForCreationAndOutliningCompTable,
  CreationComparisonInsights,
  determineColorForCreationAndOutliningCompTable,
  getComparisonTableTooltipText,
} from './tableHelpers';

import { InfoOutlined } from '@mui/icons-material';

import { QaeCreationOutlineMaintenanceTaskInsights, SumObject, TeamCreationOutlineMaintenanceTasksInsights } from '../types.js';

interface WeeklyCreationComparisonTableProps {
  creationInsights: QaeCreationOutlineMaintenanceTaskInsights;
  qaeSums: SumObject;
  teamCreationInsights: TeamCreationOutlineMaintenanceTasksInsights;
  teamSums: SumObject;
}

export default function WeeklyCreationComparisonTable({
  creationInsights,
  qaeSums,
  teamCreationInsights,
  teamSums,
}: WeeklyCreationComparisonTableProps) {
  // default static categories
  const CATEGORIES: Array<keyof CreationComparisonInsights> = [
    'Workflows Created',
    'Tests Created',
    'Average Tests Created Per Week',
    'Median Tests Created Per Week',
    'Outlining Tasks Completed',
    'Max Tests Per Week',
    'Min Tests Per Week',
  ];

  // compute insights for comparison table
  const computedQAEInsights = computeQAEInsightsForCreationAndOutliningCompTable(creationInsights, qaeSums);
  const computedTeamInsights = computeTeamInsightsForCreationAndOutliningCompTable(teamCreationInsights, teamSums);
  const computedComparisonInsights = computeComparisonInsightsForCreationAndOutliningCompTable(computedQAEInsights, computedTeamInsights);

  // get tooltip text
  const allPositions = teamCreationInsights.team?.members?.map((member) => member.position).filter((position) => position !== undefined) as string[];
  const tooltipText = getComparisonTableTooltipText(allPositions, creationInsights.qae);

  return (
    <div className="w-full h-full overflow-hidden border border-gray-600">
      <table className="min-w-full min-h-full text-left">
        <thead className="bg-white">
          <tr className="divide-x divide-gray-200 border-b border-b-gray-200">
            <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 w-1/3">
              <span className="flex justify-center items-center">
                <img src={creationInsights.qae.avatar48} alt="avatar" className="rounded-full h-10 w-auto" />
              </span>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              <span>{teamCreationInsights.team.name}:</span>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              <span>{creationInsights.qae.name.split(' ')[0]}:</span>
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell w-1/6">
              <div className="flex items-center justify-around">
                {!creationInsights.qae.isLead && (
                  <span className="mr-1 cursor-help" title={tooltipText}>
                    <InfoOutlined />
                  </span>
                )}
                <span>Comparison:</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {CATEGORIES.map((category, categoryIndex) => (
            <tr
              key={categoryIndex}
              className={classNames(
                'divide-x divide-x-gray-200 border-b border-b-gray-200',
                category === 'Median Tests Created Per Week' && 'border-b-2 border-b-gray-600',
              )}
            >
              <td className="relative pl-4 py-4 text-sm font-medium text-gray-900 truncate w-1/6">{category}</td>
              <td className="tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6">{computedTeamInsights[category]}</td>
              <td
                className={classNames(
                  [4, 5, 6].includes(categoryIndex)
                    ? ''
                    : determineColorForCreationAndOutliningCompTable(
                        category,
                        computedComparisonInsights[category],
                        creationInsights.qae,
                        teamCreationInsights.team,
                        teamSums,
                      ),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {computedQAEInsights[category]}
              </td>
              <td
                className={classNames(
                  [4, 5, 6].includes(categoryIndex)
                    ? ''
                    : determineColorForCreationAndOutliningCompTable(
                        category,
                        computedComparisonInsights[category],
                        creationInsights.qae,
                        teamCreationInsights.team,
                        teamSums,
                      ),
                  'tabular-nums hidden px-3 py-4 text-sm text-gray-500 sm:table-cell w-1/6',
                )}
              >
                {typeof computedComparisonInsights[category] === 'number' && computedComparisonInsights[category] > 0 ? '+' : ''}
                {computedComparisonInsights[category]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
