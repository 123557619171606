// RowDetailPanel.jsx
import { Box, CardMedia } from '@mui/material';
import MonacoEditor from './MonacoEditor/MonacoEditor';
import EmailEditor from './HiringEmails/EmailEditor';
import { useQuery } from '@tanstack/react-query';
import { getCandidateEmails } from './api'; // Define this API function
import * as dateFns from 'date-fns';

const RowDetailPanel = ({ row, data }) => {
  const { id, candidate: { email } } = row.original; // Adjust based on your data structure

  // Fetch additional data for the row
  const { data: emailData, isLoading, isFetching, isError, error } = useQuery({
    queryKey: ['rowDetails', id],
    queryFn: () => getCandidateEmails(email),
    enabled: !!id, // Only run the query if id is available
    staleTime: 5 * 60 * 1000,

  });


  return (
    <Box
      className="panelContainer"
      sx={{
        display: 'flex',
        justifyContent: 'left',
        width: '100%',
        // position: 'absolute'
        position: 'sticky',
      }}
    >
      <Box
        className="panelInnerContainer"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 6,
          margin: 0,
          borderRadius: 1,
          height: '70vh',
          width: '100%',
          '@media (max-width: 1320px) or (max-height: 870px)': {
            width: '90%', // Set a smaller width for screens less than 1320px
            height: '60vh',
          },
        }}
      >

        <Box
          className="upperContainer"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '50%',
            paddingBottom: '2px',
            justifyContent: 'end',
          }}
        >
          {/* =============================== LOOM SECTION =============================== */}
          <CardMedia
            component="iframe"
            src={data
              .filter((candidateName) => `${candidateName.candidate.firstName} ${candidateName.candidate.lastName}` === row.getValue('name').fullName)[0]
              .submissionVideo.replace('share', 'embed')}
            title="title"
            className={'.MuiCardMedia-media'}
            sx={{
              boxShadow: 4,
              maxWidth: '35%',
              width: '100%',
              aspectRatio: 16 / 9,
              display: 'flex',
            }}
          />
          {/* =============================== MONACO EDITOR SECTION =============================== */}
          <Box
            className="monacoContainer"
            sx={{
              boxShadow: 4,
              zIndex: 10,
              marginLeft: '2px',
              width: '65%',
              overflow: 'clip',
              position: 'relative',
            }}
          >
            <MonacoEditor
              fileUrl={
                data.filter((candidateName) => `${candidateName.candidate.firstName} ${candidateName.candidate.lastName}` === row.getValue('name').fullName)[0]
                  .submissionFilePath
              }
              candidateName={{ firstName: row.original.candidate.firstName, lastName: row.original.candidate.lastName }}
            />
          </Box>
        </Box>

        <Box
          className="lowerContainer"
          sx={{
            height: '50%',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {/* =============================== CANDIDATE EXTRA INFO SECTION =============================== */}
          <div
            className='extraInfo flex flex-col p-4 border-r border-gray-700 bg-gray-900 w-[15%] text-white overflow-y-auto'
          >
            <h2
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
              }}
            >
              Candidate Extra Information:
            </h2>
            <div>
              <div className="p-4 text-gray">
                <b>First applied:</b> {dateFns.format(new Date(row.original.candidate.firstApplied), "MM/dd/yyyy")}
              </div>
              <div className="p-4 text-gray">
                <b>Cooldown dates:</b>
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  <li>
                    <b>Take-home:</b> {row.original.candidate.takeHomeCooldown
                      ? dateFns.format(new Date(row.original.candidate.takeHomeCooldown), "MM/dd/yyyy")
                      : 'N/A'}
                  </li>
                  <li>
                    <b>Final-round:</b> {row.original.candidate.finalRoundCooldown
                      ? dateFns.format(new Date(row.original.candidate.finalRoundCooldown), "MM/dd/yyyy")
                      : 'N/A'}
                  </li>
                </ul>
              </div>
              <div className="p-4 text-gray">
                <b>Referer:</b> {row.original.refereeName ? row.original.refereeName : "N/A"}
              </div>
              {
                row.original.finalRoundTeam &&
                <div className="p-4 text-gray">
                  <b>Final Round Team:</b>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    <li>
                      <a href={`https://app.qawolf.com/${row.original.finalRoundTeam}`} target='_blank' rel="noopener noreferrer">Candidate Team</a>
                    </li>
                  </ul>
                </div>
              }

            </div>
          </div>
          {/* ==================================== EMAIL SECTION ==================================== */}
          <EmailEditor
            candidateInfo={row.original.candidate}
            // row={candidate.email}
            emailData={emailData}
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            error={error}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RowDetailPanel;
