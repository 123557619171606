import React, { useEffect, useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { sendPostRequest } from '../../utils/network';
import {
  SuiteNetworkReportResponse,
  AllTracesItem,
  OperationSummaryItem,
  ArrayOfMinutesXItem,
  ArrayOfMinutesYItem,
  ShortOperationSummaryItem,
  FailedAttemptsSummary,
} from './typesForSuiteNetworkReport';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine, Label } from 'recharts';
import WolfLoader from '../WolfLoader/WolfLoader';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { useGenerateImage } from './useGenerateImage'; // Adjust the path as necessary
import { Payload } from 'recharts/types/component/DefaultLegendContent';

// Custom Legend Component
const CustomLegend = ({ payload }: { payload?: Payload[] }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {payload?.map((entry, index) => (
        <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ backgroundColor: entry.color, width: 10, height: 10, display: 'inline-block', marginRight: 5 }}></span>
          <span style={{ color: entry.color }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

export default function SuiteNetworkReport() {
  const [suiteId, setSuiteId] = useState('');
  const [suiteURL, setSuiteURL] = useState('');
  const [startTimeOfFirstOperation, setStartTimeOfFirstOperation] = useState('');
  const [arrayOfMinutesX, setArrayOfMinutesX] = useState<ArrayOfMinutesXItem[]>([]);
  const [arrayOfMinutesY, setArrayOfMinutesY] = useState<ArrayOfMinutesYItem[]>([]);
  const [waterfallChartData, setWaterfallChartData] = useState<AllTracesItem[] | undefined>();
  const [displayData, setDisplayData] = useState<AllTracesItem[] | undefined>();
  const [requestDuration, setRequestDuration] = useState<number | null>(null);
  const [allOperationsSummary, setAllOperationsSummary] = useState<OperationSummaryItem[]>();
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [operationsPerMinute, setOperationsPerMinute] = useState<ShortOperationSummaryItem | null>(null);
  const [selectedOperations, setSelectedOperations] = useState<OperationSummaryItem[]>([]);
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | null>(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [showOrange, setShowOrange] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  const [failedAttemptsSummary, setFailedAttemptsSummary] = useState<FailedAttemptsSummary | null>(null);
  const [containerHeight, setContainerHeight] = useState(1000);
  const [isWaterFallHeightToggleLoading, setIsWaterFallHeightToggleLoading] = useState(false);
  const [showPassingAttempts, setShowPassingAttempts] = useState(false);

  const [getDivJpeg, { ref, isLoading }] = useGenerateImage<HTMLDivElement>({
    quality: 0.8,
    type: 'image/jpeg',
  });

  const handleDivDownload = useCallback(async () => {
    const jpeg = await getDivJpeg();
    if (jpeg) {
      FileSaver.saveAs(jpeg, 'chart-container.jpeg');
    }
  }, [getDivJpeg]);

  // Mutation to get the suite network report from the endpoint
  const { mutate, status, error } = useMutation<SuiteNetworkReportResponse, Error, string>({
    mutationFn: async (suiteId: string) => {
      const response = await sendPostRequest('/suite-network-report', { suiteId });
      if (response && response.success) {
        return response.data as SuiteNetworkReportResponse;
      } else {
        throw new Error(`Failed to load report`);
      }
    },
    onSuccess: (data) => {
      setSuiteURL(data.reportData.report.suiteURL);
      setDisplayData(data.reportData.report.allTraces);
      setArrayOfMinutesX(data.reportData.report.arrayOfMinutesX);
      setArrayOfMinutesY(data.reportData.report.arrayOfMinutesY);
      setRequestDuration(data.durationInMs);
      setAllOperationsSummary(data.reportData.report.allOperationsSummary);
      setStartTimeOfFirstOperation(data.reportData.report.startTimeOfFirstOperation);
      setFailedAttemptsSummary(data.reportData.report.failedAttemptsSummary);
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  // Timer to update the elapsed time
  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (status === 'pending') {
      timer = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    } else {
      setElapsedTime(0);
    }
    return () => clearInterval(timer);
  }, [status]);

  // Timer to measure the chart render time
  useEffect(() => {
    setIsChartLoading(true);
    if (displayData) {
      setWaterfallChartData(displayData); // Set waterfallChartData to transformedData on initial load
      requestAnimationFrame(() => {
        performance.mark('chart-render-start');
        const timer = setTimeout(() => {
          performance.mark('chart-render-end');
          try {
            performance.measure('chart-render', 'chart-render-start', 'chart-render-end');
          } catch (error) {
            console.error('Performance measure error:', error);
          }
          performance.clearMarks('chart-render-start');
          performance.clearMarks('chart-render-end');
          performance.clearMeasures('chart-render');
          setIsChartLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
      });
    }
  }, [displayData]);

  // Function to format the elapsed time
  const formatElapsedTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} min ${secs < 10 ? '0' : ''}${secs} sec`;
  };

  // Function to handle the input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuiteId(event.target.value);
  };

  // Function to handle the form submission
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate(suiteId);
  };

  const toggleRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const downloadCSV = () => {
    const dataToExport = selectedOperations.length > 0 ? selectedOperations : allOperationsSummary || [];
    const csvData = dataToExport
      .map((operation) => {
        const baseData = {
          'Operation Name': operation.operationName,
          'Total Calls For Operation': operation.totalCallsForOperation,
          'Workflows That Called It': operation.totalWorkflowsCallingOperation,
          'Attempt Retries': operation.totalReAttempts,
          'Attempt Failures': operation.totalFailedAttempts,
          'Call Failures': operation.totalFailedCalls,
          'Call Duration 2+ sec': operation.totalCallsLongerThan2,
          'Call Duration 30+ sec': operation.totalCallsLongerThan30,
        };

        // Add expanded data for each workflow
        const expandedData = operation.workflowsCallingOperation
          .map((workflow) => {
            return workflow.attempts.map((attempt) => ({
              ...baseData,
              'Workflow Name': workflow.workflowName,
              'Attempt ID': attempt.attemptId,
              'Attempt URL': attempt.attemptURL.toString(),
              'Attempt Number': attempt.attemptNumber,
              'Attempt Status': attempt.attemptStatus,
            }));
          })
          .flat();

        return expandedData;
      })
      .flat();

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'operations_summary.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uniqueTraceRefLines = new Set();

  const [tickArray, setTickArray] = useState<number[]>([0]);
  const [graphDomain, setGraphDomain] = useState<[number, number] | undefined>([tickArray[0], tickArray[tickArray.length - 1]]);
  console.log({ graphDomain });
  console.log({ tickArray });
  function setTickArrayAndGraphDomain() {
    const initialTickArray = [];
    for (let i = 0; i < arrayOfMinutesY.length + 1; i++) {
      initialTickArray.push(i * 60000);
    }
    setTickArray([...initialTickArray]);
    setGraphDomain([tickArray[0], tickArray[tickArray.length - 1]]);
  }

  useEffect(() => {
    setTickArrayAndGraphDomain();
  }, [arrayOfMinutesY]);

  const toggleHeight = () => {
    setIsWaterFallHeightToggleLoading(true);
    setTimeout(() => {
      setContainerHeight((prevHeight) => (prevHeight === 1000 ? 9000 : 1000));
      setIsWaterFallHeightToggleLoading(false);
    }, 700);
  };

  return (
    <div>
      <div className="chart-container" ref={ref}>
        <div style={{ display: 'flex' }}>
          <div className="p-4" style={{ width: '20%' }}>
            <h1 className="mb-4 text-2xl font-bold">Suite Network Report</h1>
            <form onSubmit={handleSubmit} className="flex flex-col gap-2" style={{ width: '100%' }}>
              <input type="text" value={suiteId} onChange={handleInputChange} placeholder="Enter Suite ID" />
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Generate Report
              </button>
            </form>
          </div>
          {status === 'success' && displayData && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                <div className="flex flex-col gap-2 items-center border border-blue-500 p-1">
                  <a
                    href={process.env.PUBLIC_URL + '/flow-explorer/' + suiteId}
                    className="text-blue-500 hover:underline flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open this suite in Flow Explorer
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                  <a href={suiteURL} className="text-blue-500 hover:underline flex items-center" target="_blank" rel="noopener noreferrer">
                    Open this suite in QA Wolf
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  </a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid black', padding: 10 }}>
                  <p className="mb-1 text-gray-500">Start Time Of First Operation: {new Date(startTimeOfFirstOperation).toLocaleString()}</p>
                  {requestDuration !== null && (
                    <p className="mb-1 text-gray-500">
                      Report Generation Time:{' '}
                      {requestDuration >= 60000
                        ? `${Math.floor(requestDuration / 60000)} min ${((requestDuration % 60000) / 1000).toFixed(2)} sec`
                        : `${(requestDuration / 1000).toFixed(2)} seconds`}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {operationsPerMinute ? (
                  <div className="ml-4 mt-4 inline-block border border-black p-4 max-w-fit text-gray-500">
                    <h3 className="font-bold">Operations Per Minute</h3>
                    <p>{`Time: ${operationsPerMinute.time}`}</p>
                    <p>{`Total Calls: ${operationsPerMinute.totalCalls}`}</p>
                    <p>{`Total Failed Operations: ${operationsPerMinute.totalFailedOperations}`}</p>
                    <p>{`Total Operations 2+ sec: ${operationsPerMinute.totalOperations2OrOver}`}</p>
                    <p>{`Total Operations 30+ sec: ${operationsPerMinute.totalOperations30OrOver}`}</p>
                  </div>
                ) : (
                  <h3>Hover over a time bar to see operations per minute</h3>
                )}
              </div>
              <div style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="ml-4 mt-4 inline-block border border-black p-4 max-w-fit text-gray-500">
                  <h3 className="font-bold">Failed Attempts Summary</h3>
                  <p>{`Total Failed Attempts: ${failedAttemptsSummary?.totalFailedAttempts}`}</p>
                  <p>{`Total Failed Benign 1st Attempts: ${failedAttemptsSummary?.totalFailedBenign1stAttempts}`}</p>
                  <p>{`Total Failed Benign 2nd Attempts: ${failedAttemptsSummary?.totalFailedBenign2ndAttempts}`}</p>
                  <p>{`Total Failed Caused 1st Attempts: ${failedAttemptsSummary?.totalFailedCaused1stAttempts}`}</p>
                  <p>{`Total Failed Caused 2nd Attempts: ${failedAttemptsSummary?.totalFailedCaused2ndAttempts}`}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {status === 'pending' && (
          <>
            <div className="p-10">
              <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
            </div>
            <div className="text-blue-500 font-bold ml-4">
              Generating report for Suite ID: {suiteId}. This usually takes about 3-5 minutes.
              <br />
              <p className="text-gray-500">Elapsed Time: {formatElapsedTime(elapsedTime)}</p>
            </div>
          </>
        )}
        {error && <div>Error loading environment load report: {error.message}</div>}
        {!displayData && status !== 'pending' && !error && (
          <div className="text-gray-500 font-bold m-5">No data available. Please enter a Suite ID and submit to load the report.</div>
        )}
        {status !== 'pending' && displayData && !error && (
          <>
            {isChartLoading ? (
              <div className="flex justify-center items-center flex-col p-10">
                <WolfLoader customStyles={{ height: '50px', width: '100px' }} customText="" />
                <div className="text-blue-500 font-bold mt-10">Rendering Chart...</div>
              </div>
            ) : (
              <>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4 mt-2 mr-4"
                  onClick={() => {
                    setIsChartLoading(true);
                    setWaterfallChartData(displayData);
                    setSelectedOperations([]); // Clear selected operations
                    setTickArrayAndGraphDomain();
                    setHoveredBarIndex(null);
                    setSelectedBarIndex(null);
                    setIsChartLoading(false);
                  }}
                >
                  See All Operations In Suite
                </button>
                <button
                  onClick={handleDivDownload}
                  disabled={isLoading}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 mr-4"
                >
                  {isLoading ? 'Generating...' : 'Download Chart Screenshot'}
                </button>
                <button
                  onClick={toggleHeight}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                  disabled={isWaterFallHeightToggleLoading}
                >
                  {isWaterFallHeightToggleLoading ? 'Loading...' : 'Toggle Chart Height'}
                </button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'stretch',
                    marginLeft: '6.4%',
                    marginRight: '1.5%',
                    alignContent: 'stretch',
                    padding: 0,
                  }}
                >
                  <ResponsiveContainer width="100%" height={87}>
                    <BarChart layout="vertical" data={arrayOfMinutesX}>
                      <XAxis type="number" dataKey="value" domain={[0, 100]} hide={true} />
                      <YAxis type="category" dataKey="time" hide={true} />
                      {arrayOfMinutesY.map((minute, minuteIdx) => {
                        return (
                          <Bar
                            key={minuteIdx}
                            dataKey={minute.time}
                            stackId="a"
                            fill={minute.fill}
                            onMouseOver={() => {
                              setHoveredBarIndex(minuteIdx); // Update the hovered bar index
                              setOperationsPerMinute({
                                time: minute.time,
                                totalCalls: minute.totalCalls,
                                totalFailedOperations: minute.totalFailedOperations,
                                totalOperations2OrOver: minute.totalOperations2OrOver,
                                totalOperations30OrOver: minute.totalOperations30OrOver,
                              });
                            }}
                            onMouseOut={() => setHoveredBarIndex(null)} // Reset the hovered bar index
                            onClick={() => {
                              setIsChartLoading(true);

                              setWaterfallChartData(minute.traces);
                              setSelectedOperations(minute.operations); // Update selected operations

                              setTickArray([minute.startTimeOfFirstMin, minute.endTimeOfLastMin]);
                              setGraphDomain([minute.startTimeOfFirstMin, minute.endTimeOfLastMin]);
                              setSelectedBarIndex(minuteIdx); // Set the selected bar index
                              setHoveredBarIndex(null);
                              setTimeout(() => {
                                setIsChartLoading(false);
                              }, 3000); // Adjust the delay as needed
                            }}
                          >
                            <Cell
                              fill={minuteIdx === hoveredBarIndex ? '#3B82F6' : minute.fill} // Change fill on hover
                              stroke={minuteIdx === selectedBarIndex ? '#3B82F6' : 'none'} // Add outline on hover
                              strokeWidth={minuteIdx === selectedBarIndex ? 4 : 0} // Define outline width
                            />
                          </Bar>
                        );
                      })}
                      <Legend content={<CustomLegend />} verticalAlign="top" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <ResponsiveContainer width="100%" height={containerHeight}>
                  <BarChart data={waterfallChartData} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      domain={graphDomain}
                      type="number"
                      tickFormatter={(tick) => `${(tick / 1000) % 1 === 0 ? tick / 1000 : (tick / 1000).toFixed(2)} seconds`}
                      ticks={tickArray}
                    />
                    <YAxis
                      dataKey="startedDateTime"
                      type="category"
                      tickFormatter={(tick) => new Date(tick).toLocaleTimeString()} // Format the date/time
                      width={100} // Adjust the width as needed
                      reversed={true}
                    />
                    <Tooltip
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                              <p>{`Op Name: ${data.operationName}`}</p>
                              <p>{`Op Start Time: ${new Date(data.startedDateTime).toLocaleTimeString()}`}</p>
                              <p>{`Op End Time: ${new Date(data.endTime).toLocaleTimeString()}`}</p>
                              <p>{`Op Duration: ${
                                data.time === -1 ? 'Errored out' : data.time > 999 ? (data.time / 1000).toFixed(2) + ' sec' : data.time + ' ms'
                              }`}</p>
                              <p>{`Attempt Number: ${data.attemptNumber}`}</p>
                              <p>{`Attempt URL: ${data.attemptURL}`}</p>
                              <p>{`Attempt Status: ${data.attemptStatus}`}</p>
                              <p>{`Attempt Start Time: ${new Date(data.attemptStartedAt).toLocaleTimeString()}`}</p>
                              <p>{`Attempt End Time: ${new Date(data.endTimeOfLastTraceOfAttempt).toLocaleTimeString()}`}</p>
                              <p>{`Caused An Attempt To Fail: ${
                                data.causedAnAttemptToFailFailedTrace || data.causedAnAttemptToFailOver30Seconds ? 'Yes' : 'No'
                              }`}</p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend
                      content={() => (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                          <li>
                            <span style={{ backgroundColor: 'green', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>0 - 2 sec</span>
                            <span style={{ backgroundColor: '#d4870b', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>2 - 30 sec</span>
                            <span style={{ backgroundColor: 'red', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>30 sec or greater( Caused An Attempt To Fail)</span>
                            <span style={{ backgroundColor: 'purple', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>Failed Request That Caused An Attempt To Fail</span>
                          </li>
                          <li className="mt-2">
                            <span style={{ backgroundColor: 'pink', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>30 sec or greater( Did Not Cause An Attempt To Fail)</span>
                            <span style={{ backgroundColor: 'blue', width: 20, height: 20, display: 'inline-block', marginRight: 5 }}></span>
                            <span style={{ marginRight: 30 }}>Failed Request That Did Not Cause An Attempt To Fail</span>
                          </li>
                        </ul>
                      )}
                    />
                    {waterfallChartData?.map((traceRefLine, index) => {
                      const uniqueKey = `${traceRefLine.attemptId}-${traceRefLine.attemptStatus}-${traceRefLine.attemptNumber}`;
                      if (traceRefLine.attemptStatus === 'fail' && !uniqueTraceRefLines.has(uniqueKey)) {
                        uniqueTraceRefLines.add(uniqueKey);

                        let strokeColor;
                        switch (traceRefLine.attemptNumber) {
                          case 1:
                            strokeColor = 'orange';
                            break;
                          case 2:
                            strokeColor = 'red';
                            break;
                        }

                        const shouldRender = (strokeColor === 'orange' && showOrange) || (strokeColor === 'red' && showRed);

                        if (shouldRender) {
                          return (
                            <ReferenceLine
                              key={`ref-line-${index}`}
                              x={traceRefLine.attemptEndedAtRefPoint}
                              stroke={strokeColor}
                              strokeDasharray={
                                traceRefLine.causedAnAttemptToFailFailedTrace || traceRefLine.causedAnAttemptToFailOver30Seconds ? '0' : '3 3'
                              }
                              label={
                                <Label position="top" fill={strokeColor} fontSize={12} offset={10}>
                                  {traceRefLine.attemptNumber === 1 ? '1st' : '2nd'}
                                </Label>
                              }
                            />
                          );
                        }
                      }
                      return null;
                    })}
                    <Bar dataKey="duration" isAnimationActive={false}>
                      {waterfallChartData?.map((trace, index) => {
                        return <Cell key={`cell-${index}`} fill={trace.color} />;
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="toggle-buttons flex flex-row gap-2 m-4">
                  <label>
                    <input className="mr-2" type="checkbox" checked={showOrange} onChange={() => setShowOrange(!showOrange)} />
                    Show 1st Attempt Failures
                  </label>
                  <label>
                    <input className="mr-2" type="checkbox" checked={showRed} onChange={() => setShowRed(!showRed)} />
                    Show 2nd Attempt Failures
                  </label>
                  <div>
                    <span style={{ marginRight: 5, marginLeft: 30, fontWeight: 'bold' }}>- - -</span>
                    <span style={{ marginRight: 30 }}>
                      Attempt Failures Likely <strong>Not</strong> Caused By Failed Operation
                    </span>
                    <span style={{ marginRight: 5, fontWeight: 'bold' }}>━</span>
                    <span style={{ marginRight: 30 }}>
                      Attempt Failures <strong>Likely</strong> Caused By Failed Operation
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <br />
      </div>
      {status !== 'pending' && displayData && !error && (
        <>
          <div className="overflow-x-auto pt-4">
            <div className="flex h-15">
              <button
                onClick={() => setShowPassingAttempts(!showPassingAttempts)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2"
              >
                {showPassingAttempts ? 'Hide Passing Attempts' : 'Show Passing Attempts'}
              </button>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded p-2 m-2" onClick={downloadCSV}>
                Download CSV Of Summary Table
              </button>
            </div>
            <table className="min-w-full bg-white border border-gray-200 text-xs">
              <caption className="text-lg font-bold mb-2">
                {selectedOperations.length > 0 ? `Operation Summary For Minute ${operationsPerMinute?.time}` : 'Operation Summary For Whole Suite'}
              </caption>
              <thead>
                <tr>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Operation Name</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Total Calls For Operation</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Workflows That Called It</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Attempt Retries</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Attempt Failures</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Failures</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Duration 2+ sec</th>
                  <th className="px-2 py-1 border-b border-gray-200 tooltip relative text-left">Call Duration 30+ sec</th>
                </tr>
              </thead>
              <tbody>
                {selectedOperations.length > 0
                  ? selectedOperations.map((operation, index) => (
                      <React.Fragment key={index}>
                        <tr className="hover:bg-gray-100 cursor-pointer" onClick={() => toggleRow(index)}>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.operationName}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalCallsForOperation}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalWorkflowsCallingOperation}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalReAttempts}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalFailedAttempts}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalFailedCalls}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalCallsLongerThan2}</td>
                          <td className="px-2 py-1 border-b border-gray-200">{operation.totalCallsLongerThan30}</td>
                        </tr>
                        {expandedRowIndex === index && (
                          <tr>
                            <td colSpan={8} className="px-2 py-1 border-b border-gray-200">
                              <div className="bg-gray-100 p-2">
                                {operation.workflowsCallingOperation
                                  .filter((workflow) => workflow.attempts.some((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass'))
                                  .map((workflow, workflowIndex) => {
                                    const hasCriticalFailure = workflow.attempts.some(
                                      (attempt) => attempt.causedAnAttemptToFailFailedTrace || attempt.causedAnAttemptToFailOver30Seconds,
                                    );

                                    return (
                                      <div key={workflowIndex} className="mb-5" style={{ backgroundColor: hasCriticalFailure ? 'pink' : 'inherit' }}>
                                        <p className="font-bold">
                                          Workflow Name: <i>{workflow.workflowName}</i>
                                        </p>
                                        <ul>
                                          {workflow.attempts
                                            .filter((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass')
                                            .map((attempt, attemptIndex) => (
                                              <li key={attemptIndex} className="ml-4">
                                                <p>Attempt ID: {attempt.attemptId}</p>
                                                <p>
                                                  Attempt URL:{' '}
                                                  <a
                                                    href={attempt.attemptURL.toString()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                  >
                                                    {attempt.attemptURL.toString()}
                                                  </a>
                                                </p>
                                                <p>Attempt Number: {attempt.attemptNumber}</p>
                                                <p
                                                  style={{
                                                    color:
                                                      attempt.attemptStatus === 'pass'
                                                        ? 'green'
                                                        : attempt.attemptStatus === 'fail'
                                                        ? 'red'
                                                        : 'inherit',
                                                  }}
                                                >
                                                  Attempt Status: {attempt.attemptStatus}
                                                </p>
                                                <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                                  Failed operation Caused An Attempt To Fail:{' '}
                                                  {attempt.causedAnAttemptToFailFailedTrace ? 'Yes' : 'No'}
                                                </p>
                                                <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                                  Over 30 Seconds operation Caused An Attempt To Fail:{' '}
                                                  {attempt.causedAnAttemptToFailOver30Seconds ? 'Yes' : 'No'}
                                                </p>
                                                <hr className="mb-3" />
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  : allOperationsSummary &&
                    allOperationsSummary.map((operation, index) => (
                      <React.Fragment key={index}>
                        <tr className="hover:bg-gray-100 cursor-pointer" onClick={() => toggleRow(index)}>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.operationName}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsForOperation}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalWorkflowsCallingOperation}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalReAttempts}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalFailedAttempts}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalFailedCalls}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsLongerThan2}</td>
                          <td className="px-2 py-1 border-b border-gray-200 text-left">{operation.totalCallsLongerThan30}</td>
                        </tr>
                        {expandedRowIndex === index && (
                          <tr>
                            <td colSpan={8} className="px-2 py-1 border-b border-gray-200">
                              <div className="bg-gray-100 p-2">
                                {operation.workflowsCallingOperation
                                  .filter((workflow) => workflow.attempts.some((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass'))
                                  .map((workflow, workflowIndex) => {
                                    const hasCriticalFailure = workflow.attempts.some(
                                      (attempt) => attempt.causedAnAttemptToFailFailedTrace || attempt.causedAnAttemptToFailOver30Seconds,
                                    );

                                    return (
                                      <div key={workflowIndex} className="mb-5" style={{ backgroundColor: hasCriticalFailure ? 'pink' : 'inherit' }}>
                                        <p className="font-bold">
                                          Workflow Name: <i>{workflow.workflowName}</i>
                                        </p>
                                        <ul>
                                          {workflow.attempts
                                            .filter((attempt) => showPassingAttempts || attempt.attemptStatus !== 'pass')
                                            .map((attempt, attemptIndex) => (
                                              <li key={attemptIndex} className="ml-4">
                                                <p>Attempt ID: {attempt.attemptId}</p>
                                                <p>
                                                  Attempt URL:{' '}
                                                  <a
                                                    href={attempt.attemptURL.toString()}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 hover:underline"
                                                  >
                                                    {attempt.attemptURL.toString()}
                                                  </a>
                                                </p>
                                                <p>Attempt Number: {attempt.attemptNumber}</p>
                                                <p
                                                  style={{
                                                    color:
                                                      attempt.attemptStatus === 'pass'
                                                        ? 'green'
                                                        : attempt.attemptStatus === 'fail'
                                                        ? 'red'
                                                        : 'inherit',
                                                  }}
                                                >
                                                  Attempt Status: {attempt.attemptStatus}
                                                </p>
                                                <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                                  Failed operation Caused An Attempt To Fail:{' '}
                                                  {attempt.causedAnAttemptToFailFailedTrace ? 'Yes' : 'No'}
                                                </p>
                                                <p style={{ color: attempt.causedAnAttemptToFailFailedTrace ? 'red' : 'inherit' }}>
                                                  Over 30 Seconds operation Caused An Attempt To Fail:{' '}
                                                  {attempt.causedAnAttemptToFailOver30Seconds ? 'Yes' : 'No'}
                                                </p>
                                                <hr className="mb-3" />
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
