import TeamOverviewTable from './tables/TeamOverviewTable';
import CustomerOverviewTable from './tables/CustomerOverviewTable';

export default function ExecutiveOverviewBoard({ activeTab, tableData }) {

    const viewMap = {
        'Team View': <TeamOverviewTable tableData={tableData} />,
        'Customer View': <CustomerOverviewTable tableData={tableData} />,
    };
    return (
        <div>
            {viewMap[activeTab.type]}
        </div>
    );
}
