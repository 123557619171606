import _ from 'lodash';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

import { useState } from 'react';

import { TrackedTableModal } from './TrackedTableModal';
import { classNames } from '../../InvestigationBoard/helpers';
import { WeeklyTableModal } from './WeeklyTableModal';

import TimeTrackingRowOptions from './TimeTrackingRowOptions';

export function WeekRow({ week, weeklyTotals, dailyBreakdownsForWeek, isLastPart }) {
  const [showDailyBreakdown, setShowDailyBreakdown] = useState(false);
  const [showCustomerBreakdown, setShowCustomerBreakdown] = useState(false);

  // get basic totals for week and round to 2 decimals
  const totals = weeklyTotals.totals;
  for (const [category, total] of Object.entries(totals)) {
    totals[category] = _.round(total, 2);
  }

  // get weekly customer breakdown and round to 2 decimal
  const weeklyBreakdown = {};
  for (const dailyBreakdown of Object.values(dailyBreakdownsForWeek)) {
    for (const [customer, customerBreakdown] of Object.entries(dailyBreakdown)) {
      if (!weeklyBreakdown[customer]) {
        weeklyBreakdown[customer] = _.cloneDeep(customerBreakdown);
      } else {
        for (const [category, hours] of Object.entries(customerBreakdown)) {
          if (!weeklyBreakdown[customer][category]) {
            weeklyBreakdown[customer][category] = hours;
          } else {
            if (hours === '-' || hours === 0) {
              continue;
            } else if (weeklyBreakdown[customer][category] === '-') {
              weeklyBreakdown[customer][category] = hours;
            } else {
              const oldTotal = parseFloat(weeklyBreakdown[customer][category]);
              const newTotal = parseFloat(hours);
              weeklyBreakdown[customer][category] = (oldTotal + newTotal).toFixed(2);
            }
          }
        }
      }
    }
  }

  for (const [customer, customerBreakdown] of Object.entries(weeklyBreakdown)) {
    for (const [category, total] of Object.entries(customerBreakdown)) {
      if (total !== '-') {
        weeklyBreakdown[customer][category] = _.round(total, 2);
      }
    }
  }

  // guarantee order of weekly breakdown
  const weeklyBreakdownMap = new Map(Object.entries(weeklyBreakdown));
  const breakdownTotal = weeklyBreakdownMap.get('Total');
  weeklyBreakdownMap.delete('Total');
  weeklyBreakdownMap.set('Total', breakdownTotal);
  const orderedWeeklyBreakdown = Object.fromEntries(weeklyBreakdownMap);

  function getWeekRange(weekNumber) {
    const year = Object.keys(weeklyTotals)[0].split('-')[0];

    const startOfWeek = dayjs().year(+year).isoWeek(weekNumber).startOf('isoWeek');
    const endOfWeek = dayjs().year(+year).isoWeek(weekNumber).endOf('isoWeek');

    return `${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D, YYYY')}`;
  }

  function handleShowDailyBreakdown() {
    setShowDailyBreakdown((prev) => !prev);
  }

  function handleShowCustomerBreakdown() {
    setShowCustomerBreakdown((prev) => !prev);
  }

  return (
    <>
      <tr className="bg-white">
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
          )}
        >
          <span className="flex items-center gap-x-3">
            {week}
            <span className="text-gray-500 text-xs">{`(${getWeekRange(week)})`}</span>
          </span>
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell',
          )}
        >
          {totals.tracked}
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell',
          )}
        >
          {totals.ooo}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {totals.triage}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {totals.other}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {totals.overtime}
        </td>
        <td
          className={classNames(
            !isLastPart ? 'border-b border-gray-200' : '',
            totals.untracked > 2.5 ? 'bg-red-100' : '',
            totals.untracked > 0 && totals.untracked <= 2.5 ? 'bg-yellow-100' : '',
            'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
          )}
        >
          {totals.untracked}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          {totals.total}
        </td>
        <td className={classNames(!isLastPart ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
          <TimeTrackingRowOptions handleShowDailyBreakdown={handleShowDailyBreakdown} handleShowCustomerBreakdown={handleShowCustomerBreakdown} />
        </td>
      </tr>
      {showDailyBreakdown && (
        <WeeklyTableModal
          title={`Week #: ${week}`}
          totals={weeklyTotals}
          breakdowns={dailyBreakdownsForWeek}
          handleVisibility={handleShowDailyBreakdown}
        />
      )}
      {showCustomerBreakdown && (
        <TrackedTableModal title={`Week #: ${week}`} breakdown={orderedWeeklyBreakdown} handleShowTrackedDetails={handleShowCustomerBreakdown} />
      )}
    </>
  );
}
