import Message from './Message';
import MessageInput from './MessageInput';
import Thread from './Thread';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

function EmailEditor({ candidateInfo, emailData, isLoading, isFetching, isError, error }) {
  const [singleMessageHeaders, setSingleMessageHeaders] = useState({});
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedThread, setSelectedThread] = useState({});


  useEffect(() => {
    if (!isLoading && !isFetching && emailData?.threads?.length > 0) {
      const threads = emailData.threads;
      const mostRecentThread = threads[0];
      const mostRecentEmailHeaders = mostRecentThread.messages[mostRecentThread.messages.length - 1].headers;


      setSelectedThread(mostRecentThread);
      setSelectedThreadId(mostRecentThread.threadId);
      setSingleMessageHeaders(mostRecentEmailHeaders);
    }
  }, [emailData]);


  const handleThreadSelection = (thread) => {
    setSelectedThreadId(thread.threadId);
    setSelectedThread(thread);
    setSingleMessageHeaders(thread.messages[thread.messages.length - 1].headers);
  };


  return (
    <div className="flex flex-col bg-gray-900 text-white w-[85%] h-full">
      <div className="sticky top-0 z-20 bg-gray-800 p-4">
        {(isFetching && isLoading) ? null :
          <div className=" flex overflow-x-auto">
            {emailData?.threads?.map((thread, idx) => (
              <Thread
                key={thread.threadId}
                thread={thread}
                handleThreadSelection={handleThreadSelection}
                isSelected={thread.threadId === selectedThreadId}
                idx={idx}
              />
            ))}
          </div>
        }
      </div>



      <div className="flex-1 p-6 overflow-y-auto">
        {
          // Handle error state
          isError && (
            <div className="rounded-xl border border-gray-200 bg-white mx-2 mb-4">
              <h1>{'Error loading emails...'}</h1>
              <p>{error.message}</p>
            </div>
          )
        }

        {(isFetching && isLoading)
          ? (
            <div className='flex w-full justify-center align-center'>
              <CircularProgress />
            </div>
          )
          :
          emailData.threads && emailData.threads.length > 0 ? (
            <div >
              {selectedThread && selectedThread.messages && selectedThread.messages.map((message, idx) => (
                <Message
                  key={idx}
                  time={message.timestamp}
                  text={message.body}
                  sender={message.sender}
                />
              ))}
            </div>
          )
            :
            (
              <h1>{'Error loading emails...'}</h1>

            )}

      </div>
      <MessageInput
        candidate={candidateInfo}
        emailData={emailData}
        singleMessageHeaders={singleMessageHeaders}
      />
    </div>
  );
}

export default EmailEditor;
