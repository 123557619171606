import _ from 'lodash';
import { useMemo, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Tabs } from '../../UtilityComponents';
import * as Forms from '../forms';

import { classNames } from '../../InvestigationBoard/helpers';
import { deDuplicateCards, generateCardHash, generateCards } from '../helpers';

/**
 *
 * @param {object} props
 * @param {import('../types').ActiveCard[]} props.activeCards
 * @param {import('react').Dispatch<import('react').SetStateAction<import('../types').ActiveCard[]>>} props.setActiveCards
 * @param {import('../../InsightsExplorer/types').QAEFromLocalStorage[]} props.allUsers
 * @param {import('../../InsightsExplorer/types').QATeamFromLocalStorage[]} props.allTeams
 * @returns {import('react').JSX.Element}
 */
export default function AddCardModal({ activeCards, setActiveCards, allUsers, allTeams }) {
  // manage modal state
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  // manage tab state
  const [tabs, setTabs] = useState(
    /** @type {{ type: string, current: boolean }[]} */([
      { type: 'Packs', current: true },
      { type: 'Teams', current: false },
      { type: 'QAEs', current: false },
      { type: 'Tiers', current: false },
    ]),
  );
  const activeTab = tabs.find((tab) => tab.current);

  // handle form components
  const formMap = useMemo(
    () => ({
      Packs: (
        <Forms.PackCardForm
          packs={_.sortBy(
            allUsers.filter((user) => user.isManager),
            ['name'],
          )}
        />
      ),
      Teams: (
        <Forms.TeamCardForm
          teams={_.sortBy(
            allTeams.filter((team) => team.teamName !== 'TestTeam'),
            ['teamName'],
          )}
        />
      ),
      Tiers: (
        <Forms.TierCardForm
          qaes={_.sortBy(
            allUsers.filter((user) => !user.isLead && !user.isManager && user.isQAE && user.teamId),
            ['name'],
          )}
        />
      ),
      QAEs: (
        <Forms.QAECardForm
          qaes={_.sortBy(
            allUsers.filter((user) => !user.isLead && !user.isManager && user.isQAE && user.teamId),
            ['name'],
          )}
        />
      ),
    }),
    [],
  );

  // handle form submission
  const handleCardFormSubmit = async (event) => {
    event.preventDefault();

    // gether form submission data
    const formData = new FormData(event.currentTarget);
    const cardData = Object.fromEntries(formData.entries());

    // hash the cardData to get the cardSetId
    const cardSetId = await generateCardHash(cardData);

    // create new card
    const newCards = generateCards(cardData, cardSetId, allUsers, allTeams);

    // detect duplicates in already active cards
    const deDuplicatedNewCards = deDuplicateCards(activeCards, newCards);

    // set new active cards and close modal
    setActiveCards([...activeCards, ...deDuplicatedNewCards]);
    handleModalClose();
  };

  return (
    <>
      <button
        type="button"
        onClick={handleModalOpen}
        className={classNames(
          activeCards.length > 0 && 'ml-4',
          'rounded-full bg-indigo-500 p-1 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
        )}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
      <Dialog
        fullWidth
        open={modalOpen}
        onClose={handleModalClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => handleCardFormSubmit(event),
        }}
      >
        <DialogTitle>{`Add ${activeTab.type} Card`}</DialogTitle>
        <Tabs tabs={tabs} setTabs={setTabs} activeTab={activeTab} />
        <DialogContent>{formMap[activeTab.type]}</DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button type="submit">Add Card</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
