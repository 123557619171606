import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

// Bubble Chart Data
export const aggregateData = (suiteSnapshots, timezone) => {
    const aggregatedData = {};
  
    suiteSnapshots.forEach((snapshot) => {
      const localDate = dayjs(snapshot.startTime).tz('America/Los_Angeles').add(7, 'hour').tz(timezone);
      const dateString = localDate.format('YYYY-MM-DD');
      const startHour = localDate.hour();
  
      const { runsByLastAttemptStatus, customerName, triggerName } = snapshot;
  
      Object.keys(runsByLastAttemptStatus).forEach((statusKey) => {
        if (statusKey === 'passedFirst' || statusKey === 'passedSecond' || statusKey === 'passedThird' || statusKey === 'autoTriaged') return;
  
        const attempts = runsByLastAttemptStatus[statusKey];
  
        attempts.forEach((attempt) => {
          const attemptDuration = attempt.uv + attempt.pv;
          const contribution = 1; // Each attempt contributes 1 to the value
  
          const distributeContribution = (hour, duration, contribution, dateString) => {
            let remainingDuration = duration;
            let currentHour = hour;
            let currentDate = dateString;
  
            while (remainingDuration > 0) {
              const nextKey = `${currentDate}-${currentHour}`;
              if (!aggregatedData[nextKey]) {
                aggregatedData[nextKey] = {
                  date: currentDate,
                  day: dayjs(currentDate).format('dddd'),
                  hour: `${currentHour % 12 === 0 ? 12 : currentHour % 12}${currentHour >= 12 ? 'p' : 'a'}`,
                  hourNum: currentHour,
                  value: 0,
                  newAttempts: 0,
                  finishedAttempts: 0,
                  details: {},
                  color: 'green', // Default color
                };
              }
  
              if (!aggregatedData[nextKey].details[customerName]) {
                aggregatedData[nextKey].details[customerName] = {
                  customerName,
                  triggerName,
                  contribution: 0,
                };
              }
  
              aggregatedData[nextKey].value += contribution;
              if (remainingDuration <= 60) {
                aggregatedData[nextKey].newAttempts += contribution;
              }
              aggregatedData[nextKey].details[customerName].contribution += contribution;
  
              remainingDuration -= 60; // Subtract 60 minutes for each hour
              currentHour += 1; // Move to the next hour
  
              // Handle rollover to the next day
              if (currentHour === 24) {
                currentHour = 0;
                currentDate = dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD');
              }
            }
          };
  
          distributeContribution(startHour, attemptDuration, contribution, dateString);
        });
      });
    });
  
    const allHours = Array.from({ length: 24 }, (_, i) => ({
      hour: `${i % 12 === 0 ? 12 : i % 12}${i >= 12 ? 'p' : 'a'}`,
      hourNum: i,
    }));
  
    const completeData = {};
    Object.values(aggregatedData).forEach((entry) => {
      if (!completeData[entry.date]) {
        completeData[entry.date] = allHours.map(({ hour, hourNum }) => ({
          date: entry.date,
          day: entry.day,
          hour,
          hourNum,
          value: 0,
          newAttempts: 0,
          finishedAttempts: 0,
          details: {},
          color: '#FBEC5D', // Default color
        }));
      }
    });
  
    Object.keys(aggregatedData).forEach((key) => {
      const entry = aggregatedData[key];
      const hourEntry = completeData[entry.date].find((e) => e.hourNum === entry.hourNum);
      if (hourEntry) {
        hourEntry.value = entry.value;
        hourEntry.newAttempts = entry.newAttempts;
        hourEntry.details = entry.details;
      }
    });
  
    // Calculate color based on comparison with the previous and next bubble
    Object.keys(completeData).forEach((date) => {
      completeData[date].forEach((hourEntry, index, arr) => {
        if (index > 0) {
          const prevHourEntry = arr[index - 1];
          const nextHourEntry = index < arr.length - 1 ? arr[index + 1] : null;
  
          // Update color based on the condition
          if (hourEntry.value === 0 || (nextHourEntry && nextHourEntry.value === 0)) {
            hourEntry.color = '#018749'; // Green if current or next value is 0
          } else if (hourEntry.value > prevHourEntry.value) {
            const delta = hourEntry.value - prevHourEntry.value;
            let colorValue = '#FAA0A0'; // Least angry red
            if (delta > 75) {
              colorValue = '#811331'; // Most angry red
            } else if (delta > 15) {
              colorValue = '#D70040'; // Medium angry red
            }
            hourEntry.color = colorValue;
          } else {
            const delta = prevHourEntry.value - hourEntry.value;
            let colorValue =  `#E49B0F`; // Most angry yellow
            if (delta > 75) {
              colorValue = '#AFE1AF'; // Least angry yellow
            } else if (delta > 15) {
              colorValue = '#FBEC5D'; // Medium angry yellow
            }
            hourEntry.color = colorValue;
          }
        }
      });
    });

    return Object.values(completeData)
    .flat()
    .sort((a, b) => {
      const dateA = dayjs(`${a.date} ${a.hourNum}`, 'YYYY-MM-DD H').tz(timezone);
      const dateB = dayjs(`${b.date} ${b.hourNum}`, 'YYYY-MM-DD H').tz(timezone);
      return dateA.unix() - dateB.unix(); // Order by ascending date and time
    });
};



// ScatterPlotData
export const prepareScatterData = (suiteSnapshots, timezone) => {
  const suiteMap = new Map();

  // Map of suites by their ID
  suiteSnapshots.forEach((snapshot) => {
    suiteMap.set(snapshot.id, {
      ...snapshot,
      adjustedTotalTimeInMinutes: snapshot.totalTimeInMinutes, // Initialize adjusted total time with the original total time
    });
  });

  // Sort suiteSnapshots from last to first based on start time using dayjs
  const sortedSnapshots = [...suiteSnapshots].sort((a, b) => dayjs(b.startTime).unix() - dayjs(a.startTime).unix());

  // Add the total time of the superseding suites to the corresponding original suites
  sortedSnapshots.forEach((snapshot) => {
    if (snapshot.supersededSuiteIds.length > 0) {
      snapshot.supersededSuiteIds.forEach((supersededId) => {
        if (suiteMap.has(supersededId)) {
          suiteMap.get(snapshot.id).adjustedTotalTimeInMinutes += suiteMap.get(supersededId).adjustedTotalTimeInMinutes;
        }
      });
    }
  });

  const scatterData = [];

  suiteMap.forEach((snapshot) => {
    const localDate = dayjs(snapshot.startTime).tz('America/Los_Angeles').add(7, 'hour').tz(timezone);
    const totalTimeInMinutes = Math.min(snapshot.adjustedTotalTimeInMinutes, 1440); // Cap at 24 hours

    scatterData.push({
      id: snapshot.id,
      date: localDate.format('YYYY-MM-DD'),
      day: localDate.format('dddd'),
      dayOfWeek: localDate.day(), // 0 for Sunday, 1 for Monday, etc.
      time: localDate.format('HH:mm'),
      timeInMinutes: localDate.hour() * 60 + localDate.minute(),
      totalTimeInMinutes: totalTimeInMinutes, // Time including superseding suites
      actualTotalTime: snapshot.totalTimeInMinutes, // Keep the actual total time for tooltip
      value: snapshot.numberOfRuns - snapshot.numberOfRunsPassingOnFirstAttempt - snapshot.numberOfRunsAutoTriaged - snapshot.numberOfRunsPassingOnSecondAttempt - snapshot.numberOfRunsPassingOnThirdAttempt,
      bubbleSize: ((snapshot.numberOfRuns - snapshot.numberOfRunsPassingOnFirstAttempt - snapshot.numberOfRunsAutoTriaged - snapshot.numberOfRunsPassingOnSecondAttempt - snapshot.numberOfRunsPassingOnThirdAttempt) * 1.3) + 100, // Adjust bubble size
      customerName: snapshot.customerName,
      triggerName: snapshot.triggerName,
      isSuperseded: snapshot.runsByLastAttemptStatus.supersededRuns.length > 0,
      supersededSuiteIds: snapshot.supersededSuiteIds,
    });
  });

  return scatterData.sort((a, b) => {
    const dateA = dayjs(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm').tz(timezone);
    const dateB = dayjs(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm').tz(timezone);
    return dateA.unix() - dateB.unix();
  });
};

// Prepare data for LineChart
export const prepareLineChartData = (data, timeZone) => {
  const aggregatedData = data.reduce((acc, item) => {
    const date = dayjs(item.startTime).tz('America/Los_Angeles').add(7, 'hour').tz(timeZone);
    const dayOfWeek = date.day();
    const hour = date.hour();
    
    // Only include Monday (1) through Friday (5)
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = Array(24).fill().map(() => ({
          value: 0,
          newAttempts: 0,
          finishedAttempts: 0,
          details: {},
        }));
      }
      
      const hourData = acc[dayOfWeek][hour];
      const failures = item.numberOfRuns - item.numberOfRunsPassingOnFirstAttempt;
      
      hourData.value += failures;
      hourData.newAttempts += item.numberOfRuns;
      hourData.finishedAttempts += item.numberOfRunsPassingOnFirstAttempt;
      
      if (!hourData.details[item.customerName]) {
        hourData.details[item.customerName] = {};
      }
      if (!hourData.details[item.customerName][item.triggerName]) {
        hourData.details[item.customerName][item.triggerName] = 0;
      }
      hourData.details[item.customerName][item.triggerName] += failures;
    }
    
    return acc;
  }, {});

  return Object.entries(aggregatedData).map(([day, hourlyData]) => ({
    day: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day],
    data: hourlyData.map((hourData, hourNum) => ({
      hourNum,
      ...hourData,
    })),
  }));
};
