import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WolfLoader from '../../WolfLoader/WolfLoader';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../utils/network';
import { useState } from 'react';
import ProgressDateRange from './ProgressDateRange';
import dayjs from 'dayjs';
import ClientMetricsTable from './ClientMetricsTable';

export default function CreationAndMaintenanceProgress({ team }) {
  const [expanded, setExpanded] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(8, 'week').startOf('week'));
  const [endDate, setEndDate] = useState(dayjs());

  // Modified useQuery to only run when accordion is expanded
  const { data, isFetching, isLoading, isError, error } = useQuery({
    queryKey: ['clientSummary'],
    queryFn: () => sendGetRequest(`/client-summaries/${team.id}?startDate=${dayjs(startDate)}&endDate=${dayjs(endDate)}`),
    select: ({ data }) => data,
    enabled: expanded, // Only fetch when accordion is expanded
  });

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  // Function to update the date range
  const handleDateChange = (date, type) => {
    if (type === 'start') {
      // Ensure start date is before end date
      if (date.isAfter(endDate)) setEndDate(date);
      setStartDate(date);
    } else {
      // Ensure end date is after start date
      if (date.isBefore(startDate)) setStartDate(date);
      setEndDate(date);
    }
  };

  return (
    <Box padding={1}>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="creation-maintenance-content" id="creation-maintenance-header">
          <Typography variant="h6">Creation and Maintenance Progress</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccordionContent
            data={data}
            startDate={startDate}
            endDate={endDate}
            team={team}
            handleDateChange={handleDateChange}
            isFetching={isFetching}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function AccordionContent({ data, startDate, endDate, team, handleDateChange, isFetching, isLoading, isError, error }) {
  // Show load spinner on initial load
  if (isFetching && isLoading)
    return (
      <Box>
        <WolfLoader customStyles={{ height: '100%' }} />
      </Box>
    );
  if (isError) return <Typography>{error.message}</Typography>;
  return (
    <>
      <ProgressDateRange handleDateChange={handleDateChange} startDate={startDate} endDate={endDate} />
      <ClientMetricsTable data={data} startDate={startDate} endDate={endDate} team={team} />
    </>
  );
}
