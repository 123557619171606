import { useState } from "react";
// @ts-ignore
import emailTemplates from './emailTemplate.jsx';
import '../../../css/hiring.css';
import { sendCandidateEmail } from "../api.js";
import { Editor, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import ReactDOMServer from 'react-dom/server';

import Toast from '../../Layout/Toast';

const MessageInput = ({ candidate, singleMessageHeaders }) => {
  const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
  const [isDisabled, setIsDisabled] = useState(false);
  const [toast, setToast] = useState(<></>);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());


  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle template selection from buttons or dropdown
  const handleTemplateSelection = (templateName) => {

    const templateContent = templateName.content(candidate);
    const htmlString = ReactDOMServer.renderToString(templateContent);
    const blocksFromHTML = stateFromHTML(htmlString);
    const editorState = EditorState.createWithContent(blocksFromHTML);



    setEditorState(editorState);
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleSendingEmail = async ({ candidate, singleMessageHeaders }) => {
    // Disable the button
    setIsDisabled(true);
    
    // Clears the input box
    setEditorState(() => EditorState.createEmpty());
    
    let state = editorState.getCurrentContent();
    let currentEditorState = stateToHTML(state);
    let result = await sendCandidateEmail({ currentEditorState, candidate, singleMessageHeaders });

    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success
      ? `Email sent to candidate.`
      : 'Something went wrong! Unable to send email.';

    setToast(<Toast title={title} message={message} key={new Date().toISOString()} isSuccess={success} />);

    // Re-enable the button
    setIsDisabled(false);
  };


  return (
    <div className="template-container flex items-center p-4 border-t border-gray-700 bg-gray-900 h-[40%]">
      <div
        className="template-buttons flex flex-col items-center justify-center space-y-2.5 w-[33%] h-full">
        {/* ========================= Buttons ========================= */}
        <button
          className="reject-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => handleTemplateSelection(
            emailTemplates.find((item) => item.name === "Template - Reject Take-home"),
          )}
        >
          Reject Take Home
        </button>
        <button
          className="final-round-button bg-gray-700 text-white rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-2/3 p-0.75"
          onClick={() => handleTemplateSelection(
            emailTemplates.find((item) => item.name === "Template - Schedule Final Round"),
          )}
        >
          Schedule Final Round
        </button>

        {/* ========================== Custom Dropdown ========================== */}
        <div className=" relative inline-block w-2/3 ">
          <button
            onClick={toggleDropdown}
            className="template-dropdown-button bg-white text-gray-700 rounded-md shadow-md hover:bg-gray-200 focus:outline-none w-full p-0.75"
          >
            Choose a template
          </button>

          {isOpen && (
            <div className="template-select absolute bottom-full mb-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <ul className="py-1 max-h-48 overflow-y-auto divide-y divide-solid divide-gray-200">
                {emailTemplates.map((template, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handleTemplateSelection(template)}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                    >
                      {template.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* ======================= Email Textarea ======================= */}
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        placeholder={"Type your message here..."}
      />
      <button 
        onClick={() => {
          handleSendingEmail({
            candidate, singleMessageHeaders,
            });
        }} 
        className="ml-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
        style={
          isDisabled ? {
            backgroundColor: "grey",
            cursor: "not-allowed",
          } : null
        }
        disabled={isDisabled}
      >  
        Send
      </button>
      {toast}
    </div>
  );
};

export default MessageInput;
