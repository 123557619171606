import { Dispatch, SetStateAction } from 'react';
import { QaeCreationOutlineMaintenanceTaskInsights, TeamInsightsFilter } from '../types';

import { useQuery } from '@tanstack/react-query';

import WolfLoader from '../../WolfLoader/WolfLoader';
import { WeeklyMaintenanceChart } from '../charts';
import { WeeklyMaintenanceComparisonTable } from '../tables';

import { getCreationOutlineMaintenanceInsights, protectFilters, sumAllCreationOutliningMaintenanceTasks } from '../helpers';

import { WOLF_LOGO } from '../../../constants';

type SumTypeKeys = 'sumTests' | 'sumTasks';

export default function TeamMaintenanceView({ filters }: { filters: TeamInsightsFilter; setFilters: Dispatch<SetStateAction<TeamInsightsFilter>> }) {
  // protect team filters from potential stale state
  filters = protectFilters(filters);

  // set filter type to `maintenance`
  filters.types = ['testMaintenance'];

  const {
    data: maintenanceInsights,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['maintenanceInsights', filters],
    queryFn: () => getCreationOutlineMaintenanceInsights(filters),
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const teamSums = sumAllCreationOutliningMaintenanceTasks(maintenanceInsights?.teamTasksByWeekAndType ?? []);

  return (
    <div className="min-h-screen px-4 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-6 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          {filters.entity.type === 'team' && (
            <img src={maintenanceInsights.team.imageUrl ?? WOLF_LOGO} alt="avatar" className="h-14 w-14 rounded-full" />
          )}
          <span className="font-semibold">{maintenanceInsights[filters.entity.type].name}</span>
        </div>
      </div>
      <div className="isolate flex-col divide-y divide-gray-200 border">
        <div className="isolate flex divide-x divide-gray-200">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tests Completed: {teamSums.sumTests}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Total Tasks/Workflows Completed: {teamSums.sumTasks}
          </span>
        </div>
        {teamSums && (
          <div className="isolate flex divide-x divide-gray-200">
            {(['sumTests', 'sumTasks'] as SumTypeKeys[]).map((sumType, sumTypeIdx) => (
              <span
                key={sumTypeIdx}
                className="flex-col space-y-2 group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-sm"
              >
                <p>
                  Maintenance - {sumType === 'sumTests' ? 'Tests' : 'Tasks'} {teamSums.sumsByType.testMaintenance?.[sumType] || 0}
                </p>
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="py-4">
        <WeeklyMaintenanceChart data={maintenanceInsights} />
      </div>
      <div className="border p-4 grid 2xl:grid-cols-2 4xl:grid-cols-3 grid-flow-row gap-4">
        {maintenanceInsights.team &&
          maintenanceInsights.team.members
            ?.filter((m) => !m.isManager)
            .sort((a, b) => {
              // Put the lead at the end of the list
              if (a.isLead && !b.isLead) return 1;
              if (!a.isLead && b.isLead) return -1;

              return a.name.localeCompare(b.name);
            })
            .map((member, mIdx) => {
              const qaeMaintenanceInsights: QaeCreationOutlineMaintenanceTaskInsights = {
                qae: member,
                qaeTasksByWeekAndType: maintenanceInsights.teamTasksByWeekAndType.filter(
                  (task) => task.completed_by_qaw_id === member.qawId && task.type === 'testMaintenance',
                ),
              };
              const qaeSums = sumAllCreationOutliningMaintenanceTasks(qaeMaintenanceInsights.qaeTasksByWeekAndType);
              return (
                <div key={mIdx} className="shadow">
                  <WeeklyMaintenanceComparisonTable
                    maintenanceInsights={qaeMaintenanceInsights}
                    qaeSums={qaeSums}
                    teamMaintenanceInsights={maintenanceInsights}
                    teamSums={teamSums}
                  />
                </div>
              );
            })}
      </div>
    </div>
  );
}
