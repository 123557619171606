import { Box, Button, Snackbar, TextField } from '@mui/material';
import { NoInputDatePicker } from './subComponents';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getLastIncompleteMilestone, milestoneCreateReq, validateMilestone, styles } from './utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { hasOrHave, plural } from '../../../InvestigationBoard/helpers';

const today = dayjs();

export function CreationForm({ client, milestones }) {
  const activeTestCount = client.activeTestCount;
  const lastIncompleteMilestone = getLastIncompleteMilestone(milestones);
  const lastMilestoneDueDate = lastIncompleteMilestone?.externalDueDate || today;
  const lastTargetTestCount = lastIncompleteMilestone?.targetTestCount || 0;

  // Target test count must be greater than the existing test count and the previous
  // milestone's target test count
  const expectedTestCount = Math.max(activeTestCount, lastTargetTestCount);
  const queryClient = useQueryClient();

  const [values, setValues] = useState({
    name: '',
    targetTestCount: `${expectedTestCount + 10}`,
    testsPerWeek: `${Math.ceil(10 / 3)}`,
    notes: '',
    startDate: dayjs(lastMilestoneDueDate),
    internalDueDate: dayjs(lastMilestoneDueDate).add(1, 'week'),
    externalDueDate: dayjs(lastMilestoneDueDate).add(3, 'week'),
  });

  const [errors, setErrors] = useState({
    name: '',
    notes: '',
    targetTestCount: '',
    startDate: '',
    externalDueDate: '',
  });

  // Set the minimum start date to the external due date of the last incomplete milestone
  const [minStartDate, setMinStartDate] = useState(dayjs(lastMilestoneDueDate));

  // State for the result message after creating a milestone
  const [showResMsg, setShowResMsg] = useState(false);

  const updateValues = (key, val) => {
    // Account for start date being less than 1 week before internal due date
    const updatedValues = {};
    if (key === 'startDate') {
      const validInternalDueDate = dayjs(val).add(1, 'week');
      if (dayjs(values.internalDueDate).isBefore(validInternalDueDate)) {
        updatedValues.internalDueDate = validInternalDueDate;
        updatedValues.externalDueDate = dayjs(validInternalDueDate).add(2, 'week');
      }
    }

    // Update external due date if internal due date is updated
    if (key === 'internalDueDate') {
      updatedValues.externalDueDate = dayjs(val).add(2, 'week');
    }

    // Update tests per week if start date is updated
    if (key === 'startDate') {
      const numWeeks = dayjs(values.externalDueDate).diff(val, 'week');
      updatedValues.testsPerWeek = `${Math.ceil((+values.targetTestCount - expectedTestCount) / numWeeks)}`;
    }

    // Update tests per week if external due date is updated
    if (key === 'externalDueDate') {
      const numWeeks = dayjs(val).diff(values.startDate, 'week');
      updatedValues.testsPerWeek = `${Math.ceil((+values.targetTestCount - expectedTestCount) / numWeeks)}`;
    }

    // Update tests per week if target test count is updated
    if (key === 'targetTestCount') {
      const numWeeks = dayjs(values.externalDueDate).diff(values.startDate, 'week');
      const newTestCount = +val - expectedTestCount;

      updatedValues.testsPerWeek = `${Math.ceil(newTestCount / numWeeks)}`;
    }

    // Update target test count if tests per week is updated
    if (key === 'testsPerWeek') {
      const numWeeks = dayjs(values.externalDueDate).diff(values.startDate, 'week');
      const newTestCount = +val * numWeeks;

      updatedValues.targetTestCount = `${expectedTestCount + newTestCount}`;
    }

    // Update the values object
    setValues((prev) => ({
      ...prev,
      ...updatedValues,
      [key]: val,
    }));

    // Clear the error message for the updated field
    setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const {
    mutate: createMilestone,
    isPending,
    isError,
  } = useMutation({
    mutationFn: milestoneCreateReq,
    onSuccess: (_data, variables) => {
      // Refetch the client summary data
      queryClient.invalidateQueries({ queryKey: ['clientSummary', client.id] });

      // Set the new min start date to the external due date of the milestone that was just created
      const newStartDate = dayjs(variables.internalDueDate).add(2, 'week');
      setMinStartDate(newStartDate);

      // Reset the form
      setValues({
        name: '',
        targetTestCount: '',
        testsPerWeek: '',
        notes: '',
        startDate: newStartDate,
        internalDueDate: dayjs(newStartDate).add(1, 'week'),
        externalDueDate: dayjs(newStartDate).add(3, 'week'),
      });
    },
    onSettled: () => {
      // Show the toast message
      setShowResMsg(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the request data
    const foundErrors = validateMilestone(values);

    if (foundErrors) {
      // Set the errors object
      return setErrors((prev) => ({ ...prev, ...foundErrors }));
    } else {
      // Create the milestone
      createMilestone({
        name: values.name,
        notes: values.notes,
        targetTestCount: +values.targetTestCount,
        startDate: dayjs(values.startDate).toDate(),
        externalDueDate: dayjs(values.externalDueDate).toDate(),
        customerId: client.id,
        lastTargetTestCount,
        activeTestCount,
      });
    }
  };

  return (
    <Box sx={{ p: '1em 4em 1em 4em', display: 'flex', flexDirection: 'column', ...styles }} component="form" onSubmit={handleSubmit}>
      <TextField
        id="name"
        label="Milestone Name"
        sx={{ mb: '.7em' }}
        value={values.name}
        onChange={(e) => updateValues('name', e.target.value)}
        required
        helperText={errors.name}
        error={!!errors.name}
      />
      <TextField
        label="Notes"
        multiline
        minRows={3}
        sx={{ mb: '.7em' }}
        value={values.notes}
        onChange={(e) => updateValues('notes', e.target.value)}
        InputProps={{ inputProps: { min: 1 } }}
        required
        helperText={errors.notes}
        error={!!errors.notes}
      />
      <Box sx={{ display: 'flex', mb: '.7em' }}>
        <NoInputDatePicker
          label="Start Date"
          sx={{ mr: '.7em' }}
          minDate={minStartDate}
          value={values.startDate}
          onChange={(val) => updateValues('startDate', val)}
          required
          helperText={errors.startDate}
          error={!!errors.startDate}
        />
        <NoInputDatePicker
          label="External Due Date"
          minDate={dayjs(values.startDate).add(1, 'week')}
          value={values.externalDueDate}
          onChange={(val) => updateValues('externalDueDate', val)}
          required
          helperText={errors.externalDueDate}
          error={!!errors.externalDueDate}
        />
      </Box>
      <TextField
        label="Target Total Test Count"
        type="number"
        sx={{ mb: '.7em' }}
        value={values.targetTestCount}
        onChange={(e) => updateValues('targetTestCount', e.target.value)}
        InputProps={{ inputProps: { min: expectedTestCount + 1 } }}
        helperText={errors.targetTestCount}
        error={!!errors.targetTestCount}
      />
      <TextField
        label="Tests Per Week"
        type="number"
        sx={{ mb: '.7em' }}
        value={values.testsPerWeek}
        onChange={(e) => updateValues('testsPerWeek', e.target.value)}
        InputProps={{ inputProps: { min: 3 } }}
        helperText={
          errors.targetTestCount ||
          `*${Math.max(+values.targetTestCount - expectedTestCount, 0)} new tests over ${Math.ceil(
            dayjs(values.externalDueDate).diff(dayjs(values.startDate), 'weeks', true),
          )} weeks${
            milestones.length
              ? `, assuming ${expectedTestCount} tests were activated at the end of the last milestone.`
              : ` (${activeTestCount} test${plural(activeTestCount)} ${hasOrHave(activeTestCount)} already been activated)`
          }`
        }
        error={!!errors.targetTestCount}
      />
      <Button variant="contained" type="submit">
        {isPending ? 'Creating...' : 'Create'}
      </Button>
      <Snackbar
        open={showResMsg}
        ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
        autoHideDuration={3000}
        onClose={() => setShowResMsg(false)}
        message={!isError ? '🎉 Created milestone successfully!' : "⛔️ Couldn't create milestone, please reach out in #dragons for assistance."}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
}
