import { useMutation } from '@tanstack/react-query';
import { sendPostRequest } from '../../../utils/network';

export default function GoogleCodeConverter() {
  const codeMutation = useMutation({
    mutationFn:
      /**
       *
       * @param {string} code
       * @returns {Promise}
       */
      (code) => {
        return sendPostRequest(`/oauth/code/google`, { code });
      },
    onSuccess: (data) => {
      if (data === undefined) {
        reportError('Failed to fetch the new rate.');
        return;
      }

      console.log(data);
    },
    onError: (error) => {
      reportError(error);
    },
  });

  function convertCode() {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    codeMutation.mutate(code);
  }

  return (
    <>
      <button
        type="button"
        className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        onClick={() => {
          convertCode();
        }}
      >
        Convert Code on Server
      </button>
      <div className="pt-8">
        <a href="/google/auth" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
          Restart the Auth Flow
        </a>
      </div>
    </>
  );
}
