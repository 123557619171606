import { sendPostRequest } from '../../utils/network';

/**
 * Creates a new task
 * @param {import('../../types').QAWTask} taskData
 * @returns {Promise<{data: object, success: boolean, message: string}>}
 */
export default async function createNewTask(taskData) {
    try {
        const options = {
            team: {
                id: taskData.customer.qawId,
                name: taskData.customer.officialName,
            },
            taskDetails: {
                type: 'generic',
                assignedTo: { qawId: taskData.assignee.qawId },
                user: taskData.assignee,
                dueAt: taskData.dueDate,
                name: taskData.taskName,
            },
        };

        const res = await sendPostRequest('/create-generic-task', options);

        if (!res.success) {
            throw new Error(res.error);
        }

        return {
            data: res.jsonData,
            success: res.success,
            message: "Task Created Successfully",
        };
    } catch (error) {
        throw new Error(error.message);
    }
}
