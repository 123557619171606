import dayjs from 'dayjs';
import LZString from 'lz-string';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Header from '../Layout/Header';
import { InsightsPlaygroundFilters } from './filters';
import { ImpactPlaygroundChart } from './charts';
import { ResponsiveContainer } from 'recharts';

import useQueryKeyData from '../../hooks/useQueryKeyData';

/**
 * Primary insights playground view
 * @returns {import('react').JSX.Element}
 */
export default function InsightsPlayground() {
  // Query user data from Tanstack network
  const { data: allQAWUsers } = useQueryKeyData(['getAllQAWUsers']);
  const allUsers = /** @type {import('../InsightsExplorer/types').User[]} */ (
    allQAWUsers.filter((user) => user.isQAE || user.isManager || user.isLead)
  );
  const allTeams = /** @type {import ('../InsightsExplorer/types').QATeamFromLocalStorage[]}  */ (JSON.parse(localStorage.getItem('teams')));

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries(searchParams.entries());

  // handle default dates from search params or subtract 1 month
  const [dates, setDates] = useState(
    /** @type {{gte: string, lte: string}} */({
      gte: paramsMap.gte || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      lte: paramsMap.lte || dayjs().format('YYYY-MM-DD'),
    }),
  );

  // track state for active cards
  const [activeCards, setActiveCards] = useState(
    /** @type {import('./types').ActiveCard[]} */(
      paramsMap.activeCards ? JSON.parse(LZString.decompressFromEncodedURIComponent(paramsMap.activeCards)) : []
    ),
  );

  // track state for visible lines
  const [lineProps, setLineProps] = useState(
    /** @type {{[key: string]: boolean}} */(
      paramsMap.activeLines ? JSON.parse(LZString.decompressFromEncodedURIComponent(paramsMap.activeLines)) : {}
    ),
  );

  // handle updating search params
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('gte', dates.gte);
    newSearchParams.set('lte', dates.lte);

    if (activeCards.length > 0) {
      newSearchParams.set('activeCards', LZString.compressToEncodedURIComponent(JSON.stringify(activeCards)));
    }

    if (Object.values(lineProps).filter((value) => value === true).length > 0) {
      newSearchParams.set(
        'activeLines',
        LZString.compressToEncodedURIComponent(JSON.stringify(Object.fromEntries(Object.entries(lineProps).filter(([, value]) => value === true)))),
      );
    } else {
      newSearchParams.delete('activeLines');
    }

    setSearchParams(newSearchParams);
  }, [dates, activeCards, lineProps]);

  return (
    <div className="min-h-full py-4">
      <Header text="Insights Playground" />
      <InsightsPlaygroundFilters
        allUsers={allUsers}
        allTeams={allTeams}
        dates={dates}
        setDates={setDates}
        activeCards={activeCards}
        setActiveCards={setActiveCards}
        setLineProps={setLineProps}
      />
      <ResponsiveContainer width="100%" height="100%">
        <ImpactPlaygroundChart
          allUsers={allUsers}
          allTeams={allTeams}
          dates={dates}
          activeCards={activeCards}
          lineProps={lineProps}
          setLineProps={setLineProps}
        />
      </ResponsiveContainer>
    </div>
  );
}
