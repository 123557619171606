// React imports
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';


// Material UI Imports
import { Box } from '@mui/material';

// Data imports
import EditableCell from '../helpers/EditableCell';
import { getAllJobSources } from '../api';
import { handleSubmissionChange, handleCandidateInfoChange } from '../helpers/hiringHelpers';


const FinalRoundDetailPanel = ({ row, queryClient }) => {
  const { data: jobSources, isLoading, isFetching } = useQuery({
    queryKey: ['allJobSources'],
    queryFn: getAllJobSources,
   
  });

  // Initialize form state with existing candidate data
  const initialFormState = useMemo(() => ({
    firstName: row.original.candidate.firstName || '',
    lastName: row.original.candidate.lastName || '',
    email: row.original.candidate.email || '',
    location: row.original.candidate.location || '',
    refereeName: row.original.refereeName || '',
    jobSource: row.original.jobSourceName || '',
    instituteName: row.original.instituteName || '',
    takeHomeVersion: row.original.takeHomeVersion || '',
    finalRoundDate: row.original.finalRoundDate || '',
    level: row.original.level || '',
    interviewer: row.original.interviewer || '',
    status: row.original.status || '',
    stipendInfo: row.original.candidate.stipendInfo || '',
  }), [row.original]);

  const [formState, setFormState] = useState(initialFormState);

  // Define form fields dynamically
  const formFields = useMemo(() => [
    { label: 'Job Source', name: 'jobSource', type: 'select', options: !isLoading && !isFetching ? jobSources.map((j) => j.sourceName) : [] },
    { label: 'Referee Name', name: 'refereeName', type: 'input' },
    { label: 'Institute Name', name: 'instituteName', type: 'input' },
    { label: 'Take-Home Version', name: 'takeHomeVersion', type: 'select', options: ["V2", "V3"] },
    { label: 'Level', name: 'level', type: 'select', options: ['Passed', 'Close', 'Ran out of Time', 'Waste of Time'] },
    { label: 'Interviewer', name: 'interviewer', type: 'select', options: ["Amanda", "Jonathan", "Laura"] },
    { label: 'Stipend Info', name: 'stipendInfo', type: 'input' },
  ], [jobSources]);

  // Handle change of data for specific fields (jobSource and stipendInfo)
  const fieldHandlers = {
    stipendInfo: (row, newValue) => {
      return handleCandidateInfoChange(
        row.original.candidateId,
        { stipendInfo: newValue },
        setFormState,
        queryClient,
      );
    },
    jobSource: (row, newValue) => {
      const jobSourceId = jobSources.findIndex((j) => j.sourceName === newValue) + 1;
      return handleSubmissionChange(
        row.original.id,
        { jobSourceId },
        setFormState,
        queryClient,
      );
    },
  };

  // Default handler for fields without specific handlers
  const defaultHandler = (row, newValue, fieldName) => {
    return handleSubmissionChange(
      row.original.id,
      { [fieldName]: newValue },
      setFormState,
      queryClient,
    );
  };
  
  // Handle saving the new info
  const handleSave = (row, newValue, fieldName) => {
    const handler = fieldHandlers[fieldName] || defaultHandler;
    handler(row, newValue, fieldName);
  };

  return (
    <Box
      className="panelContainer"
      sx={{
        display: 'flex',
        justifyContent: 'left',
        width: '100%',
        position: 'sticky',
      }}
    >
      <Box
        className="panelInnerContainer"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 6,
          margin: 0,
          borderRadius: 1,
          width: '100%',
          overflowY: 'auto', // Added for scrollability
          '@media (max-width: 1320px) or (max-height: 870px)': {
            width: '90%',
          },
        }}
      >
          <section className="flex flex-wrap flex-col justify-center w-full p-4">
            {formFields.map((field) => (
              <div className="flex flex-col inputs mb-4" key={field.name}>
                <label className="font-semibold mb-1" htmlFor={field.name}>
                  {field.label}
                </label>
                <EditableCell
                  initialValue={formState[field.name]}
                  onSave={(newValue) => {
                    handleSave(row, newValue, field.name);
                  }}
                  placeholder={formState[field.name] ? formState[field.name] : "—"}
                  type={field.type}
                  options={field.options || []}
                  style={{
                    padding: '4px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    backgroundColor: '#f9f9f9',
                  }}
                />
              </div>
            ))}
          </section>
      </Box>
    </Box>
  );
};

export default FinalRoundDetailPanel;
