import { useEffect, useState } from 'react';
import _ from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { InvestigationTable } from './InvestigationInsightsTable';
import { classNames } from '../../../InvestigationBoard/helpers';
import { TeamInvestigationTaskListFilters } from '../../filters';

import useQueryKeyData from '../../../../hooks/useQueryKeyData';

export default function InvestigationTasksTable({ investigationTasks, team }) {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allClientsForTeam, setAllClientsForTeam] = useState([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(investigationTasks.length / 25));
  const [investigationTasksToDisplay, setInvestigationTasksToDisplay] = useState(investigationTasks.slice(0, 25));

  // Query user data from Tanstack network
  const { data: allQAWUsers } = useQueryKeyData(['getAllQAWUsers']);
  const allQAEs = allQAWUsers.filter((user) => user.isQAE || user.isManager || user.isLead);

  // handle pagination
  function handleNextPage() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  // filter investigationTasksToDisplay based on applied filters
  useEffect(() => {
    const allFilteredTeamIds = _.uniq(appliedFilters.filter((f) => f.param === 'teamName').map((f) => f.data.id));
    const allFilteredQAEEmails = _.uniq(
      appliedFilters.filter((f) => f.param === 'assigneeId').map((f) => allQAEs.find((qae) => qae.qawId === f.value).email),
    );

    const startIndex = (currentPage - 1) * 25;
    const endIndex = currentPage * 25;

    // filter tasks by team ids and qae emails when relevant
    let filteredTasks = appliedFilters.length
      ? investigationTasks.filter((t) => {
        if (allFilteredTeamIds.length && allFilteredQAEEmails.length) {
          return allFilteredTeamIds.includes(t.customerId) && allFilteredQAEEmails.includes(t.taskClaimedBy);
        } else if (allFilteredTeamIds.length) {
          return allFilteredTeamIds.includes(t.customerId);
        } else if (allFilteredQAEEmails.length) {
          return allFilteredQAEEmails.includes(t.taskClaimedBy);
        }
      })
      : investigationTasks;

    // sort filtered tasks by date
    filteredTasks.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());

    setTotalPages(Math.ceil(filteredTasks.length / 25));
    setInvestigationTasksToDisplay(filteredTasks.slice(startIndex, endIndex));
  }, [appliedFilters, currentPage, investigationTasks]);

  // get all clients for team from all tasks (already the tasks _done by the team_)
  useEffect(() => {
    const allClientsForTeam = _.uniqBy(
      /** @type {{id: string, name: string}[]} */(
        investigationTasks.map((t) => {
          return {
            id: t.customerId,
            name: t.customerName,
          };
        })
      ),
      'id',
    ).sort((a, b) => (a.name > b.name ? 1 : -1));
    setAllClientsForTeam(allClientsForTeam);
  }, [investigationTasks]);

  return (
    <div className="min-h-full px-4">
      <TeamInvestigationTaskListFilters setAppliedFilters={setAppliedFilters} clients={allClientsForTeam} team={team} />
      <InvestigationTable investigationTasks={investigationTasksToDisplay} />
      {investigationTasksToDisplay.length > 0 && (
        <div
          className={classNames(
            totalPages > 0 ? '' : 'sr-only',
            'pagination__controls flex justify-end items-center space-x-4 border-t mt-2 pt-2 pr-20',
          )}
        >
          <button
            className={classNames(currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : '', 'text-lg flex items-center space-x-2')}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className="h-5 w-5" />
            Previous
          </button>
          <span className="text-sm text-gray-500">
            page {currentPage} of {totalPages}
          </span>
          <button
            className={classNames(currentPage === totalPages ? 'text-gray-500 cursor-not-allowed' : '', 'text-lg flex items-center space-x-2')}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
}
