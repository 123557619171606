// React imports
import { useState, useEffect } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from 'dayjs';

const EditableCell = ({ initialValue, onSave, placeholder, style, type = "input", options = [] }) => {
  const [dateValue, setDateValue] = useState(dayjs());
  const [textValue, setTextValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsEditing(false);
    setIsOpen(false);
    if (dayjs(initialValue).isValid()) {
      setDateValue(dayjs(initialValue));
    }
    setTextValue(initialValue);
  }, [initialValue]);
  
  // Handle input change for text/select
  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  // Handle date change
  const handleDateChange = (newDateValue) => {
    setDateValue(newDateValue); // Set date value locally
  };

  // Handle blur/save logic
  const handlePickerClose = () => {
    setIsOpen(false);
    setIsEditing(false);
    onSave(dateValue);
  };

  const handleTextBlur = () => {
    setIsEditing(false);
    onSave(textValue);
  };


  const handleClick = () => {
    setIsEditing(true);
    if (type === "datepicker") {
      setIsOpen(true);
    }
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer", ...style }}>
      {isEditing ? (
        type === "select" ? (
          <select
            value={textValue}
            onChange={handleChange}
            onBlur={handleTextBlur}
            autoFocus
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
            }}
          >
            <option value="">{`Select option`}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        ) : type === "datepicker" ? (
          <DateTimePicker
            value={dateValue}
            onChange={handleDateChange}
            onAccept={handlePickerClose}
            onClose={handlePickerClose}
            onOpen={() => setIsOpen(true)}
            open={isOpen}
          />
        ) : (
          <input
            type="text"
            value={textValue}
            onChange={handleChange}
            onBlur={handleTextBlur}
            placeholder={placeholder}
            autoFocus
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          />
        )
      ) : (
        <span>{textValue || placeholder}</span>
      )}
    </div>
  );
};

export default EditableCell;
