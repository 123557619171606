import { InformationCircleIcon, XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { classNames } from '../../InvestigationBoard/helpers';
import { useTaskFilterCriteria } from '../../context/TaskFilterCriteriaContext';
import { generateWarnings } from './generateWarnings';
import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../utils/network';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
dayjs.extend(isSameOrAfter);

export default function WarningWidgets({ team, setTableState }) {
  // Get date for the the beginning of the week 8 weeks ago
  const beginningOfThisWeek = dayjs().startOf('week');
  const eightWeeksAgo = beginningOfThisWeek.subtract(8, 'weeks').startOf('week');

  const {
    data: warningsData,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['clientSummary', eightWeeksAgo, beginningOfThisWeek, team.id],
    queryFn: () => sendGetRequest(`/client-summaries/${team.id}?startDate=${eightWeeksAgo}&endDate=${beginningOfThisWeek}`),
    select: ({ data }) => {
      // Get the last 8 weeks of tasks
      const lastEightWeeksOfTasks = data.TaskSnapshot.filter((task) => dayjs(task.completedAt).startOf('week').isSameOrAfter(eightWeeksAgo));

      // Get the total number of tests created in the last 8 weeks
      const totalTestCount = lastEightWeeksOfTasks
        .filter((task) => task.type === 'testCreation' && task.details.workflow.stepCount)
        .reduce((prev, curr) => prev + curr.details.workflow.stepCount, 0);

      // Calculate the average number of tests created per week
      const eightWeekAvgTestCreation = (totalTestCount / 8).toFixed(2);

      const warnings = generateWarnings({ ...team, crsWithUpdatedDueDates: data.crsWithUpdatedDueDates, eightWeekAvgTestCreation });
      return warnings;
    },
  });

  if (isFetching && isLoading) {
    return <div className="m-2 flex flex-col"></div>;
  }

  if (isError) {
    return <div className="m-2 flex flex-col">{error.message}</div>;
  }

  return (
    <Box padding={1}>
      {warningsData.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Warnings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {_.orderBy(warningsData, 'warning.severity', 'asc').map((w) => (
                <WarningWidget
                  key={w.warning.warningKey}
                  title={w.warning.title}
                  detailsArr={w.warning.detailsArr}
                  severity={w.warning.severity}
                  setTableState={setTableState}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

function WarningWidget({ title, detailsArr, severity, setTableState }) {
  const { updateFilterCriteria } = useTaskFilterCriteria();

  const handleFilterForAllTasks = () => {
    // Get all task names from detailsArr that have a name property
    const taskNames = detailsArr.filter((d) => d.name).map((d) => formatTaskName(d.name));

    if (taskNames.length > 0) {
      // Allow for Coverage Requests and Maintenance Report grouping
      setTableState((tableState) => ({
        ...tableState,
        groupCRandMRs: true,
        columnFilterFns: {
          ...tableState.columnFilterFns,
          // Add arrIncludesSome filter function for name column
          name: 'arrIncludesSome',
        },
      }));

      // Create an array of filter criteria for all task names
      const filterCriteria = [
        {
          id: 'name',
          value: taskNames,
        },
      ];

      // Update filter criteria with all task names
      updateFilterCriteria(filterCriteria);
    }
  };

  // Helper function to format task names
  const formatTaskName = (name) => {
    // Remove first 2 characters of name
    return name.slice(2);
  };

  // Severity is low - gray warning
  let bgClass = 'bg-gray-100';
  let iconForWarning = <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
  let titleColorClass = 'text-gray-800';

  // Severity is high - red warning
  if (severity === 'high') {
    bgClass = 'bg-red-50';
    iconForWarning = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />;
    titleColorClass = 'text-red-800';
    // Severity is medium - yellow warning
  } else if (severity === 'medium') {
    bgClass = 'bg-yellow-50';
    iconForWarning = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />;
    titleColorClass = 'text-yellow-800';
  }

  console.log(detailsArr);

  return (
    <div className={classNames('rounded-md m-1 p-2', bgClass)}>
      <div className="flex">
        <div className="flex-shrink-0">{iconForWarning}</div>
        <div className="ml-3">
          <h3 className={classNames('text-sm font-medium', titleColorClass)}>{title}</h3>
          {detailsArr.length > 0 && (
            <Tooltip title={`${detailsArr.map((d) => d.name).join(', ')}`}>
              <span>
                <button className="text-xs text-gray-500" onClick={handleFilterForAllTasks}>
                  View Tasks
                </button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
