import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Header from '../Layout/Header';
import InsightsExplorerBoard from './InsightsExplorerBoard';
import { Tabs } from '../UtilityComponents';
import { TeamInsightsFilters } from './filters';

export default function InsightsExplorer() {
  // Get current and stored search param strings
  const currentSearchStr = window.location.search;
  const storedSearchStr = localStorage.getItem('teamInsightsSearchParams');

  // Create a new URLSearchParams instance, using current if available, otherwise stored
  const searchParamsInstance = new URLSearchParams(currentSearchStr || storedSearchStr);

  const [searchParams, setSearchParams] = useSearchParams(searchParamsInstance);
  const paramsMap = Object.fromEntries([...searchParams]);

  const defaultTabs = [
    { type: 'Team Velocity & Impact', current: true },
    { type: 'Team Investigation', current: false },
    { type: 'Team Creation & Outlining', current: false },
    { type: 'Team Maintenance', current: false },
    { type: 'Team Time Tracking', current: false },
  ];

  const defaultInvestigationOptions = {
    includeRuns: JSON.parse(sessionStorage.getItem('includeTeamRuns')) ?? false,
    includeEvents: JSON.parse(sessionStorage.getItem('includeTeamEvents')) ?? false,
    includeFlatOrderedData: JSON.parse(sessionStorage.getItem('includeTeamFlatOrderedData')) ?? false,
    includeRunsByLastAttemptStatus: JSON.parse(sessionStorage.getItem('includeTeamRunsByLastAttemptStatus')) ?? false,
  };

  const gte = paramsMap.gte ? dayjs(paramsMap.gte).format('YYYY-MM-DD') : dayjs().subtract(1, 'month').format('YYYY-MM-DD');
  const lte = paramsMap.lte ? dayjs(paramsMap.lte).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
  const types = paramsMap.types ? [...paramsMap.types] : ['testCreation'];

  /**
   * @type {import('./types').TeamInsightsFilter}
   */
  const defaultTeamFilter = {
    entity: {
      type: 'team',
      data: {
        name: paramsMap.teamName ?? 'Hammerheads',
      },
    },
    types,
    investigationOptions: defaultInvestigationOptions,
    gte,
    lte,
  };

  // start with Hammerheads selected by default
  const [appliedFilters, setAppliedFilters] = useState(
    /**@type {{team: import('./types').TeamInsightsFilter | null}} */({
      team: defaultTeamFilter,
    }),
  );
  const [tabs, setTabs] = useState(paramsMap.activeTab ? defaultTabs.map((t) => ({ ...t, current: t.type === paramsMap.activeTab })) : defaultTabs);

  // handle init and update to search params
  useEffect(() => {
    // set active tab
    searchParams.set('activeTab', tabs.find((t) => t.current).type);

    // handle team
    const teamName = appliedFilters.team?.entity?.data?.name;
    if (teamName) {
      searchParams.set('teamName', teamName);
    } else {
      searchParams.delete('teamName');
    }

    // handle date ranges
    searchParams.set('gte', appliedFilters.team.gte);
    searchParams.set('lte', appliedFilters.team.lte);
    setSearchParams(searchParams);
    localStorage.setItem('teamInsightsSearchParams', searchParams.toString());
  }, [searchParams, tabs, appliedFilters, gte, lte]);

  return (
    <div className="min-h-full px-4">
      {appliedFilters.team.entity && (
        <>
          <Header text={`${tabs.find((t) => t.current).type}`} />
          <TeamInsightsFilters filters={appliedFilters.team} setFilters={setAppliedFilters} />
          <Tabs tabs={tabs} setTabs={setTabs} activeTab={tabs.find((t) => t.current)} />
          <InsightsExplorerBoard activeTab={tabs.find((t) => t.current)} filters={appliedFilters.team} setFilters={setAppliedFilters} />
        </>
      )}
    </div>
  );
}
