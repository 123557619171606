import { colord, extend } from 'colord';
import labPlugin from 'colord/plugins/lab';

function prepareLumBlock(byA, l, lumIndex) {
  let loomBlock = byA.map((byB, aIndex) =>
    byB.map((values, bIndex) => {
      let color = colord(values).toHex();
      return (
        <div key={`${lumIndex}_${aIndex}_${bIndex}`} className="h-20 w-20" style={{ background: color }}>
          <p>{`${values.a}/${values.b}`}</p>
        </div>
      );
    }),
  );
  //<div>{lumIndex}</div>;

  return (
    <>
      <h3>Lum: {l}</h3>
      <div key={`lum_${lumIndex}`} className="grid grid-cols-10 gap-2 py-4">
        {loomBlock}
      </div>
    </>
  );
}

export default function ColorTest() {
  extend([labPlugin]);

  let byLum = []; //[colord({ l: 50, a: 10, b: 15 }).toHex()];
  for (let l = 20; l < 20; l += 10) {
    let byB = [];
    for (let b = -128; b < 127; b += 1) {
      let byA = [];
      for (let a = -128; a < 127; a += 1) {
        byA.push({ l, a, b });
      }
      byB.push(byA);
    }
    byLum.push({ byB, l });
  }

  return (
    <div className="min-h-full py-4 max-w-xl">
      <div>
        {byLum.map((value, lumIndex) => {
          return prepareLumBlock(value.byB, value.l, lumIndex);
        })}
      </div>
    </div>
  );
}
