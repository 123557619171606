import { Box, MenuItem, Button, Dialog, DialogContent, DialogTitle, ListItemIcon, Typography, CircularProgress, Tabs, Tab } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMemo, useState } from "react";
import { useQueryClient } from '@tanstack/react-query';

// Component and variable imports
import FinalRoundDetailPanel from './FinalRoundDetailPanel';
import { statusMap } from '../helpers/constants';
import EditableCell from '../helpers/EditableCell';
import { handleSubmissionChange, handleCandidateInfoChange } from '../helpers/hiringHelpers';
import { inviteExecsToFinalRoundTeam, initCandidateFinalRound } from '../api';
import RowDetailPanel from '../HiringDetailPanel';

// Date and time picker imports
import dayjs from 'dayjs';
import Toast from '../../Layout/Toast';


function FinalRoundTable({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedFinalRoundRowData, setSelectedFinalRoundRowData] = useState({});
  const [isLoadingSuperday, setIsLoadingSuperday] = useState(false);
  const [toast, setToast] = useState(<></>);

  const queryClient = useQueryClient();

  const [value, setValue] = useState(() =>
    data.reduce((acc, row) => {
      acc[row.id] = row || '';
      return acc;
    }, {}),
  );

  const handleInitFinalRound = async (superday) => {
    setIsLoadingSuperday(true);

    const candidateData = {
      superday,
      candidateEmail: selectedFinalRoundRowData.original.candidate.email,
      candidateName: selectedFinalRoundRowData.original.candidate.firstName,
      submissionId: selectedFinalRoundRowData.original.id,
    };


    let result = await initCandidateFinalRound(candidateData);
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ? 'Candidate superday created' : 'Something went wrong';

    setIsLoadingSuperday(false);
    setOpen(false);
    setToast(<Toast title={title} message={message} key={new Date().toISOString()} isSuccess={success} />);
  };

  const handleInviteExecToFinalRoundTeam = async (row) => {
    const { original: { finalRoundTeamId } } = row;

    let result = await inviteExecsToFinalRoundTeam({ teamId: finalRoundTeamId });
    let { success } = result;

    if (!success) {
      console.log(result);
    }

    let title = success ? 'Success!' : 'Ruh-roh';
    let message = success ?
      `Invitations to ${row.original.candidate.firstName}'s Final Round Team have been sent.`
      : 'Something went wrong!';

    setToast(<Toast title={title} message={message} isSuccess={success} />);
  };


  const columns = useMemo(() => {
    return [
      // ========================= CANDIDATE NAME =========================
      {
        id: 'name',
        header: 'Candidate Name',
        accessorKey: 'candidateName',
        accessorFn: (row) => {
          return ({
            candidate: row.candidate,
            firstName: row.candidate.firstName,
            lastName: row.candidate.lastName,
            fullName: `${row.candidate.firstName} ${row.candidate.lastName}`,
          });
        },
        Cell: ({ cell }) => {
          const { candidate, firstName, lastName } = cell.getValue();
          const initialValue = `${firstName} ${lastName}`;

          // Handle saving the new name
          const handleSave = (newValue) => {
            const [newFirstName, newLastName] = newValue.split(/\s(.+)/);
            handleCandidateInfoChange(
              candidate.id,
              {
                firstName: newFirstName || '',
                lastName: newLastName || '',
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Candidate Name"
            />
          );
        },
      },
      // ========================= CANDIDATE EMAIL =========================
      {
        accessorFn: (row) => row?.candidate.email,
        id: 'email',
        header: 'Candidate Email',
        enableClickToCopy: true,
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.email || '';

          // Handle saving the new email
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                email: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Email"
            />
          );
        },
      },
      // ========================= INTERVIEW STATUS =========================
      {
        accessorFn: (row) => ({
          status: row.status,
          submissionId: row,
        }), //accessorFn used to join multiple data into a single cell
        id: 'statusId', //id is still required when using accessorFn instead of accessorKey
        header: 'Status',

        Cell: ({ cell, row }) => {
          const { submissionId, status } = cell.getValue();

          const matchedStatus = statusMap.find((s) => s.value === status);

          return (
            <>
              <select
                id="status"
                name="status"
                style={{
                  backgroundColor: matchedStatus?.color || 'blue',
                  color: matchedStatus?.textColor || 'white',
                  textAlign: 'center',
                  borderRadius: '20px',
                  padding: '5px 10px',
                  width: '100%',
                  overflow: 'clip',
                  fontSize: '14px',
                }}
                value={status}
                onChange={(e) => handleSubmissionChange(
                  submissionId.id,
                  { status: e.target.value },
                  setValue,
                  queryClient,
                  row,
                )}
              >
                {statusMap.map((status) => (
                  <option value={status.value} key={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
      // ========================= FINAL ROUND DATE =========================
      {
        accessorFn: (row) => ({
          submission: row,
          finalRoundDate: row.finalRoundDate,
        }),
        id: 'finalRoundDate',
        header: 'Final Round Date',
        Cell: ({ cell, row }) => {
          const { submission, finalRoundDate } = cell.getValue();
          const initialValue = finalRoundDate ? dayjs(finalRoundDate).format('MMM DD, YYYY h:mm A') : "Select a date";

          // Handler to save the updated date
          const handleSave = (newValue) => {
            handleSubmissionChange(
              submission.id,
              { finalRoundDate: newValue },
              setValue,
              queryClient,
              row,
            );
          };
      
          return (
            <EditableCell
              initialValue={initialValue} // Initialize with the current date
              onSave={handleSave} // Save the new date when editing is finalized
              placeholder="Select a date"
              type="datepicker" // Enable the date picker
            />
          );
        },
        sortingFn: (a, b) => {
          const dateA = a.original.finalRoundDate ? new Date(a.original.finalRoundDate) : new Date(0);
          const dateB = b.original.finalRoundDate ? new Date(b.original.finalRoundDate) : new Date(0);
          return dateA - dateB;
        },
      },
      // ========================= CANDIDATE LOCATION =========================
      {
        accessorFn: (row) => row.candidate.location ? row.candidate.location : "N/A",
        id: 'location',
        header: 'Location',
        Cell: ({ cell }) => {
          const candidate = cell.row.original.candidate;
          const initialValue = candidate.location || '';

          // Handle saving the new location
          const handleSave = (newValue) => {
            handleCandidateInfoChange(
              candidate.id,
              {
                location: newValue,
              },
              setValue,
              queryClient,
            );
          };

          return (
            <EditableCell
              initialValue={initialValue}
              onSave={handleSave}
              placeholder="Location"
            />
          );
        },
      },
      // ========================= FINAL ROUND SANDBOX =========================
      {
        header: 'Final Round Team',
        accessorKey: 'finalRoundTeam',
        Cell: ({  cell, row }) => {

          const cellData = cell.getValue();

          return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {cellData &&
              <a
                href={`https://app.qawolf.com/${cellData}`}
                target='_blank'
                rel='noreferrer'
                style={{
                  color: '#6366f1',
                }}
              >
                {row.original.candidate.firstName}'s Superday
              </a>
            }
          </Box>
          );
        },
      },
    ];
  }, [data, value]);


  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
      columnPinning: {
        left: ['mrt-row-select', 'mrt-row-expand'],
        right: ['mrt-row-actions'],
      },
      sorting: [{ id: 'finalRoundDate', desc: false }],
    },
   
    enableColumnActions: true,
    enableColumnFilters: true,
    enableTopToolbar: true,
    enableTableFooter: true,
    enableRowActions: true,
    autoResetPageIndex: false,
    enableExpanding: true,

    renderBottomToolbarCustomActions: ({ table }) => {
      // Calculate total rows
      const rowCount = table.getRowModel().rows.length;
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
          {toast}
          {selectedFinalRoundRowData ?

            <Dialog open={open} onClose={() => { setOpen(false); }}>
              <DialogTitle>Which Superday for {selectedFinalRoundRowData?.original?.candidate.firstName}</DialogTitle>
              <DialogContent>
                <div className='flex w-full justify-center align-center'>
                  <Button onClick={() => handleInitFinalRound("superday1")}>Superday 1</Button>
                  <Button onClick={() => handleInitFinalRound("superday2")}>Superday 2</Button>
                  <Button onClick={() => handleInitFinalRound("superday3")}>Superday 3</Button>
                </div>


                {isLoadingSuperday &&
                  <div className='flex w-full justify-center align-center'>
                    <CircularProgress />
                  </div>
                }
              </DialogContent>
            </Dialog> : null
          }
          <Typography variant="subtitle1">Total Rows: {rowCount}</Typography>
        </Box>
      );
    },

    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
    }),

    renderDetailPanel: ({ row }) => {
      const [tabValue, setTabValue] = useState(0);

      const handleTabChange = (event, selectedTab) => {
        setTabValue(selectedTab);
      };
  
      return (
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="detail panel tabs"
            variant="fullWidth"
          >
            <Tab label="Final Round Info" />
            <Tab label="Take-home Submission" />
          </Tabs>
          {tabValue === 0 && (
            <Box value={tabValue} index={0}>
              <FinalRoundDetailPanel row={row} data={data} queryClient={queryClient} />
            </Box>
          )}
          {tabValue === 1 && (
            <Box value={tabValue} index={1}>
              <RowDetailPanel row={row} data={data} />
            </Box>
          )}
        </Box>
      );
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => [
      <MenuItem
        key={0}
        sx={{ m: 0 }}
        onClick={() => {
          handleInviteExecToFinalRoundTeam(row);
          closeMenu();
        }}>
        Invite Eric/Laura to Final Round Team
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setOpen(true);
          setSelectedFinalRoundRowData(row);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        Init Final Round
      </MenuItem>,
    ],
  });


  return (
    <div>
      <MaterialReactTable table={table} />
      {toast}
    </div>
  );
}

export default FinalRoundTable;


