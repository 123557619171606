import { sendGetRequest, sendPostRequest } from '../../utils/network';

/**
 * Retrieve team details asynchronously.
 * @param {string} [clientSummaryTeamId] - The ID of the team to retrieve tasks for (optional).
 * @returns {Promise<import('../../types').QAWTask[]>} Promise that resolves to an array of tasks or an error message.
 */
export async function getAllTasks(clientSummaryTeamId = '') {
  try {
    const selectedTeams = !clientSummaryTeamId
      ? // Handle getting tasks for the main tasks table
        JSON.parse(localStorage.getItem('teams')).filter((team) => team.active)
      : // Handle getting tasks for the client summary tasks table
        [{ customerQawId: clientSummaryTeamId }];
    let response = await sendPostRequest(`/all-tasks`, { selectedTeams });

    if (!response.success) {
      throw new Error('Failed to fetch new tasks info. Please refresh the page.');
    }

    return response?.data || [];
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getFilterValues() {
  try {
    let response = await sendGetRequest(`/filter-values`);

    if (!response.success) {
      throw new Error('Failed to fetch filtering data. Please refresh the page.');
    }

    return response?.data || {};
  } catch (error) {
    throw new Error(error.message);
  }
}

/**
 * Updates a task.
 * @param {object} data - The data to update the task with.
 * @returns {Promise<import('../../types').QAWTask[]>} - A promise that resolves to the updated task or an error message.
 */
export async function updateTask(data) {
  let resultData = await sendPostRequest(`/update-task`, data);
  let tasks = resultData?.data;

  if (tasks) {
    return tasks;
  }

  // @ts-ignore
  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}

export async function multiUpdateTasks(data) {
  let resultData = await sendPostRequest(`/update-tasks`, data);

  let tasks = resultData?.data;

  if (tasks) {
    return tasks;
  }

  return { error: 'Failed to fetch new tasks info. Please refresh the page.' };
}
