import { useMemo } from 'react';
import { Box } from '@mui/material';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';

const WorkflowFlakeChart = ({ filteredSuiteData }) => {

    const processFlakeData = (suites) => {
        const workflowData = {};
        const statusToDescription = {
            passedFirst: "Passed on First Attempt",
            passedSecond: "Passed on Second Attempt",
            passedThird: "Passed on Third Attempt",
            qaeFlake: "Passed on QAE Retry",
            qaeTriage: "Passed on QAE Change",
            autoTriaged: "Previously Bugged",
            newlyBugged: "Newly Bugged",
            maintenance: "Maintenance",
            doNotInvestigate: "Do Not Investigate",
            canceled: "Canceled",
            failed: "Failed",
            supersededRuns: "Superseded",
        };

        const workflowFlakiness = {
            "Passed on First Attempt": 0,
            "Passed on Second Attempt": 1,
            "Passed on Third Attempt": 1,
            "Passed on QAE Retry": 1,
            "Passed on QAE Change": 0,
            "Previously Bugged": 0,
            "Newly Bugged": 0,
            "Maintenance": 0,
            "Do Not Investigate": 0,
            "Superseded": 0,
            "Canceled": 0,
            "Failed": 0,
        };

        suites.forEach((suite) => {
            const weekStart = dayjs(suite.startTime).startOf('week');
            Object.keys(suite.runsByLastAttemptStatus).forEach((status) => {
                suite.runsByLastAttemptStatus[status].forEach((run) => {
                    const workflowName = run.name.split(': ').slice(1).join(': ').trim();
                    if (!workflowData[workflowName]) {
                        workflowData[workflowName] = {};
                    }
                    if (!workflowData[workflowName][weekStart]) {
                        workflowData[workflowName][weekStart] = { totalFlakiness: 0, count: 0 };
                    }
                    workflowData[workflowName][weekStart].totalFlakiness += workflowFlakiness[statusToDescription[status]] || 0;
                    workflowData[workflowName][weekStart].count += 1;
                });
            });
        });

        // Calculate flake scores for each week with two decimal places
        Object.keys(workflowData).forEach((workflow) => {
            workflowData[workflow] = Object.entries(workflowData[workflow]).map(([week, data]) => ({
                week: dayjs(week).format('YYYY-MM-DD'),
                flakeScore: Number((data.totalFlakiness / data.count).toFixed(2)),
            })).sort((a, b) => dayjs(a.week).diff(dayjs(b.week)));
        });

        return workflowData;
    };

    const workflowFlakeData = useMemo(() => processFlakeData(filteredSuiteData), [filteredSuiteData]);

    const getPlotData = (flakeScores) => {
        return [{
            x: flakeScores.map((score) => score.week),
            y: flakeScores.map((score) => score.flakeScore),
            type: 'scatter',
            mode: 'lines+markers',
            line: { shape: 'spline', smoothing: 1.3 },
            connectgaps: false,
        }];
    };

    const getPlotLayout = (workflow) => {
        return {
            title: `${workflow}`,
            xaxis: { title: 'Week' },
            yaxis: { title: 'Flake Score', range: [0, 1] },
            height: 160, // Reduced to 40% of original 400px height
            autosize: true,
            margin: { l: 50, r: 20, t: 40, b: 40 }, // Adjusted margins for smaller plot
        };
    };

    // Function to get the first alphanumeric character
    const getFirstAlphanumeric = (str) => {
        const match = str.match(/[a-zA-Z0-9]/);
        return match ? match[0].toLowerCase() : '';
    };

    // Sort workflows alphabetically based on the first alphanumeric character
    const sortedWorkflows = Object.entries(workflowFlakeData).sort((a, b) => {
        const firstCharA = getFirstAlphanumeric(a[0]);
        const firstCharB = getFirstAlphanumeric(b[0]);
        return firstCharA.localeCompare(firstCharB);
    });

    return (
        <Box>
            {sortedWorkflows.map(([workflow, flakeScores]) => (
                <Box key={workflow} mb={4} width="100%">
                    <Plot
                        data={getPlotData(flakeScores)}
                        layout={getPlotLayout(workflow)}
                        config={{ responsive: true }}
                        style={{ width: '100%', height: '100%' }}
                        useResizeHandler={true}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default WorkflowFlakeChart;
