import CardFormBase from './CardFormBase';

/**
 *
 * @param {object} props
 * @param {import('../../InsightsExplorer/types').QAEFromLocalStorage[]} props.packs
 * @returns {import('react').JSX.Element}
 */
export default function PackCardForm({ packs }) {
  return (
    <CardFormBase
      items={packs}
      label="packs"
      inputLabel="Packs"
      secondarySwitchLabel="Include Individual Team Cards"
      itemName="packs"
      secondarySubSelectName="Teams"
    />
  );
}
