import FinalRoundTable from './FinalRoundTable/FinalRoundTable';
import HiringTable from './HiringTable';
import ArchiveTable from './ArchivedTable/ArchiveTable';

function HiringOverview({ activeTab, data }) {
  const viewMap = {
    'Candidate View': <HiringTable data={data} />,
    'Final Round View': <FinalRoundTable data={data} />,
    'Archived': <ArchiveTable data={data} />,
  };
  return <div>{viewMap[activeTab.type]}</div>;
}

export default HiringOverview;
