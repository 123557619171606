import { MaterialReactTable, useMaterialReactTable, MRT_ColumnDef, MRT_Row, MRT_TableState } from 'material-react-table';
import { useMemo } from 'react';
import MainDrawer from '../ClientMilestones/MainDrawer';
import { ClientSummaryTableRow } from '../types';
import ProjectPlanStatusCell from './ProjectPlanStatusCell';
import PlanningDrawer from '../PlanningDrawer';
import { usePlanningContext } from '../../../context/PlanningContext';

const getMilestoneCellProps = ({ row }: { row: MRT_Row<ClientSummaryTableRow> }) => ({
  sx: {
    backgroundColor: row.original.activeMilestone
      ? row.original.isOnTrack
        ? '#e8f5e9' // light green
        : '#ffebee' // light red
      : '#fff3e0', // light yellow
  },
});

export default function ClientSummaryTable() {
  const { clientSummaries } = usePlanningContext();

  const initialState: Partial<MRT_TableState<ClientSummaryTableRow>> = {
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    density: 'compact',
  };

  const columns = useMemo<MRT_ColumnDef<ClientSummaryTableRow>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Customer Name',
      },
      {
        accessorKey: 'testsScheduledForCurrentWeek',
        header: 'Tests Scheduled',
        enableColumnFilter: false,
        sortUndefined: 1,
        muiTableBodyCellProps: ({ row }) => {
          const hasMilestone = row.original.activeMilestone;
          const hasEnoughTestsScheduled = row.original.testsScheduledForCurrentWeek >= row.original.requiredVelocity;
          return {
            sx: {
              backgroundColor: !hasMilestone ? '#fff3e0' : hasEnoughTestsScheduled ? '#e8f5e9' : '#ffebee',
            },
          };
        },
      },
      {
        accessorKey: 'requiredVelocity',
        header: 'Req. Velocity',
        enableColumnFilter: false,
        sortUndefined: 1,
        muiTableBodyCellProps: getMilestoneCellProps,
        accessorFn: (row) => Math.max(row.requiredVelocity, 0),
      },
      {
        accessorKey: 'weeksRemaining',
        header: 'Weeks Remaining',
        enableColumnFilter: false,
        sortUndefined: 1,
        Cell: ({ row }) => (row.original.activeMilestone ? row.original.weeksRemaining : 'N/A'),
        muiTableBodyCellProps: getMilestoneCellProps,
      },
      {
        accessorKey: 'isOnTrack',
        header: 'Milestone Status',
        filterVariant: 'multi-select',
        muiFilterCheckboxProps: { title: 'On Track?' },
        accessorFn: (row) => (row.isOnTrack && row.activeMilestone ? 'On Track' : row.activeMilestone ? 'Behind' : 'N/A'),
        muiTableBodyCellProps: getMilestoneCellProps,
      },
      {
        accessorKey: 'activeMilestone',
        header: 'Active Milestone',
        accessorFn: (row) => !!row.activeMilestone,
        filterVariant: 'checkbox',
        muiFilterCheckboxProps: {
          title: 'Has Active Milestone?',
        },
        sortUndefined: 1,
        Cell: ({ row }) => <MainDrawer client={row.original} />,
        minSize: 200,
        muiTableBodyCellProps: getMilestoneCellProps,
      },
      {
        header: 'Planning',
        Cell: ({ row }) => <PlanningDrawer client={row.original} />,
      },
      {
        accessorKey: 'isProjectPlanComplete',
        header: 'Project Plan Status',
        filterVariant: 'checkbox',
        muiFilterCheckboxProps: {
          title: 'Done?',
        },
        Cell: ProjectPlanStatusCell,
        minSize: 140,
      },
    ];
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: clientSummaries.data || [],
    initialState,
    columnFilterDisplayMode: 'popover',
    enableColumnResizing: true,
    enableColumnActions: true,
    layoutMode: 'grid',
    enableFacetedValues: true,
    state: { isLoading: clientSummaries.isLoading || clientSummaries.isFetching },
    muiSkeletonProps: { animation: 'pulse' },
  });

  return <MaterialReactTable table={table} />;
}
