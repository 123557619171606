import { ResponsiveContainer } from 'recharts';

import { TimeTrackingBarChart } from './TimeTrackingBarChart';

import { groupAndSortEvents, normalizeEventsForChart, normalizeTrackedEventsForChart, parseEvents, parseTrackedEvents } from './timeTrackingHelpers';

export function OrgTimeTrackingCard({ teamsInsights }) {
  // consolidate data from all teams
  const allEvents = {};
  const allTrackedEvents = {};
  for (const team of teamsInsights) {
    for (const [id, qaeEvents] of Object.entries(team.events)) {
      const { batchTotals } = parseEvents(groupAndSortEvents(qaeEvents), id);
      const { totalBreakdown } = parseTrackedEvents(groupAndSortEvents(qaeEvents));

      allEvents[id] = batchTotals;
      allTrackedEvents[id] = totalBreakdown;
    }
  }

  // generate normalized data for chart
  const chartNormalizedEventData = normalizeEventsForChart(allEvents);

  // generate normalized tracked data for chart
  const normalizedTrackedEventData = normalizeTrackedEventsForChart(allTrackedEvents);

  // TODO: break data by week and render a <DataRow /> for each week
  //   const weeklyData = {};

  return (
    (
      <div className="min-h-full px-1/2">
        <div className="flex rounded-md bg-white border justify-around items-center p-2 my-2">
          <div className="flex-1 flex flex-col items-center pr-4 space-y-3 ml-4">
            <div className="rounded-full border-4 flex items-center justify-center h-20 w-20">
              <img
                className="bg-gray-100 rounded-full h-full w-full p-1.5"
                src="https://assets-global.website-files.com/6260298eca091b57c9cf188e/6260298eca091b8710cf18ea_logo.svg"
                alt="Task Wolf"
              />
            </div>
            <h2 className="text-3xl font-medium">QAW</h2>
          </div>
          {chartNormalizedEventData.length > 0 && (
            <div className="flex-1 flex-col items-center justify-center">
              <ResponsiveContainer className="flex items-center justify-end" width="100%" height="100%">
                <TimeTrackingBarChart normalizedData={[...chartNormalizedEventData, ...normalizedTrackedEventData]} />
              </ResponsiveContainer>
            </div>
          )}
        </div>
        <div>
          {/*
          // TODO: Implement weekly data breakdown
          {team &&
            Object.keys(team.events).map((id) => {
              const qae = allQaes.find((qae) => qae.qawId === id);
              return qae ? (
                <div key={id} className="bg-white rounded-md flex mb-2">
                  <QaeTimeTrackingCard events={team.events[id]} qae={allQaes.find((user) => user.qawId === id)} />
                </div>
              ) : (
                <div
                  key={id}
                  className="flex border mb-2"
                >{`No QAE in localStorage with id: ${id}. Please generate a new session by logging out and back in.`}</div>
              );
            })} */}
        </div>
      </div>
    ) || <div>This is where the org insights card WILL go</div>
  );
}
