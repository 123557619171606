import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { OrgCard, PackCard, IndividualQAECard, AggregateQAECard } from '../cards';
import CustomTooltip from '../../InsightsExplorer/charts/CustomTooltip';

import WolfLoader from '../../WolfLoader/WolfLoader';

import { buildPlaygroundChartData, useOrgPlaygroundInsights } from '../helpers';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';

// card component enum
const cards = {
  Packs: () => PackCard,
  Teams: () => AggregateQAECard,
  QAEs: (card) => (card.qaes.length > 1 ? AggregateQAECard : IndividualQAECard),
  Tiers: () => AggregateQAECard,
};

/**
 *
 * @param {object} props
 * @param {import('../../InsightsExplorer/types').User[]} props.allUsers
 * @param {import('../../InsightsExplorer/types').QATeamFromLocalStorage[]} props.allTeams
 * @param {{gte: string, lte: string}} props.dates
 * @param {import('../types').ActiveCard[]} props.activeCards
 * @param {{[key: string]: boolean}} props.lineProps
 * @param {import('react').Dispatch<import('react').SetStateAction<{[key: string]: boolean}>>} props.setLineProps
 * @returns {import('react').JSX.Element}
 */
export default function ImpactPlaygroundChart({ allUsers, allTeams, dates, activeCards, lineProps, setLineProps }) {
  const allPacks = useMemo(
    () =>
      _.sortBy(
        allUsers.filter((user) => user.isManager),
        ['name'],
      ),
    [],
  );

  // handle state and refs for re-sizing chart
  const parentRef = useRef(null);
  const [width, setWidth] = useState(0);

  // handle state for legend toggles (hover opacity)
  const [hoverProps, setHoverProps] = useState(/** @type {{hover: string | null}} */({ hover: null }));

  // handle data fetching for entire org
  const queries = useOrgPlaygroundInsights(allPacks, dates);

  const { loadedPacks, isLoading, isError, errors } = useMemo(() => {
    // filter out queries that haven't succeeded
    const successfulQueries = queries.filter((query) => query.isSuccess);

    // map the successful queries as just their data, sort them by pack manager name
    const sortedLoadedPacks = successfulQueries.map((query) => query.data).sort((a, b) => a.pack.name.localeCompare(b.pack.name));

    return {
      loadedPacks: sortedLoadedPacks,
      isLoading: queries.some((query) => query.isLoading),
      isError: queries.some((query) => query.isError),
      errors: queries.map((query) => query.error),
    };
  }, [...queries.map((query) => query.isSuccess)]);

  // handles chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // cleanup observer
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, [parentRef.current]);

  const chartData = useMemo(() => buildPlaygroundChartData(loadedPacks, allUsers, allTeams, activeCards), [loadedPacks, activeCards]);
  const dataKeys = Array.from(new Set(chartData.flatMap((data) => Object.keys(data).filter((key) => key !== 'week'))));

  if (isLoading && loadedPacks.length < 1) return <WolfLoader />;
  if (isError) return <ErrorDisplay errors={errors} />;

  return (
    <div className="flex flex-col items-center justify-center border" ref={parentRef}>
      <div className="sticky top-0 z-50 bg-white p-2">
        <LineChart height={550} width={width - 10} data={chartData} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" tickMargin={5} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {dataKeys.map((key) => {
            const bulkKeyName = `ALL:${key.split(':').at(-1)}`;

            return (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                hide={key.includes('ORG_CARD') ? !lineProps[key] : !lineProps[key] && !lineProps[bulkKeyName]}
                stroke={hslStringToRgbString(stringToColor(key))}
                strokeWidth={hoverProps.hover === key ? 3 : 1.5}
                opacity={hoverProps.hover === key || !hoverProps.hover ? 1 : 0.3}
                dot={false}
                activeDot={false}
              />
            );
          })}
        </LineChart>
      </div>
      <div className="flex gap-4 flex-wrap items-stretch  w-full p-2">
        <OrgCard visibilityProps={lineProps} visibilitySetter={setLineProps} hoverProps={hoverProps} hoverSetter={setHoverProps} />
        {activeCards.map((card) => {
          const CurrentCard = cards[card.type](card);
          return (
            <CurrentCard
              key={card.cardName}
              card={card}
              visibilityProps={lineProps}
              visibilitySetter={setLineProps}
              hoverProps={hoverProps}
              hoverSetter={setHoverProps}
            />
          );
        })}
      </div>
    </div>
  );
}

function ErrorDisplay({ errors }) {
  return (
    <div>
      <h2>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      </h2>
    </div>
  );
}
