import { useQueryClient } from '@tanstack/react-query';

/**
 * Queries data depending on given queryKey and returns data using getQueryData on our Query Client. Query must be called in top layer of App to be able to use getQueryState. (Queried in MainLayout.jsx)
 * @param {*[]} queryKey
 * @returns {object} Data from get request queried from Tanstack
 */
export default function useQueryKeyData(queryKey) {
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState(queryKey);
  let data;
  if (queryState) {
    try {
      data = queryClient.getQueryData(queryKey);
      return { data };
    } catch (error) {
      console.error('Error fetching data from Query Client using getQueryState', error);
      return { data };
    }
  } else {
    // Refetch the data using refetchQueries with the given queryKey
    queryClient.refetchQueries({ queryKey: [queryKey] });

    // Re-attempt getQueryData
    try {
      data = queryClient.getQueryData(queryKey);
      return { data };
    } catch (error) {
      console.error('Error getting data from Query Client using getQueryState', error);
      return { data };
    }
  }
}
