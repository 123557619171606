import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../utils/network';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useState, Fragment } from 'react';
import { Menu, MenuItem } from '@mui/material';
import Toast from '../Layout/Toast';
import dayjs from 'dayjs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SavedViews({ selectedView, setSelectedView, setTableState, deleteView, setToast, defaultTableState }) {
  const userQawId = localStorage.getItem('userId');
  const [contextMenu, setContextMenu] = useState(null);
  const [clickedViewId, setClickedViewId] = useState(null);

  const {
    data: savedTableViews,
    isPending,
    isPlaceholderData,
    isError,
    error,
  } = useQuery({
    queryKey: ['savedTableViews'],
    queryFn: async () => {
      const views = (await sendGetRequest(`/table-views/${userQawId}?tableName=taskTable`)).data;
      // console.dir(views, { depth: null, colors: true });
      return views;
    },
    placeholderData: [],
  });

  if (isPending || isPlaceholderData) return <div>Loading...</div>;
  if (isError) return <span>Error: {error.message}</span>;

  if (!savedTableViews.some((view) => view.id === selectedView)) {
    setSelectedView(0);
  }

  const handleChange = (newValue) => {
    setSelectedView(newValue);
  };
  
  const handleTabClick = (view, defaultTableState) => {
    // Set selected view for UI to update
    handleChange(view.id);

    // Update dueDate columnFilter so that dates are either undefined or use dayjs
    let dueDateIndex = view.state.columnFilters?.findIndex((filter) => filter.id === 'dueDate');
    if (dueDateIndex >= 0) {
      // Update date to a dayjs object, or to undefined if it's falsy
      view.state.columnFilters[dueDateIndex].value = view.state.columnFilters[dueDateIndex].value.map((date) =>
        !date ? (date = undefined) : (date = dayjs(date)),
      );
    }

    // Set table state based on the selected view
    setTableState(() => ({
      ...defaultTableState,
      ...view.state,
    }));
  };

  const handleContextMenu = (event, viewId) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
    setClickedViewId(viewId);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleClick = () => {
    if (clickedViewId === 0) {
      setToast(<Toast title={'Error'} message={`The Home view can't be deleted. 🙃`} key={new Date().toISOString()} isSuccess={false} />);
    } else {
      deleteView.mutate(clickedViewId);
    }
    setClickedViewId(null);
    setContextMenu(null);
  };

  return (
    <div className="w-4/5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          onChange={(e) => handleChange(e.target.value)}
          value={selectedView}
        >
          {savedTableViews?.map((view) => (
            <option key={view.id}>{view.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex flex-wrap space-x-2" aria-label="Tabs">
          {savedTableViews?.map((view) => (
            <Fragment key={view.id}>
              <a
                onContextMenu={(event) => handleContextMenu(event, view.id)}
                style={{ cursor: 'context-menu' }}
                className={classNames(
                  view.id === selectedView ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer',
                )}
                onClick={() => handleTabClick(view, defaultTableState)}
                aria-current={view.id === selectedView ? 'page' : undefined}
              >
                {view.name === 'Home' ? <HomeIcon className="h-5 w-5" /> : view.name}
              </a>
              <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
              >
                <MenuItem onClick={handleClick}>Delete</MenuItem>
              </Menu>
            </Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
}
