import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { QAWTask } from '../../types';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendPutRequest } from '../../utils/tanstackNetwork';
import { Edit, Preview, CloseOutlined } from '@mui/icons-material';
import { Snackbar } from '@mui/material';

export default function BlockerEditorModal({ task, open, setOpen }: { task: QAWTask; open: boolean; setOpen: (open: boolean) => void }) {
  const queryClient = useQueryClient();

  const [isPreview, setIsPreview] = useState(true);
  const [blockerReason, setBlockerReason] = useState(task.blocker?.description || '');
  const [reasonError, setReasonError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Reset the blocker reason when the cached task changes
  useEffect(() => {
    setBlockerReason(task.blocker?.description || '');
  }, [task]);

  const blockerMutation = useMutation({
    mutationKey: ['updateBlocker'],
    mutationFn: async (taskData: { task: QAWTask }) => sendPutRequest(`/update-blocker`, taskData),
    onSuccess: () => {
      setSnackbarMessage('Blocker updated successfully');
      setShowSnackbar(true);
      // Update the task cache
      queryClient.setQueryData(['allTasks'], (oldData: QAWTask[]) => {
        return oldData.map((cachedTask: QAWTask) => {
          // Skip tasks that don't have matching blockers
          if (!cachedTask.blocker?.id && !cachedTask.childTasks?.some((t) => t.blocker?.id === task.blocker?.id)) {
            return cachedTask;
          }

          const updatedBlocker = { ...task.blocker, description: blockerReason };

          return {
            ...cachedTask,
            blocker: cachedTask.blocker?.id === task.blocker?.id ? updatedBlocker : cachedTask.blocker,
            childTasks: cachedTask.childTasks?.map((childTask) =>
              childTask.blocker?.id === task.blocker?.id ? { ...childTask, blocker: updatedBlocker } : childTask,
            ),
          };
        });
      });
    },
    onError: () => {
      setSnackbarMessage('Failed to update blocker');
      setShowSnackbar(true);
    },
  });

  const handleUpdateBlocker = () => {
    if (!blockerReason || !task.blocker) return setReasonError(true);
    blockerMutation.mutate({
      task: {
        ...task,
        blocker: {
          ...task.blocker,
          description: blockerReason,
        },
      },
    });
  };

  const handleClose = () => {
    setIsPreview(true);
    setBlockerReason(task.blocker?.description || '');
    setReasonError(false);
    setShowSnackbar(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper
          sx={{
            p: 4,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ mt: -2 }}>
              {task.blocker?.title}
            </Typography>
            <Tooltip title={isPreview ? 'Edit' : 'Preview'}>
              <span>
                <IconButton onClick={() => setIsPreview(!isPreview)} disableRipple>
                  {isPreview ? <Edit /> : <Preview />}
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <MDEditor
            value={blockerReason}
            onChange={(val) => {
              setBlockerReason(val || '');
            }}
            preview={isPreview ? 'preview' : 'edit'}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
            height={400}
            data-color-mode="light"
            textareaProps={{
              placeholder: 'Enter detailed reason for blocked status',
            }}
            style={{
              marginTop: 10,
              marginBottom: 10,
              boxSizing: 'border-box',
              outline: reasonError ? '1px solid red' : 'none',
            }}
            hideToolbar={isPreview}
          />
          <Button variant="contained" onClick={handleUpdateBlocker} disableRipple disabled={!blockerReason || blockerMutation.isPending}>
            {blockerMutation.isPending ? 'Updating...' : 'Update Blocker'}
          </Button>
          <Snackbar
            open={showSnackbar}
            message={snackbarMessage}
            onClose={() => setShowSnackbar(false)}
            ContentProps={{ sx: { bgcolor: 'white', color: 'black' } }}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)} disableRipple>
                <CloseOutlined fontSize="small" />
              </IconButton>
            }
          />
        </Paper>
      </Modal>
    </div>
  );
}
