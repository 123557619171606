import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Legend, ComposedChart, Tooltip, CartesianGrid, XAxis, YAxis, Area, Line } from 'recharts';
// import { Switch } from '@headlessui/react';
import CustomTooltip from './CustomTooltip';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { classNames } from '../../InvestigationBoard/helpers';
import { computePercentile } from '../helpers';

import useQueryKeyData from '../../../hooks/useQueryKeyData';

/**
 * Computes a QAEs impact score based on velocity and + / -
 * @param {{creation: number, investigation: number}} velocity
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @returns {number} impactScore
 */
const computeImpactScore = (velocity, creationData, investigationData) => {
  let impactScore = 0;

  // calculate and add velocity to impact score
  const velocityScore = velocity.investigation + velocity.creation;
  impactScore += velocityScore;

  // calculate and add creation to impact score
  const investigatedSuites = investigationData.flatMap((data) => data.suites);
  const investigationScore = investigatedSuites.reduce((acc, suite) => {
    // handle positives
    acc += suite.number_of_runs_passing_on_qae_fix;
    acc += suite.number_of_runs_triaged_as_bug;

    // handle negatives
    acc -= suite.number_of_runs_passing_on_qae_flake / 10;
    acc -= suite.number_of_runs_triaged_as_maintenance * 2;

    return acc;
  }, 0);

  impactScore += investigationScore;

  // calculate and add creation to impact score
  const creationScore = 0;
  impactScore += creationScore;

  return Math.round(impactScore * 100) / 100;
};

/**
 * @typedef {object} TeamWeekData
 * @property {number} a
 * @property {number[]} mm
 * @property {number[]} p
 * @property {string} week
 */

/**
 * Formats aggregate data into bar chart format (impact score)
 * @param {import('../types').InvestigationTrackedTimeAggregate[]} investigationData
 * @param {import('../types').TaskSnapshotTrackedTimeAggregate[]} creationData
 * @param {import('../types').User[]} qaes
 * @param {boolean} complexImpact
 * @returns {(TeamWeekData & {[key: string]: number})[]}
 */
const formatChartData = (investigationData, creationData, qaes, complexImpact) => {
  const dataArray = [];

  // group data sets by week
  const investigationDataGroupedByWeek = _.groupBy(investigationData, 'week');
  const creationDataGroupedByWeek = _.groupBy(creationData, 'week');

  // get all weeks
  const allWeeks = _.uniq([...Object.keys(investigationDataGroupedByWeek), ...Object.keys(creationDataGroupedByWeek)]);

  // iterate through each week and process creation and investigation data
  for (const week of allWeeks) {
    const allQaesInvestigationForWeek = investigationDataGroupedByWeek[week] || [];
    const allQaesCreationForWeek = creationDataGroupedByWeek[week] || [];

    /** @type {{[key: string]: {investigation: number, creation: number}}} */
    const teamWeekVelocity = {};

    /** @type {TeamWeekData & {[key: string]: number}} */
    const teamWeekData = {};

    // calculate investigation velocity for each qae
    for (const qae of allQaesInvestigationForWeek) {
      const hoursTrackedThisWeek = qae.hours_tracked;
      const investigationVelocity = qae.total_failures_investigated_this_week / hoursTrackedThisWeek;

      if (!teamWeekVelocity[qae.user_id]) {
        teamWeekVelocity[qae.user_id] = { investigation: investigationVelocity, creation: 0 };
      } else {
        teamWeekVelocity[qae.user_id].investigation = investigationVelocity;
      }
    }

    // calculate creation velocity for each qae
    for (const qae of allQaesCreationForWeek) {
      const hoursTrackedThisWeek = qae.hours_tracked;
      const creationVelocity = qae.tests_completed_this_week / hoursTrackedThisWeek;

      if (!teamWeekVelocity[qae.user_id]) {
        teamWeekVelocity[qae.user_id] = { investigation: 0, creation: creationVelocity };
      } else {
        teamWeekVelocity[qae.user_id].creation = creationVelocity;
      }
    }

    // calculate each qae's impact score from teamWeekVelocity
    for (const qaeId of Object.keys(teamWeekVelocity)) {
      // match qae
      const qaeMatch = qaes.find((qae) => qae.qawId === qaeId);

      // get qae velocity, investigation, and creation
      const qaeVelocity = teamWeekVelocity[qaeId];
      const qaeCreation = allQaesCreationForWeek.filter((creation) => creation.user_id === qaeId);
      const qaeInvestigation = allQaesInvestigationForWeek.filter((investigation) => investigation.user_id === qaeId);

      teamWeekData[qaeMatch.name] = complexImpact
        ? computeImpactScore(qaeVelocity, qaeCreation, qaeInvestigation)
        : Math.round((qaeVelocity.creation + qaeVelocity.investigation) * 100) / 100;
    }

    // calculate team { mm: [min, max], a: avg, p: {25th, 75th} } impact score
    teamWeekData.a = Math.round(_.mean(Object.values(teamWeekData)) * 100) / 100;
    teamWeekData.mm = [/** @type {number} */ (_.min(Object.values(teamWeekData))), /** @type {number} */ (_.max(Object.values(teamWeekData)))];
    const teamWeekDataNumberValues = Object.values(teamWeekData).filter((v) => typeof v === 'number');
    teamWeekData.p = [computePercentile(teamWeekDataNumberValues, 25), computePercentile(teamWeekDataNumberValues, 75)];

    // add week property
    teamWeekData.week = week;

    // push week data into dataArray
    dataArray.push(teamWeekData);
  }

  // sort dataArray by week
  dataArray.sort((a, b) => a.week.localeCompare(b.week));

  return dataArray;
};

/**
 *
 * @param {object} props
 * @param {{investigation: import('../types').TeamInvestigationAggregateResponse, creation: import('../types').TeamTaskSnapshotAggregateResponse}} props.chartData
 * @returns {import('react').JSX.Element}
 */
export default function ImpactScoreChart({ chartData }) {
  // Query user data from Tanstack network
  const { data: allQAWUsers } = useQueryKeyData(['getAllQAWUsers']);
  const allQAEs = allQAWUsers.filter((user) => user.isQAE || user.isManager || user.isLead);

  const { investigation, creation } = chartData;

  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  // refs
  const parentRef = useRef(null);
  const isInitialRender = useRef(true);

  // state
  const [width, setWidth] = useState(0);
  const [complexImpact/* , setComplexImpact */] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [lineProps, setLineProps] = useState({});
  const [hoverProps, setHoverProps] = useState({ hover: null });

  const handleLegendMouseEnter = useCallback(
    /**
     * Sets hover state to specific QAE
     * @param {import('../types').User} qae
     */
    (qae) => {
      if (!hoverProps[qae.name]) {
        setHoverProps((prev) => ({ ...prev, hover: qae.name }));
      }
    },
    [],
  );

  /**
   * Removes hover state
   */
  const handleLegendMouseLeave = useCallback(() => {
    setHoverProps((prev) => ({ ...prev, hover: null }));
  }, []);

  const handleLegendClick = useCallback(
    /**
     * Sets viz state for specific QAE
     * @param {import('../types').User} qae
     */
    (qae) => {
      setLineProps((prevState) => {
        const newLineProps = {
          ...prevState,
          [qae.name]: !prevState[qae.name],
        };

        // update search params
        const newVisibleLines = Object.keys(newLineProps)
          .filter((key) => newLineProps[key])
          .join(',');
        searchParams.set('visibleLines', newVisibleLines);
        setSearchParams(searchParams);

        // remove hover state
        setHoverProps({ hover: null });

        return newLineProps;
      });
    },
    [],
  );

  const CustomLegend = useCallback(
    /**
     * Renders the custom legend with QAE icons, toggle-able vis, and hover-able stroke
     * @param {import('recharts').LegendProps} props
     * @returns {import('react').JSX.Element}
     */
    (props) => {
      const { payload } = props;

      return (
        <ul className="list-none p-0 flex justify-center gap-x-4">
          {payload.map((entry, entryIdx) => {
            const qae = /** @type {import('../types').User} */ (entry.value);
            return (
              <li key={entryIdx} className="flex items-center mr-4">
                <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: !lineProps[qae.name] ? '#ccc' : entry.color }} />
                <button
                  onClick={() => handleLegendClick(qae)}
                  onMouseEnter={() => handleLegendMouseEnter(qae)}
                  onMouseOut={handleLegendMouseLeave}
                  className={classNames('text-sm whitespace-nowrap', !lineProps[qae.name] && 'text-gray-400')}
                >
                  {qae.name}
                </button>
              </li>
            );
          })}
        </ul>
      );
    },
    [lineProps, handleLegendMouseEnter, handleLegendMouseLeave, handleLegendClick],
  );

  // format data for chart (calc impact scores)
  useEffect(() => {
    setTeamData(
      formatChartData(
        investigation.teamInvestigationAggregatedWithTrackedTime,
        creation.teamTaskSnapshotsAggregatedWithTrackedTime,
        allQAEs,
        complexImpact,
      ),
    );
  }, [chartData, complexImpact]);

  useEffect(() => {
    // get all active members for non reference lines
    const activeMembersSet = new Set();

    // get all active members
    teamData.forEach((w) => {
      Object.keys(w).forEach((qae) => {
        if (!['a', 'p', 'mm', 'week'].includes(qae)) {
          activeMembersSet.add(allQAEs.find((q) => q.name === qae));
        }
      });
    });

    setActiveMembers([...activeMembersSet]);

    // if there are already visible lines in the search params, respect them when setting the lineProp state
    if (
      paramsMap.visibleLines &&
      paramsMap.visibleLines.split(',').length &&
      paramsMap.visibleLines.split(',').some((name) => [...activeMembersSet].some((qae) => qae.name === name))
    ) {
      setLineProps(
        [...activeMembersSet].reduce((acc, { name }) => {
          acc[name] = paramsMap.visibleLines.split(',').includes(name);
          return acc;
        }, {}),
      );
    } else if (paramsMap.visibleLines === '') {
      // explicitly handle empty string - indicates lines were all toggled off manually
      setLineProps(
        [...activeMembersSet].reduce((acc, { name }) => {
          acc[name] = paramsMap.visibleLines.split(',').includes(name);
          return acc;
        }, {}),
      );
    } else {
      // else set all lines to be visible and set initial search params with all active members
      setLineProps(
        [...activeMembersSet].reduce((acc, { name }) => {
          acc[name] = true;
          return acc;
        }, {}),
      );

      // set search params with all active members
      const visibleLines = [...activeMembersSet].map(({ name }) => name).join(',');
      if (visibleLines.length) {
        searchParams.set('visibleLines', visibleLines);
        setSearchParams(searchParams);
      }
    }
  }, [teamData]);

  // handles chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, []);

  // handles detecting initial render for future lineProps updates
  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  // memoize legend payload to reduce re-rendering
  const memoizedLegendPayload = useMemo(
    () =>
      activeMembers.map((qae) => ({
        value: qae,
        color: hslStringToRgbString(stringToColor(qae.name)),
      })),
    [activeMembers],
  );

  return (
    <div className="flex flex-col items-center justify-enter border" ref={parentRef}>
      <div className="flex justify-between font-semibold border-b w-full px-4">
        <span>Impact Scores</span>
        {/* <Switch.Group as="div" className="flex justify-center items-center gap-x-4">
          <Switch.Label>Velocity Only</Switch.Label>
          <Switch
            checked={complexImpact}
            onChange={() => setComplexImpact(!complexImpact)}
            className={classNames(
              complexImpact ? 'bg-blue-400' : 'bg-gray-200',
              'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                complexImpact ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label>Complex</Switch.Label>
        </Switch.Group> */}
      </div>
      <ComposedChart
        width={width - 10}
        height={550}
        data={teamData}
        margin={{
          top: 20,
          right: 10,
          left: -10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="week" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dataKey="mm" stroke="none" fill="#cccccc" connectNulls dot={false} activeDot={false} />
        <Area type="monotone" dataKey="p" stroke="none" fill="#FF9999" connectNulls dot={false} activeDot={false} />
        {activeMembers.map((qae) => (
          <Line
            key={qae.name}
            type="monotone"
            dataKey={qae.name}
            hide={lineProps[qae.name] === false}
            stroke={hslStringToRgbString(stringToColor(qae.name))}
            strokeWidth={hoverProps.hover === qae.name ? 3 : 1.5}
            opacity={hoverProps.hover === qae.name || !hoverProps.hover ? 1 : 0.3}
            connectNulls
            dot={false}
            activeDot={false}
          />
        ))}
        <Line
          type="natural"
          dataKey="a"
          stroke={hslStringToRgbString(stringToColor('average'))}
          strokeWidth={3}
          connectNulls
          dot={false}
          activeDot={false}
        />
        <Legend payload={memoizedLegendPayload} content={CustomLegend} />
      </ComposedChart>
    </div>
  );
}
