import { ResponsiveContainer } from 'recharts';

import { QaeTimeTrackingCard } from './QaeTimeTrackingCard';
import { TimeTrackingBarChart } from './TimeTrackingBarChart';

import { groupAndSortEvents, normalizeEventsForChart, normalizeTrackedEventsForChart, parseEvents, parseTrackedEvents } from './timeTrackingHelpers';

import useQueryKeyData from '../../../hooks/useQueryKeyData';

export function TeamTimeTrackingCard({ team, filters }) {
  // Query user data from Tanstack network
  const { data: allQAWUsers } = useQueryKeyData(['getAllQAWUsers']);
  const allQaes = /** @type {import('../types').User[]} */ (allQAWUsers.filter((user) => user.isQAE || user.isManager || user.isLead));

  const allEvents = {};
  const allTrackedEvents = {};

  for (const qaeId of Object.keys(team.events)) {
    // parse events for each qae and add to parsedEvents
    const { batchTotals } = parseEvents(groupAndSortEvents(team.events[qaeId]), qaeId);
    const { totalBreakdown } = parseTrackedEvents(groupAndSortEvents(team.events[qaeId]));

    allEvents[qaeId] = batchTotals;
    allTrackedEvents[qaeId] = totalBreakdown;
  }

  // generate normalized data for chart
  const chartNormalizedEventData = normalizeEventsForChart(allEvents);

  // generate normalized tracked data for chard
  const normalizedTrackedEventData = normalizeTrackedEventsForChart(allTrackedEvents);

  return (
    <div className="min-h-full px-1/2">
      <div className="flex rounded-md bg-white border justify-around items-center p-2 my-2">
        <div className="flex-1 flex flex-col items-center pr-4 space-y-3 ml-4">
          <div className="flex items-center justify-center h-20 w-20">
            <img
              className="h-20 w-20 rounded-full grayscale border-4"
              src={team.qaTeam.imageUrl}
              alt={`${team.teamName} Team - ${team.name}`}
              title={`${team.teamName} Team - ${team.name}`}
            />
          </div>
          <h2 className="text-3xl font-medium">{team.qaTeam.name}</h2>
        </div>
        {chartNormalizedEventData.length > 0 && (
          <div className="flex-1 flex-col items-center justify-center">
            <ResponsiveContainer className="flex items-center justify-end" width="100%" height="100%">
              <TimeTrackingBarChart normalizedData={[...chartNormalizedEventData, ...normalizedTrackedEventData]} />
            </ResponsiveContainer>
          </div>
        )}
      </div>
      <div>
        {team &&
          Object.keys(team.events)
            .map((id) => allQaes.find((qae) => qae.qawId === id) || { qawId: id })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((qae) => {
              const { qawId: id } = qae;
              return qae.name ? (
                <div key={id} className="bg-white rounded-md flex mb-2">
                  <QaeTimeTrackingCard events={team.events[id]} qae={qae} filters={filters} />
                </div>
              ) : (
                <div
                  key={id}
                  className="flex border mb-2 p-4 text-red-600"
                >{`No QAE in localStorage with id: ${id}. Please generate a new session by logging out and back in.`}</div>
              );
            })}
      </div>
    </div>
  );
}
