import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoginPage from './components/LoginPage/LoginPage';
import ErrorPage from './components/Layout/ErrorPage';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import MainLayout from './components/Layout/MainLayout';
import { logout, tokenVerification } from './components/LoginPage/authActions';
import { InvestigationBoard } from './components/InvestigationBoard/InvestigationBoard';
import Tasks from './components/Tasks/Tasks';
import {
  getOpenTasks,
  claimTask,
  unclaimTask,
  checkTask,
  ignoreTask,
  urgentTask,
  doneTask,
  subscribe,
} from './components/InvestigationBoard/investigationActions';

import SummaryView from './components/Planning/Summary/Summary';
import Settings from './components/Settings/Settings';
import FlowExplorer from './components/FlowExplorer/FlowExplorer';
import { getSuiteInfo } from './components/FlowExplorer/getSuiteInfo';
import StreamExplorer from './components/StreamExplorer/StreamExplorer';
import StreamExplorerNav from './components/StreamExplorer/StreamExplorerNav';
import GoogleConnector from './components/OAuth/Google/GoogleConnector';
import GoogleCodeConverter from './components/OAuth/Google/GoogleCodeConverter';
import FacebookConnector from './components/OAuth/Facebook/FacebookConnector';
import FacebookCodeConverter from './components/OAuth/Facebook/FacebookCodeConverter';
import TikTokConnector from './components/OAuth/TikTok/TikTokConnector';
import TikTokCodeConverter from './components/OAuth/TikTok/TikTokCodeConverter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { multiUpdateTasks } from './components/Planning/api';
import TeamsAuthCode from './components/TeamsAuth/TeamsAuthCode';
import RedirectRoute from './components/Planning/Summary/CustomerTasks/RedirectRoute';
import ExecutiveDashboard from './components/ExecutiveDashboard/ExecutiveDashboard';
import InsightsExplorer from './components/InsightsExplorer/InsightsExplorerView';
import InsightsPlayground from './components/InsightsPlayground/InsightsPlayground';
import ClientSummary from './components/Planning/Summary/ClientSummary';
import BookOfBusiness from './components/BookOfBusiness/BookOfBusiness';
import ReportsPage from './components/ReportsPage/ReportsPage';
import { TaskFilterCriteriaProvider } from './components/context/TaskFilterCriteriaContext';
import { PlanningContextProvider } from './components/context/PlanningContext';
import InvestigationMetrics from './components/InvestigationMetrics/InvestigationMetrics';
import ClerkPage from './components/ClerkDemo/ClerkDemoLanding';
import { ClerkProvider } from '@clerk/clerk-react';
import ClerkDemoSignUp from './components/ClerkDemo/ClerkDemoSignUp';
import HiringPublic from './components/HiringPublic/HiringPublic';
import HiringInternalDashboard from './components/HiringInternal/HiringInternalDashboard';
import CustomerToTeams from './components/CustomerToTeams/CustomerToTeams';
import HowlUtilization from './components/HowlUtilization';
import SuiteNetworkReport from './components/SuiteNetworkReport/SuiteNetworkReport';
import ColorTest from './components/ColorTest/ColorTest';

export default function App() {
  const RoutesJSX = (
    <>
      <Route path="/" loader={() => redirect('/triage')} index />
      <Route
        element={
          <ProtectedRoute>
            <MainLayout />
          </ProtectedRoute>
        }
        errorElement={<ErrorPage />}
      >
        <Route path="/triage" element={<InvestigationBoard />} errorElement={<ErrorPage />} />

        <Route path="/tasks" element={<Tasks />} errorElement={<ErrorPage />} />

        <Route path="/client-summary" element={<SummaryView />} errorElement={<ErrorPage />} />
        <Route path="/client-summary/:teamId" element={<ClientSummary />} errorElement={<ErrorPage />} />

        <Route path="/book-of-business" element={<BookOfBusiness />} errorElement={<ErrorPage />} />

        <Route path="/settings" element={<Settings />} loader={getOpenTasks} errorElement={<ErrorPage />} />

        <Route path="/flow-explorer" element={<Navigate to="/flow-explorer/:suiteId" replace />} errorElement={<ErrorPage />} />
        <Route path="/flow-explorer/:suiteId" element={<FlowExplorer />} errorElement={<ErrorPage />} />
        <Route path="/stream-explorer-nav" element={<StreamExplorerNav />} errorElement={<ErrorPage />} />
        <Route path="/stream-explorer/:teamId" element={<StreamExplorer />} errorElement={<ErrorPage />} />

        <Route path="/reports" element={<ReportsPage />} errorElement={<ErrorPage />} />

        <Route path="/investigation-metrics" element={<InvestigationMetrics />} errorElement={<ErrorPage />} />

        <Route path="/executive-dashboard" element={<ExecutiveDashboard />} errorElement={<ErrorPage />} />
        <Route path="/insights-explorer" element={<InsightsExplorer />} errorElement={<ErrorPage />} />
        <Route path="/insights-playground" element={<InsightsPlayground />} errorElement={<ErrorPage />} />

        <Route path="/howl-utilization" element={<HowlUtilization />} errorElement={<ErrorPage />} />
        <Route path="/customer-team-assignments" element={<CustomerToTeams />} errorElement={<ErrorPage />} />
        <Route path="/suite-network-report" element={<SuiteNetworkReport />} errorElement={<ErrorPage />} />

        {/* LEGACY ROUTES */}
        <Route path="/taskboard" element={<Navigate to="/client-summary" replace />} errorElement={<ErrorPage />} />
        <Route path="/teamTasks/:teamId" element={<RedirectRoute />} errorElement={<ErrorPage />} />
        <Route path="/sniffer" element={<Navigate to="/flow-explorer/:suiteId" replace />} errorElement={<ErrorPage />} />
        <Route path="/sniffer/:suiteId" element={<RedirectRoute />} loader={getSuiteInfo} errorElement={<ErrorPage />} />

        <Route path="/google/auth" element={<GoogleConnector />} />
        <Route path="/google/code" element={<GoogleCodeConverter />} />

        <Route path="/tiktok/auth" element={<TikTokConnector />} />
        <Route path="/tiktok/code" element={<TikTokCodeConverter />} />

        {/* these should eventually be cleaned up when we're sure everything is being handled by React Router */}
        <Route path="/updateTasks" action={multiUpdateTasks} />
        <Route path="/getTasks" action={getOpenTasks} />
        <Route path="/claim" action={claimTask} />
        <Route path="/unclaim" action={unclaimTask} />
        <Route path="/check" action={checkTask} />
        <Route path="/ignore" action={ignoreTask} />
        <Route path="/urgent" action={urgentTask} />
        <Route path="/done" action={doneTask} />
        <Route path="/subscribe" action={subscribe} />
        <Route path="/teams/auth" element={<TeamsAuthCode />} />

        <Route path="/hiring-dashboard" element={<HiringInternalDashboard />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login/:token" loader={tokenVerification} />
      <Route path="/logout" action={() => logout()} />
      {/*Jackals Hiring Work */}
      <Route path="/apply-qae" element={<HiringPublic />} />
      {/* Clerk Demo */}
      <Route path="/clerk-demo" element={<ClerkPage />} errorElement={<ErrorPage />} />
      <Route path="/clerk-demo-sign-up" element={<ClerkDemoSignUp />} errorElement={<ErrorPage />} />
      <Route path="/color-test" element={<ColorTest />} />
      {/* FB OAuth */}
      <Route path="/facebook/auth" element={<FacebookConnector />} />
      <Route path="/facebook/code" element={<FacebookCodeConverter />} />
    </>
  );

  const routes = createRoutesFromElements(RoutesJSX);
  const router = createBrowserRouter(routes);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30 * 1000,
        // refetchInterval: 60 * 1000,
        // refetchOnWindowFocus: true,
        throwOnError: true,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* eslint-disable-next-line no-undef */}
        <ClerkProvider afterSignOutUrl="/clerk-demo" publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}>
          <TaskFilterCriteriaProvider>
            <PlanningContextProvider>
              <RouterProvider router={router} />
              <ReactQueryDevtools initialIsOpen={false} />
            </PlanningContextProvider>
          </TaskFilterCriteriaProvider>
        </ClerkProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}
