import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sendGetRequest } from '../../utils/network';
import { useQuery } from '@tanstack/react-query';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Grid, MenuItem, Select, FormControl, InputLabel, Checkbox, FormControlLabel, FormGroup, Tabs, Tab } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import TeamSelector from '../Layout/TeamSelector';
import WolfLoader from '../WolfLoader/WolfLoader';
import BubbleChart from './BubbleChart/BubbleChartInvestigation';
import InvestigationScatterPlot from './ScatterPlot/ScatterPlotInvestigation';
import fetchInvestigationDataForMultipleClients from './Helpers/fetchInvestigationDataForMultipleClients';
import { aggregateData, prepareScatterData } from './Helpers/InvestigationCapacityHelpers';
import InvestigationSimulation from './InvestigationSimulation';

dayjs.extend(utc);
dayjs.extend(timezone);

const getPreviousWeekRange = () => {
  const today = dayjs();
  const startOfWeek = today.startOf('week').subtract(1, 'week');
  const endOfWeek = today.endOf('week').subtract(1, 'week');
  return {
    startDate: startOfWeek.format('YYYY-MM-DD'),
    endDate: endOfWeek.format('YYYY-MM-DD'),
  };
};

export default function InvestigationMetrics() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'bubble';

  const localActiveTeams = JSON.parse(localStorage.getItem('teams')).filter((team) => team.active);
  const previousWeek = getPreviousWeekRange();

  const [filters, setFilters] = useState({
    startDate: previousWeek.startDate,
    endDate: previousWeek.endDate,
  });

  const [enabledTeams, setEnabledTeams] = useState(localActiveTeams);
  const [allClients, setAllClients] = useState([]);
  const [selectedTeamClients, setSelectedTeamClients] = useState([]);
  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [selectedTriggers, setSelectedTriggers] = useState(new Set(['Deploy', 'Timed']));
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    (async () => {
      const rawClients = await sendGetRequest('/clients').catch((e) => {
        throw new Error(e);
      });

      const flattenedClients = Object.entries(rawClients.data).map(([key, value]) => ({ ...value, key }));
      setAllClients(flattenedClients);
    })();
  }, []);

  useEffect(() => {
    const teamClients = allClients.filter((client) =>
      enabledTeams.some((team) => team.email === client.qaLead.email),
    );

    setSelectedTeamClients(teamClients);
  }, [enabledTeams, allClients]);

  const customerIds = selectedTeamClients.map((client) => client.id);

  const {
    data: suiteData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['investigationDataByClients', customerIds, filters.startDate, filters.endDate],
    queryFn: () => fetchInvestigationDataForMultipleClients(customerIds, filters.startDate, filters.endDate),
    enabled: !!customerIds.length,
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const handleCheckboxChange = (event) => {
    const triggerName = event.target.name;
    const newSelectedTriggers = new Set(selectedTriggers);
    if (newSelectedTriggers.has(triggerName)) {
      newSelectedTriggers.delete(triggerName);
    } else {
      newSelectedTriggers.add(triggerName);
    }
    setSelectedTriggers(newSelectedTriggers);
  };

  const handleTabChange = (event, newValue) => {
    // Check if the tab is not disabled before allowing change
    if (!isSimulationTabDisabled || newValue !== 'simulation') {
      setActiveTab(newValue);
      setSearchParams({ tab: newValue });
    }
  };

  const filterDataByTriggers = (data) => {
    if (selectedTriggers.has('Deploy') && selectedTriggers.has('Timed')) {
      return data;
    }

    return data.filter((suite) => {
      const isTimed = suite.triggerName.includes('CI');
      if (selectedTriggers.has('Deploy') && !isTimed) return true;
      if (selectedTriggers.has('Timed') && isTimed) return true;
      return false;
    });
  };

  
  const filteredSuiteData = filterDataByTriggers(suiteData);
  const aggregatedData = aggregateData(filteredSuiteData, timezone);
  const scatterData = prepareScatterData(filteredSuiteData, timezone);

  const isSimulationTabDisabled = localActiveTeams.length !== 1;

  const viewMap = {
    bubble: <BubbleChart data={aggregatedData} />,
    scatter: <InvestigationScatterPlot data={scatterData} />,
    simulation: <InvestigationSimulation />,
  };

  return (
    <div>
      <TeamSelector enabledTeams={enabledTeams} setEnabledTeams={setEnabledTeams} />
      <Box sx={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '95%', paddingTop: '10px', paddingLeft: '10px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '600px', paddingTop: '10px' }}>
            <Grid container spacing={1} sx={{ maxWidth: '600px' }}>
              {['Start Date *', 'End Date *'].map((dateType, index) => {
                const dateParam = dateType.includes('Start') ? 'startDate' : 'endDate';
                return (
                  <Grid item key={index} xs={6}>
                    <DatePicker
                      value={dayjs(filters[dateParam])}
                      onChange={(newDate) => setFilters((prev) => ({ ...prev, [dateParam]: dayjs(newDate).toISOString() }))}
                      label={dateType}
                      disableFuture
                    />
                  </Grid>
                );
              })}
            </Grid>
            <FormControl sx={{ minWidth: 200, marginLeft: '20px' }}>
              <InputLabel id="timezone-label">Timezone</InputLabel>
              <Select labelId="timezone-label" id="timezone-select" value={timezone} label="Timezone" onChange={(e) => setTimezone(e.target.value)}>
                <MenuItem value="America/New_York">Eastern Time (ET)</MenuItem>
                <MenuItem value="America/Chicago">Central Time (CT)</MenuItem>
                <MenuItem value="America/Denver">Mountain Time (MT)</MenuItem>
                <MenuItem value="America/Los_Angeles">Pacific Time (PT)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, marginLeft: '20px' }}>
            <FormGroup row>
              {['Deploy', 'Timed'].map((triggerName) => (
                <FormControlLabel
                  key={triggerName}
                  control={
                    <Checkbox
                      checked={selectedTriggers.has(triggerName)}
                      onChange={handleCheckboxChange}
                      name={triggerName}
                      sx={{
                        color: '#2f2fc1',
                        '&.Mui-checked': {
                          color: '#2f2fc1',
                        },
                      }}
                    />
                  }
                  label={triggerName}
                  sx={{ margin: 0 }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            isolate: 'true',
            display: 'flex',
            flexDirection: 'row',
            boxShadow: 3,
            borderRadius: 1,
            marginTop: `10px`,
            '& .MuiTabs-flexContainer': {
              display: 'flex',
              gap: '0',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            },
          }}
        >
          <Tab
            label="Failure Bubble Chart"
            value="bubble"
            sx={{
              textTransform: 'none',
              flex: 1,
              minWidth: '0',
              overflow: 'hidden',
              backgroundColor: 'white',
              px: 4,
              py: 2,
              fontSize: '14px',
              fontWeight: 'medium',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
              },
              '&.Mui-selected': {
                color: 'black',
              },
              '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
              },
            }}
          />
          <Tab
            label="Time to Investigate Scatter Plot"
            value="scatter"
            sx={{
              textTransform: 'none',
              flex: 1,
              minWidth: '0',
              overflow: 'hidden',
              backgroundColor: 'white',
              px: 4,
              py: 2,
              fontSize: '14px',
              fontWeight: 'medium',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
              },
              '&.Mui-selected': {
                color: 'black',
              },
            }}
          />
          <Tab
            label="Investigation Simulation"
            value="simulation"
            disabled={isSimulationTabDisabled}
            sx={{
              textTransform: 'none',
              flex: 1,
              minWidth: '0',
              overflow: 'hidden',
              backgroundColor: 'white',
              px: 4,
              py: 2,
              fontSize: '14px',
              fontWeight: 'medium',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
              },
              '&.Mui-disabled': {
                opacity: 0.5,
                cursor: 'not-allowed',
              },
            }}
          />
        </Tabs>
      </Box>
      {viewMap[activeTab]}
    </div>
  );
}
