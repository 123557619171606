import { Paper, Typography, Divider, Box, ListItem, ListItemText } from '@mui/material';
import { List } from 'reactstrap';
import { QaTeamCapacity, usePlanningContext } from '../../context/PlanningContext';
import { plural } from '../../InvestigationBoard/helpers';
import ApproveAllButton from './ApproveAllButton';

export function TeamCapacityPanel() {
  const { qaTeamCapacity, clientSummaries, totalScheduledForCurrentWeek, remainingCapacity, showApproveAllButton } = usePlanningContext();
  const capacityData = qaTeamCapacity.data as QaTeamCapacity;
  const clientSummariesData = clientSummaries.data;
  const isCapacityLoading = qaTeamCapacity.isLoading || qaTeamCapacity.isFetching;
  const isClientSummariesLoading = clientSummaries.isLoading || clientSummaries.isFetching;

  const requiredMilestoneTests = clientSummariesData?.reduce((sum, row) => sum + Math.max(row.requiredVelocity || 0, 0), 0) ?? 0;

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        <Box>
          <Typography variant="h5">Team Capacity</Typography>
          <Typography variant="caption" sx={{ mt: 2, maxWidth: '100ch' }}>
            <strong>Note:</strong> Average values are calculated based on the last 8 weeks of data.
          </Typography>
        </Box>
        {showApproveAllButton && <ApproveAllButton />}
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" sx={{ px: 2 }}>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Total QAE Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.totalQaeHours} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="OOO Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.oooHours} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Avg. Investigation Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.avgInvestigationHours} />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Avg. Meeting / Other Hours" secondary={isCapacityLoading ? 'Loading...' : capacityData.avgMeetingHours} />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Est. Creation / Maint Hours Available"
                secondary={isCapacityLoading ? 'Loading...' : capacityData.hoursAvailable}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Avg. Tests Created Per Hour"
                secondary={isCapacityLoading ? 'Loading...' : capacityData.avgTestsCreatedPerHour}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Est. Creation Capacity"
                secondary={isCapacityLoading ? 'Loading...' : `${capacityData.estCreationCapacity} test${plural(capacityData.estCreationCapacity)}`}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary="Remaining Capacity" secondary={isClientSummariesLoading ? 'Loading...' : `${remainingCapacity} tests`} />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Total Tests Scheduled For Selected Week"
                secondary={isClientSummariesLoading ? 'Loading...' : `${totalScheduledForCurrentWeek} tests`}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Tests Created This Week"
                secondary={isCapacityLoading ? 'Loading...' : `${capacityData.testsCreatedThisWeek} test${plural(capacityData.testsCreatedThisWeek)}`}
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Tests Req'd for Milestones"
                secondary={isClientSummariesLoading ? 'Loading...' : `${requiredMilestoneTests} tests`}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Paper>
  );
}
