import _ from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import LegendForCreationOutlineMaintenanceTaskCharts from './LegendForCreationOutlineMaintenanceTaskCharts';
import CustomTooltip from './CustomTooltip';

import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { dataTypeMap, storageMap } from '../helpers';
import { taskTypeMap } from '../types';

const buildChartDataFromInsights = (insights, weeks, prefix) => {
  const chartData = [];

  for (const week of weeks) {
    const weekObj = {
      name: week,
    };

    for (const typeGroup of Object.keys(taskTypeMap)) {
      const typeGroupData = insights[week]?.filter((x) => x.type === typeGroup) ?? [];
      const sumTasks = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_tasks'])) || 0;
      const sumTests = _.sumBy(typeGroupData, (obj) => parseFloat(obj['total_steps'])) || 0;
      weekObj[`${prefix ? prefix + ' | ' : ''}${taskTypeMap[typeGroup]} - ${typeGroup === 'testCreation' ? 'Workflows' : 'Tasks'}`] = sumTasks || 0;
      weekObj[`${prefix ? prefix + ' | ' : ''}${taskTypeMap[typeGroup]} - Tests`] = sumTests || 0;
    }

    chartData.push(weekObj);
  }

  return chartData;
};

const addAveragesToChartData = (chartData, denominator) => {
  for (const week of chartData) {
    for (const key of Object.keys(week)) {
      if (key === 'name') continue;
      week[`${key} | Average`] = (week[key] / denominator).toFixed(2) || 0;
    }
  }
};

function mergeWeeklyData(data) {
  const mergedData = {};

  // Iterate over each key in the data
  for (const key of Object.keys(data)) {
    data[key].forEach((week) => {
      const weekName = week.name;

      // If the week name is not already in mergedData, initialize it
      if (!mergedData[weekName]) {
        mergedData[weekName] = { name: weekName };
      }

      // Add the data from the current key to the corresponding week in mergedData
      for (const insight in week) {
        if (insight !== 'name') {
          mergedData[weekName][insight] = week[insight];
        }
      }
    });
  }

  // Convert the mergedData object to an array
  return Object.values(mergedData);
}

export default function NewWeeklyCreationChart({ data }) {
  if (!data) {
    return <div>No data provided for creation chart</div>;
  }
  // Determine the name of the QA lead to use for sorting
  const leadName = data.team?.members?.find((m) => m.isLead)?.name || '';

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  /**
   * Render custom legend
   * @param {import('recharts').LegendProps} props - Props passed to the legend
   * @returns {import('react').JSX.Element} - Custom legend component
   */
  const renderLegend = (props) => {
    return <LegendForCreationOutlineMaintenanceTaskCharts
      payload={props.payload}
      leadName={leadName}
      images={images}
      visibility={visibility}
      clickFunction={handleLegendClick}
      enterFunction={handleLegendEnter}
      leaveFunction={handleLegendLeave} />;
  };

  /* LOCAL DATA MANIPULATION */

  // determine type of data we are making a chart for
  let dataType = data.qae ? 'qae' : data.team ? 'team' : 'pack';
  const creationAndOutliningData = data[dataTypeMap[dataType]];

  const images = {};
  if (dataType === 'qae') {
    images[data.qae.name] = data.qae.avatar48;
  } else if (dataType === 'team') {
    for (const member of data.team.members) {
      images[member.name] = member.avatar48;
    }
    images['Team'] = data.team.imageUrl;
  } else if (dataType === 'pack') {
    for (const team of data.packTeams) {
      images[team.name] = team.imageUrl;
      for (const member of team.members) {
        images[member.name] = member.avatar48;
      }
    }
    images['Pack'] = data.pack.avatar48;
  }

  // determine all unique weeks regardless of if there is data for an individual on that week
  const allWeeks = _.uniq(creationAndOutliningData.map((x) => x['Week Number']));

  // group data by qae name
  const groupedCreationAndOutliningData = new Object(_.groupBy(creationAndOutliningData, 'name'));

  // sub-group data by week number & build individual chart data or each qae
  for (const [name, data] of Object.entries(groupedCreationAndOutliningData)) {
    groupedCreationAndOutliningData[name] = _.groupBy(data, 'Week Number');
    groupedCreationAndOutliningData[name] = buildChartDataFromInsights(groupedCreationAndOutliningData[name], allWeeks, name);
  }

  // add chart data for entire data set
  if (['team', 'pack'].includes(dataType)) {
    const capitalizedType = _.capitalize(dataType);
    const denominator = dataType === 'team' ? data.team.members.length : data.packTeams.length;
    groupedCreationAndOutliningData[capitalizedType] = buildChartDataFromInsights(
      _.groupBy(creationAndOutliningData, 'Week Number'),
      allWeeks,
      capitalizedType,
    );
    addAveragesToChartData(groupedCreationAndOutliningData[capitalizedType], denominator);
  }

  // merge all insight types by week into a single array
  const chartData = mergeWeeklyData(groupedCreationAndOutliningData);

  // sort by week
  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  // set state with already manipulated data
  const visibleLines = paramsMap.visibleLines ? paramsMap.visibleLines.split(',') : [];
  const sessionVisibleLines = JSON.parse(sessionStorage.getItem(storageMap[dataType].creation) || '{}');
  const isPreviousValidLines = visibleLines.length > 0 && visibleLines.some((line) => sortedChartData[0][line]);
  const [visibility, setVisibility] = useState(
    isPreviousValidLines
      ? _.mapValues(sortedChartData[0], (_, key) => visibleLines.includes(key))
      : Object.keys(sessionVisibleLines).length > 0
        ? sessionVisibleLines
        : _.mapValues(sortedChartData[0], (_, key) => key.includes('Creation - Tests')),
  );

  const [opacity, setOpacity] = useState(_.mapValues(sortedChartData[0], () => 1));

  const handleLegendEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = key === dataKey ? 1.5 : 0.3;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendLeave = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = 1;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendClick = useCallback((o) => {
    const { dataKey } = o;

    setVisibility((vis) => {
      const newVisibility = { ...vis, [dataKey]: !vis[dataKey] };
      sessionStorage.setItem(storageMap[dataType].creation, JSON.stringify(newVisibility));
      return newVisibility;
    });
  }, []);

  useEffect(() => {
    const visibleLines = Object.keys(visibility)
      .filter((key) => visibility[key])
      .join(',');
    searchParams.set('visibleLines', visibleLines);
    setSearchParams(searchParams);
  }, [visibility]);

  return (
    <div className="flex-col items-center justify-center border">
      <ResponsiveContainer width="100%" height={1000}>
        <LineChart height={600} data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={5} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ textAlign: 'center', width: '100%' }} content={renderLegend} />
          {Object.keys(sortedChartData).length &&
            Object.keys(sortedChartData[0]).map((line, lineIdx) => {
              if (line === 'name') return null;
              return (
                <Line
                  key={lineIdx}
                  type="monotone"
                  dataKey={line}
                  dot={false}
                  stroke={hslStringToRgbString(stringToColor(line))}
                  strokeOpacity={line.includes('Team') ? 1 : opacity[line]}
                  strokeWidth={line.includes('Team') ? 4 : opacity[line] === 1.5 ? 3 : 1}
                  hide={!visibility[line]}
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
