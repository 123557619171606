import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';

export default function ExportSuitesTableToCsvButton({ filteredSuiteData, customerNameOrId }) {
  const headers = ["Workflow Name"];

  // Helper function to escape double quotes and wrap in double quotes
  const escapeForCsv = (value) => {
    if (value === null || value === undefined) return '';
    const escapedValue = value.toString().replace(/"/g, '""');
    return `"${escapedValue}"`;
  };

  // Sort suites by start time
  const sortedSuiteData = [...filteredSuiteData].sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime)));

  // Add date headers for each suite
  sortedSuiteData.forEach((suite) => {
    headers.push(`${escapeForCsv(suite.startTimeString)} (PT)`);
  });

  // Add headers for Workflow Flakiness and Suite Health Effect
  headers.push("Workflow Flakiness", "Suite Health Effect");

  const downloadCsv = () => {
    let csvContent = headers.join(",") + "\n";

    // Create a map to track the status of workflows across all suites
    const workflowStatusMap = {};
    const workflowSuiteSlowdownMap = {};
    const workflowFlakinessMap = {};

    const suiteSlowdownWeightage = {
      "Passed on First Attempt": 0,
      "Passed on Second Attempt": 30,
      "Passed on Third Attempt": 80,
      "Passed on QAE Retry": 130,
      "Passed on QAE Change": 0,
      "Previously Bugged": 0,
      "Newly Bugged": 0,
      "Maintenance": 0,
      "Do Not Investigate": 0,
      "Superseded": 90,
      "Canceled": 0,
      "Failed": 0,
    };

    const workflowFlakiness = {
      "Passed on First Attempt": 0,
      "Passed on Second Attempt": 1,
      "Passed on Third Attempt": 1,
      "Passed on QAE Retry": 1,
      "Passed on QAE Change": 0,
      "Previously Bugged": 0,
      "Newly Bugged": 0,
      "Maintenance": 0,
      "Do Not Investigate": 0,
      "Superseded": 0,
      "Canceled": 0,
      "Failed": 0,
    };

    sortedSuiteData.forEach((suite, suiteIndex) => {
      Object.keys(suite.runsByLastAttemptStatus).forEach((status) => {
        suite.runsByLastAttemptStatus[status].forEach((workflow) => {
          // Ensure that the entire workflow name is used and properly escaped
          const workflowName = workflow.name.split(/:(.+)/)[1].replace(/"/g, '""'); // Split by the first colon only and escape double quotes for CSV format
          if (!workflowStatusMap[workflowName]) {
            workflowStatusMap[workflowName] = new Array(sortedSuiteData.length + 3).fill("");
            workflowStatusMap[workflowName][0] = `"${workflowName}"`; // Set the workflow name
            workflowSuiteSlowdownMap[workflowName] = { totalWeight: 0, count: 0 }; // Initialize Suite Health Effect tracking
            workflowFlakinessMap[workflowName] = { totalFlakiness: 0, count: 0 }; // Initialize Workflow Flakiness tracking
          }
          // Mark status in the column corresponding to the suite
          workflowStatusMap[workflowName][suiteIndex + 1] = statusToHeaderMap(status);

          // Calculate Suite Health Effect
          workflowSuiteSlowdownMap[workflowName].totalWeight += suiteSlowdownWeightage[statusToHeaderMap(status)] || 0;
          workflowSuiteSlowdownMap[workflowName].count += 1;

          // Calculate Workflow Flakiness
          workflowFlakinessMap[workflowName].totalFlakiness += workflowFlakiness[statusToHeaderMap(status)] || 0;
          workflowFlakinessMap[workflowName].count += 1;
        });
      });
    });

    // Add Workflow Flakiness and Suite Health Effect score to each workflow row and store them in an array for sorting
    const workflowRows = Object.entries(workflowStatusMap).map(([workflowName, row]) => {
      const flakiness = workflowFlakinessMap[workflowName].totalFlakiness / workflowFlakinessMap[workflowName].count;
      const suiteHealthEffect = workflowSuiteSlowdownMap[workflowName].totalWeight / workflowSuiteSlowdownMap[workflowName].count;

      row[row.length - 2] = flakiness.toFixed(2);
      row[row.length - 1] = suiteHealthEffect.toFixed(0);

      return { workflowName, row, flakiness };
    });

    // Sort workflows by Workflow Flakiness in descending order
    workflowRows.sort((a, b) => b.flakiness - a.flakiness);

    // Convert the sorted rows to a CSV string
    workflowRows.forEach(({ row }) => {
      csvContent += row.join(',') + "\n";
    });

    // Trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerNameOrId} Suites Table Data.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Map status keys to the corresponding header
  const statusToHeaderMap = (statusKey) => {
    switch (statusKey) {
      case 'passedFirst': return "Passed on First Attempt";
      case 'passedSecond': return "Passed on Second Attempt";
      case 'passedThird': return "Passed on Third Attempt";
      case 'qaeFlake': return "Passed on QAE Retry";
      case 'qaeTriage': return "Passed on QAE Change";
      case 'autoTriaged': return "Previously Bugged";
      case 'newlyBugged': return "Newly Bugged";
      case 'maintenance': return "Maintenance";
      case 'doNotInvestigate': return "Do Not Investigate";
      case 'canceled': return "Canceled";
      case 'failed': return "Failed";
      case 'supersededRuns': return "Superseded";
      default: return "";
    }
  };

  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#2f2fc1',
        '&:hover': {
          backgroundColor: '#1e1ea1',
        },
        marginLeft: '10px',
        padding: '6px 12px',
        fontSize: '0.8rem',
        minWidth: '160px',
      }}

      startIcon={<FileDownload />}
      onClick={downloadCsv}
    >
      Runs Table CSV
    </Button>
  );
}

