import { Box, Button, IconButton, Typography, Tooltip } from '@mui/material';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CloseRounded, NavigateNextRounded } from '@mui/icons-material';
import { sendGetRequest } from '../../../../utils/tanstackNetwork';
import { Content, Header, Inner, StyledDrawer, Title } from './subComponents';
import { MsDrawer } from './MsDrawer';
import { CreationDrawer } from './CreationDrawer';
import { useState } from 'react';
import WolfLoader from '../../../WolfLoader/WolfLoader';
import { useSearchParams } from 'react-router-dom';
import { ClientSummaryTableRow, Milestone } from '../types';

export default function MainDrawer({ client }: { client: ClientSummaryTableRow }) {
  // Id for direct link to client milestones
  const [searchParams] = useSearchParams();
  const milestoneClientId = searchParams.get('milestoneClientId');
  const [isOpen, setIsOpen] = useState(client.id === milestoneClientId);

  const toggleSelf = () => {
    setIsOpen((prev) => !prev);
  };

  const closeSelf = () => {
    setIsOpen(false);
  };

  const mStones: UseQueryResult<Milestone[]> = useQuery({
    queryKey: ['clientSummary', client.id],
    queryFn: () => sendGetRequest(`/client-summaries/${client.id}`),
    select: (data) => data?.milestones?.map((ms: Milestone, i: number) => ({ ...ms, previousMilestone: data?.milestones[i - 1] })) || [],
    enabled: isOpen,
  });

  let activeMilestoneName = client.activeMilestone ? client.activeMilestone.name : 'N/A';
  const fullMilestoneName = activeMilestoneName;
  if (activeMilestoneName.length > 10) activeMilestoneName = activeMilestoneName.slice(0, 7) + '...';

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <Tooltip title={fullMilestoneName}>
        <span style={{ width: '100%', maxWidth: '10rem' }}>
          <Button onClick={toggleSelf} variant="contained" endIcon={<NavigateNextRounded />} sx={{ width: '100%', maxWidth: '10rem' }}>
            {activeMilestoneName}
          </Button>
        </span>
      </Tooltip>
      <StyledDrawer anchor="right" open={isOpen} onClose={closeSelf} variant="temporary">
        <Inner>
          <Header>
            <IconButton onClick={closeSelf} disableRipple>
              <CloseRounded />
            </IconButton>
            <Title text={`${client.name} Milestones`} />
          </Header>
          <Content>
            {mStones.isError && <Typography variant="caption">{mStones.error.message}</Typography>}
            {mStones.isLoading && <WolfLoader customText="Fetching milestones..." customStyles={{ height: '100%' }} />}
            {!mStones.isLoading && (
              <Box sx={{ mt: '1em' }}>
                {mStones.data?.map((milestone) => {
                  return <MsDrawer milestone={milestone} closeParents={closeSelf} key={milestone.id} client={client} />;
                })}
                <CreationDrawer client={client} milestones={mStones.data} closeParents={closeSelf} />
              </Box>
            )}
          </Content>
        </Inner>
      </StyledDrawer>
    </Box>
  );
}
