import { Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export default function ExportWorkflowTimesToXLSXButton({ filteredSuiteData, customerNameOrId }) {
    // Map status keys to the corresponding header
    const statusToHeaderMap = (statusKey) => {
        switch (statusKey) {
            case 'passedFirst': return "Passed on First Attempt";
            case 'passedSecond': return "Passed on Second Attempt";
            case 'passedThird': return "Passed on Third Attempt";
            case 'qaeFlake': return "Passed on QAE Retry";
            case 'qaeTriage': return "Passed on QAE Change";
            case 'autoTriaged': return "Previously Bugged";
            case 'newlyBugged': return "Newly Bugged";
            case 'maintenance': return "Maintenance";
            case 'doNotInvestigate': return "Do Not Investigate";
            case 'canceled': return "Canceled";
            case 'failed': return "Failed";
            case 'supersededRuns': return "Superseded";
            default: return "";
        }
    };

    const downloadXlsx = async () => {
        const workbook = new ExcelJS.Workbook();
        const usedSheetNames = new Set();

        // Helper function to sanitize trigger names for worksheet names
        const sanitizeSheetName = (name) => {
            let sanitized = name.replace(/[*?:\\/[\]]/g, '|').substring(0, 31);

            // Special handling for European Time Zone triggers
            if (sanitized.startsWith('CI_Weekly_(European_Time_Zone)_')) {
                const dayNumber = sanitized.match(/\d+/)[0];
                const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][parseInt(dayNumber) - 1];
                sanitized = `EU_TZ_${day}`;
            }

            // Ensure unique sheet names
            let uniqueSanitized = sanitized;
            let counter = 1;
            while (usedSheetNames.has(uniqueSanitized)) {
                uniqueSanitized = `${sanitized.substring(0, 28)}_${counter}`.substring(0, 31);
                counter++;
            }
            usedSheetNames.add(uniqueSanitized);

            return uniqueSanitized;
        };

        // Group suites by trigger
        const suitesByTrigger = filteredSuiteData.reduce((acc, suite) => {
            const triggerName = suite.triggerName.toLowerCase().startsWith('pr deploy') ||
                suite.triggerName?.toLowerCase().startsWith('pr test')
                ? 'PR Deployments'
                : suite.triggerName || 'Manual';

            if (!acc[triggerName]) {
                acc[triggerName] = [];
            }
            acc[triggerName].push(suite);
            return acc;
        }, {});

        // Create a sheet for each trigger
        Object.entries(suitesByTrigger).forEach(([triggerName, triggerSuites]) => {
            const sanitizedTriggerName = sanitizeSheetName(triggerName);
            const worksheet = workbook.addWorksheet(sanitizedTriggerName);

            // Create worksheet headers (now just a single header row)
            const headers = [
                'Workflow Name',
                'Mean Time (mins)',
                ...triggerSuites.map((suite) => `${suite.startTimeString} (PT)`),
            ];
            worksheet.addRow(headers);

            // Sort suites by start time
            const sortedSuiteData = [...triggerSuites].sort((a, b) =>
                dayjs(a.startTime).diff(dayjs(b.startTime)),
            );

            // Initialize workflow time tracking
            const workflowTimeMap = {};

            // First pass: Calculate means
            sortedSuiteData.forEach((suite, suiteIndex) => {
                Object.keys(suite.runsByLastAttemptStatus).forEach((status) => {
                    suite.runsByLastAttemptStatus[status].forEach((workflow) => {
                        const workflowName = workflow.name.split(/:(.+)/)[1];

                        if (!workflowTimeMap[workflowName]) {
                            workflowTimeMap[workflowName] = {
                                name: workflowName,
                                times: new Array(sortedSuiteData.length + 2).fill(""),
                                values: [],
                            };
                            workflowTimeMap[workflowName].times[0] = workflowName;
                        }

                        workflowTimeMap[workflowName].values.push(workflow.uv);
                        workflowTimeMap[workflowName].times[suiteIndex + 2] = {
                            value: workflow.uv,
                            status: statusToHeaderMap(status),
                        };
                    });
                });
            });

            // Calculate means and prepare rows
            const workflowRows = Object.values(workflowTimeMap).map((workflow) => {
                const numericTimes = workflow.values.filter((v) => v !== undefined);
                const meanTime = numericTimes.length > 0
                    ? (numericTimes.reduce((sum, time) => sum + time, 0) / numericTimes.length).toFixed(2)
                    : "";

                workflow.times[1] = meanTime;  // Put mean in second column
                return {
                    workflowName: workflow.name,
                    row: workflow.times,
                    meanTime: parseFloat(meanTime) || 0,
                };
            });

            // Sort workflows by mean time in descending order
            workflowRows.sort((a, b) => b.meanTime - a.meanTime);

            // Add rows to the worksheet
            workflowRows.forEach(({ row }) => {
                worksheet.addRow(row);
            });

            // Apply styles
            applyWorksheetStyles(worksheet, sortedSuiteData);
        });

        // Generate and download the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${customerNameOrId} Workflow Times.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const applyWorksheetStyles = (worksheet, sortedSuiteData) => {
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell, colNumber) => {
                // Default cell style - always set black text
                cell.style = {
                    font: { name: 'Arial', size: 12, color: { argb: 'FF000000' } },
                    alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
                    border: {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    },
                };

                // Time value formatting and coloring for non-header, non-mean-time cells
                if (rowNumber > 1) {  // Now checking after single header row
                    if (colNumber > 2 && colNumber <= sortedSuiteData.length + 2) {  // Suite time columns
                        if (cell.value !== "" && cell.value.value !== undefined) {
                            const currentTime = parseFloat(cell.value.value);
                            const meanTime = parseFloat(row.getCell(2).value);  // Mean is in column 2

                            if (meanTime > 0) {
                                const percentDiff = ((currentTime - meanTime) / meanTime) * 100;
                                const fillColor = getRelativeTimeColor(percentDiff);

                                const formattedPercent = percentDiff >= 0 ? `+${percentDiff.toFixed(0)}` : percentDiff.toFixed(0);
                                cell.value = `${currentTime} (${formattedPercent}%)`;

                                cell.numFmt = '@';

                                cell.style = {
                                    ...cell.style,
                                    alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
                                    fill: {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: fillColor },
                                    },
                                    font: {
                                        name: 'Arial',
                                        size: 12,
                                        color: { argb: 'FF000000' },
                                    },
                                };
                            }
                        }
                    } else if (colNumber === 2) {  // Mean time column
                        if (cell.value !== "") {
                            cell.numFmt = '0.00';
                            cell.style = {
                                ...cell.style,
                                fill: {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFFFFFFF' },  // White background for mean column
                                },
                                font: {
                                    name: 'Arial',
                                    size: 12,
                                    color: { argb: 'FF000000' }, // Black text
                                },
                            };
                        }
                    }
                }
            });
        });

        // Set column widths
        worksheet.columns = [
            { width: 30 }, // Workflow name column
            ...Array(sortedSuiteData.length).fill({ width: 15 }), // Time columns
            { width: 20 }, // Mean Time column
        ];

        // Set row height
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.height = 60;
            }
        });

        // Freeze first row and column
        worksheet.views = [
            { state: 'frozen', xSplit: 1, ySplit: 1, topLeftCell: 'B2', activeCell: 'B2' },
        ];
    };

    // Function to get color based on percentage difference from mean
    const getRelativeTimeColor = (percentDiff) => {
        if (percentDiff < 0) return 'FFccffcc';      // Light Green (#ccffcc)
        if (percentDiff >= 50) return 'FFff6666';       // Red (#ff6666)
        if (percentDiff >= 10) return 'FFffd699';       // Light Orange (#ffd699)
        return 'FFffff99';                              // Light Yellow (#ffff99)
    };

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: '#2f2fc1',
                '&:hover': {
                    backgroundColor: '#1e1ea1',
                },
                marginLeft: '10px',
                padding: '6px 12px',
                fontSize: '0.8rem',
                minWidth: '160px',
            }}
            startIcon={<FileDownload />}
            onClick={downloadXlsx}
        >
            Export Workflow Times XLSX
        </Button>
    );
}

