import { useQueries, UseQueryResult } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useEffect } from 'react';
import { sendGetRequest } from '../../utils/tanstackNetwork';
import { ClientSummaryTableRow } from '../Planning/Summary/types';
import { StoredUser } from '../HowlUtilization/types';

export type StoredQaTeam = {
  id: number;
  name: string;
  qawId: string;
  email: string;
  teamName: string;
  packId: number;
  imageUrl: string;
  active: boolean;
  selectedInSettings: boolean;
};

export type QaTeamCapacity = {
  totalQaeHours: number;
  oooHours: number;
  avgInvestigationHours: number;
  hoursAvailable: number;
  avgTestsCreatedPerHour: number;
  avgMeetingHours: number;
  estCreationCapacity: number;
  testsCreatedThisWeek: number;
};

export type PlanningContextType = {
  user: StoredUser;
  qaTeams: StoredQaTeam[];
  selectedWeek: dayjs.Dayjs;
  setSelectedWeek: Dispatch<SetStateAction<dayjs.Dayjs>>;
  selectedTaskWeek: dayjs.Dayjs | null;
  setSelectedTaskWeek: Dispatch<SetStateAction<dayjs.Dayjs | null>>;
  selectedQaTeamIds: number[];
  setSelectedQaTeamIds: Dispatch<SetStateAction<number[]>>;
  allTeamsSelected: boolean;
  setAllTeamsSelected: Dispatch<SetStateAction<boolean>>;
  qaTeamCapacity: UseQueryResult<QaTeamCapacity>;
  clientSummaries: UseQueryResult<ClientSummaryTableRow[]>;
  totalScheduledForCurrentWeek: number;
  remainingCapacity: number;
  showApproveAllButton: boolean;
  isMoreThanOneQaTeamSelected: boolean;
};

const PlanningContext = createContext<PlanningContextType | object>({});

function getQueryParams(selectedQaTeamIds: number[], selectedWeek: dayjs.Dayjs) {
  const params = new URLSearchParams();
  selectedQaTeamIds.forEach((id) => params.append('qaTeamIds', id.toString()));
  params.append('selectedWeek', selectedWeek.format('YYYY-MM-DD'));
  return params.toString();
}

export function PlanningContextProvider({ children }: { children: ReactNode }) {
  const user = JSON.parse(localStorage.getItem('user') || '{}') as StoredUser;
  const qaTeams: StoredQaTeam[] = JSON.parse(localStorage.getItem('teams') || '[]').filter(
    (team: StoredQaTeam) => team.teamName !== 'TestTeam' && team.teamName !== 'Seals',
  );

  if (qaTeams.length === 0) {
    return <PlanningContext.Provider value={{}}>{children}</PlanningContext.Provider>;
  }

  const [selectedQaTeamIds, setSelectedQaTeamIds] = useState(() => {
    return qaTeams[0] ? [qaTeams[0].id] : [];
  });
  const [allTeamsSelected, setAllTeamsSelected] = useState(false);

  const [selectedWeek, setSelectedWeek] = useState(dayjs().startOf('week'));
  const [selectedTaskWeek, setSelectedTaskWeek] = useState(dayjs().startOf('week'));

  const [showApproveAllButton, setShowApproveAllButton] = useState(false);
  const [isMoreThanOneQaTeamSelected, setIsMoreThanOneQaTeamSelected] = useState(false);

  // When the selected week changes, update the selected task week
  useEffect(() => {
    setSelectedTaskWeek(selectedWeek);
  }, [selectedWeek]);

  
  const [qaTeamCapacity, clientSummaries] = useQueries({
    queries: [
      {
        queryKey: ['qaTeamCapacity', selectedWeek.format('YYYY-MM-DD')],
        queryFn: () => sendGetRequest(`/client-summaries/qa-team-capacity?${getQueryParams(selectedQaTeamIds, selectedTaskWeek)}`),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['clientSummaries', selectedWeek.format('YYYY-MM-DD')],
        queryFn: () => sendGetRequest(`/client-summaries?${getQueryParams(selectedQaTeamIds, selectedTaskWeek)}`),
        placeholderData: [],
        refetchOnWindowFocus: false,
      },
    ],
  });

  useEffect(() => {
    // Only show the approve all button if the user is the manager of all selected QA teams
    const selectedQaTeams = qaTeams.filter((team) => selectedQaTeamIds.includes(team.id));
    const isQaTeamManager = user.isManager && selectedQaTeams.every((team) => team.packId === user.teamId);
    
    // Disable the approva all button if more than one QA team is selected
    const isMoreThanOneTeamSelected = selectedQaTeamIds.length > 1;
    
    // When the selected QA team changes, update the showApproveAllButton state
    setShowApproveAllButton(isQaTeamManager);
    setIsMoreThanOneQaTeamSelected(isMoreThanOneTeamSelected);

    // Refetch the client summaries
    clientSummaries.refetch();
  }, [selectedQaTeamIds]);

  const capacityData = qaTeamCapacity.data;
  const clientSummaryData = clientSummaries.data;

  const totalScheduledForCurrentWeek =
    clientSummaryData?.reduce((acc: number, curr: ClientSummaryTableRow) => acc + curr.testsScheduledForCurrentWeek, 0) || 0;
  const remainingCapacity = (capacityData?.estCreationCapacity || 0) - (totalScheduledForCurrentWeek || 0);

  return (
    <PlanningContext.Provider
      value={{
        user,
        qaTeams,
        selectedWeek,
        setSelectedWeek,
        selectedTaskWeek,
        setSelectedTaskWeek,
        selectedQaTeamIds,
        setSelectedQaTeamIds,
        allTeamsSelected,
        setAllTeamsSelected,
        qaTeamCapacity,
        clientSummaries,
        totalScheduledForCurrentWeek,
        remainingCapacity,
        showApproveAllButton,
        isMoreThanOneQaTeamSelected,
      }}
    >
      {children}
    </PlanningContext.Provider>
  );
}

export function usePlanningContext() {
  return useContext(PlanningContext) as PlanningContextType;
}
