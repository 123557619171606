
const Thread = ({ thread, handleThreadSelection, isSelected, idx }) => {

    return (
        <div
            onClick={() => handleThreadSelection(thread)}
            className={`thread-tab ${isSelected ? 'selected' : ''}`}
        >
            {idx + 1}
        </div>
    );
};

export default Thread;
