import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DatePicker } from '@mui/x-date-pickers';
import fetchSuiteDataByTime from './fetchSuiteDataByTime';
import WolfLoader from '../WolfLoader/WolfLoader';
import SuiteMetricsBoxPlot from './Charts/SuiteMetricsBoxPlot';
import SuiteRunTimeTrendPlot from './Charts/SuiteRunTimeTrendLine';
import SuiteStatusPieChart from './Charts/SuiteStatusPieChart';
import PercentageTrendLine from './Charts/PercentageTrendLine';
import PercentageBoxPlot from './Charts/PercentageBoxPlot';
import SuiteWorkflowStatusTable from './Charts/SuiteWorkflowStatusTable';
import ExportSuitesTableToCsvButton from './ExportButtons/ExportSuiteRunsTableData';
import ExportSuitesDataToCsvButton from './ExportButtons/ExportSuiteRunsDetailsData';
import ExportSuitesTableXLSXButton from './ExportButtons/ExportSuiteRunsTableXLSX';
import ExportFlakinessReport from './ExportButtons/ExportFlakinessReport';
import ExportWorkflowTimesXLSX from './ExportButtons/ExportWorkflowTimesXLSX';
import FlakeTrendLineByTrigger from './Charts/FlakeTrendLineByTrigger';
import WorkflowFlakeChart from './Charts/WorkflowFlakeChart';

export default function StreamExplorer() {
  const { teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTriggersFromURL = searchParams.get('triggers') ? new Set(searchParams.get('triggers').split(',')) : new Set();
  const startDateFromURL = searchParams.get('startDate') || dayjs().subtract(30, 'days').toISOString();
  const endDateFromURL = searchParams.get('endDate') || dayjs().toISOString();

  // Sets state from URL
  const [selectedTriggers, setSelectedTriggers] = useState(selectedTriggersFromURL);
  const [filters, setFilters] = useState({
    startDate: startDateFromURL,
    endDate: endDateFromURL,
  });

  // For opening and closing the table
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isFlakeChartOpen, setIsFlakeChartOpen] = useState(false);
  const [suiteData, setSuiteData] = useState([]);

  // Fetch data
  const { data, isPending, isError, error } = useQuery({
    queryKey: ['suiteDataByTime', teamId, dayjs(filters.startDate).subtract(7, 'hours').toISOString(), filters.endDate],
    queryFn: () => fetchSuiteDataByTime(teamId, dayjs(filters.startDate).subtract(7, 'hours').toISOString(), filters.endDate),
  });

  useEffect(() => {
    if (data) {
      adjustSuiteTimes(data);
    }
  }, [data]);

  // Function to adjust times of the suites
  // Function adds key for supersededSuiteTime and one for CI/CD delta
  const adjustSuiteTimes = (suites) => {
    const sortedSuites = [...suites].sort((a, b) => dayjs(b.startTime).unix() - dayjs(a.startTime).unix());
    const suiteMap = new Map(sortedSuites.map((suite) => [suite.id, suite]));

    sortedSuites.forEach((suite) => {
      // Initialize the new key with the same value as totalTimeInMinutes
      suite.supersededTotalTimeInMinutes = suite.totalTimeInMinutes;

      if (suite.supersededSuiteIds.length > 0) {
        const supersedingSuiteId = suite.supersededSuiteIds[0]; // Only take the first ID
        const supersedingSuite = suiteMap.get(supersedingSuiteId);
        if (supersedingSuite) {
          suite.supersededTotalTimeInMinutes += supersedingSuite.totalTimeInMinutes;
        }
      }

      suite.ciCdOverhead = suite.supersededTotalTimeInMinutes - suite.totalTimeInMinutes;
    });

    setSuiteData(sortedSuites);
  };

  // Collect all unique trigger names from the suiteData
  const allTriggerNames = new Set();
  const prDeploymentTriggers = [];

  suiteData?.forEach((suite) => {
    const triggerName = suite.triggerName?.toLowerCase();
    if (triggerName?.startsWith('pr deployment') || triggerName?.startsWith('pr test')) {
      prDeploymentTriggers.push(suite.triggerName);
    } else if (suite.triggerName) {
      // Only add non-empty trigger names
      allTriggerNames.add(suite.triggerName);
    }
  });

  // Add aggregated "PR Deployments" option if there are any PR Deployment triggers
  if (prDeploymentTriggers.length > 0) {
    allTriggerNames.add('PR Deployments');
  }

  // Add 'Manual' option
  allTriggerNames.add('Manual');

  // New state for the "Remove Cancelled / Superseded Suites" switch
  const [removeCancelledSuperseded, setRemoveCancelledSuperseded] = useState(false);

  // Filter suiteData based on selected trigger names and the new switch state
  const filteredSuiteData = suiteData?.filter((suite) => {
    if (selectedTriggers.has('PR Deployments')) {
      if (suite.triggerName.toLowerCase().startsWith('pr deploy') || suite.triggerName?.toLowerCase().startsWith('pr test')) {
        return true;
      }
    }
    const isManual = selectedTriggers.has(suite.triggerName || 'Manual');
    const shouldRemove =
      removeCancelledSuperseded &&
      (suite.isCanceled || suite.isMovedToNewerSuite || suite.isSuperseded || suite.numberOfSupersededRuns > 1 || suite.numberOfRunsCanceled > 1);
    return isManual && !shouldRemove;
  });

  // Determine the customer's official name for display
  const customerNameOrId = suiteData?.[0]?.customer?.officialName || teamId;

  // Update the URL when filters or selected triggers change
  useEffect(() => {
    const params = {};
    if (selectedTriggers.size > 0) {
      params.triggers = Array.from(selectedTriggers).join(',');
    }
    params.startDate = filters.startDate;
    params.endDate = filters.endDate;
    // @ts-ignore
    setSearchParams(params);
  }, [selectedTriggers, filters, setSearchParams]);

  // Update triggers based on  url params
  useEffect(() => {
    if (suiteData) {
      const urlTriggers = searchParams.get('triggers');
      let initialTriggers = new Set();

      if (urlTriggers) {
        initialTriggers = new Set(urlTriggers.split(','));
      } else {
        const allTriggersFromData = new Set(suiteData.map((suite) => suite.triggerName).filter(Boolean));
        initialTriggers = new Set(allTriggersFromData); // default to all non-manual triggers

        // If there are PR Deployment triggers, they should be unchecked by default
        if (prDeploymentTriggers.length > 0) {
          initialTriggers.add('PR Deployments');
        }
      }

      setSelectedTriggers(initialTriggers);
    }
  }, [suiteData, searchParams]);

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  // Helper function to toggle checkbox state
  const handleCheckboxChange = (event) => {
    const triggerName = event.target.name;
    const newSelectedTriggers = new Set(selectedTriggers);

    if (triggerName === 'PR Deployments') {
      if (newSelectedTriggers.has('PR Deployments')) {
        // Remove all PR Deployment triggers
        newSelectedTriggers.delete('PR Deployments');
        prDeploymentTriggers.forEach((name) => newSelectedTriggers.delete(name));
      } else {
        // Add all PR Deployment triggers
        newSelectedTriggers.add('PR Deployments');
        prDeploymentTriggers.forEach((name) => newSelectedTriggers.add(name));
      }
    } else {
      if (newSelectedTriggers.has(triggerName)) {
        newSelectedTriggers.delete(triggerName);
      } else {
        newSelectedTriggers.add(triggerName);
      }
    }

    setSelectedTriggers(newSelectedTriggers);
  };

  return (
    <Grid container spacing={2} sx={{ padding: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          {customerNameOrId}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginBottom: 2 }}>
          {Array.from(allTriggerNames).map((triggerName) => (
            <FormControlLabel
              key={triggerName}
              control={
                <Checkbox
                  checked={selectedTriggers.has(triggerName)}
                  onChange={handleCheckboxChange}
                  name={triggerName}
                  sx={{
                    color: '#2f2fc1', // color when not checked
                    '&.Mui-checked': {
                      color: '#2f2fc1', // color when checked
                    },
                  }}
                />
              }
              label={triggerName}
              sx={{ margin: 0 }}
            />
          ))}
        </Box>
        <Box sx={{ maxWidth: '650px' }}>
          <Grid container spacing={1}>
            {['Start Date *', 'End Date *'].map((dateType, index) => {
              const dateParam = dateType.includes('Start') ? 'startDate' : 'endDate';
              return (
                <Grid item key={index} xs={6} sm={3}>
                  <DatePicker
                    value={dayjs(filters[dateParam])}
                    onChange={(newDate) => setFilters((prev) => ({ ...prev, [dateParam]: dayjs(newDate).toISOString() }))}
                    label={dateType}
                    disableFuture
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={removeCancelledSuperseded}
                    onChange={(e) => setRemoveCancelledSuperseded(e.target.checked)}
                    sx={{
                      color: '#2f2fc1', // color when not checked
                      '&.Mui-checked': {
                        color: '#2f2fc1', // color when checked
                      },
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#2f2fc1', // color of the switch thumb when checked
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#2f2fc1', // color of the switch track when checked
                      },
                    }}
                  />
                }
                label="Remove Cancelled / Superseded Suites"
                sx={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography variant="h6" gutterBottom component="div" sx={{ marginTop: 2 }}>
          Total Suites: {filteredSuiteData.length}
        </Typography>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row', // Horizontal layout for the first two buttons
              alignItems: 'center',
            }}
          >
            <ExportSuitesTableXLSXButton filteredSuiteData={filteredSuiteData} customerNameOrId={customerNameOrId} />
            <ExportFlakinessReport filteredSuiteData={filteredSuiteData} customerNameOrId={customerNameOrId} />
            <ExportSuitesTableToCsvButton filteredSuiteData={filteredSuiteData} customerNameOrId={customerNameOrId} />
            <ExportSuitesDataToCsvButton filteredSuiteData={filteredSuiteData} customerNameOrId={customerNameOrId} />
            <ExportWorkflowTimesXLSX filteredSuiteData={filteredSuiteData} customerNameOrId={customerNameOrId} />
          </Box>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFB300',
              color: '#fff', // white text
              '&:hover': {
                backgroundColor: '#FFCB05',
              },
              marginTop: '10px', // Space above this button to separate it from the others
            }}
            endIcon={<HelpOutlineIcon />}
            onClick={() => window.open('https://www.notion.so/qawolf/Stream-Explorer-Definitions-ef1153900a8d4bf7a1e47e6b37492850', '_blank')}
          >
            Stream Explorer Definitions
          </Button>
        </Box>
      </Grid>
      {filteredSuiteData &&
        ['Suite Metrics Box Plot', 'Runtime and Investigation Time Trends', 'Flake / Change Rates', 'Suite Status Distribution'].map(
          (title, index) => (
            <Grid item xs={12} key={title}>
              <Card raised sx={{ display: 'flex', flexDirection: 'column', minHeight: 500, color: 'common.white' }}>
                <CardHeader title={title} sx={{ bgcolor: '#2f2fc1', color: 'common.white' }} />
                <CardContent sx={{ flexGrow: 1 }}>
                  {index === 0 && (
                    <SuiteMetricsBoxPlot
                      suiteData={filteredSuiteData}
                      metrics={['runTimeInMinutes', 'triageTimeInMinutes', 'totalTimeInMinutes', 'supersededTotalTimeInMinutes']}
                      title={'Suite Time Boxplot'}
                    />
                  )}
                  {index === 0 && (
                    <SuiteMetricsBoxPlot
                      suiteData={filteredSuiteData}
                      metrics={['minutesUntilTriageStarted', 'minutesUntilFirstQaeAttempt']}
                      title={'Time For Investigation Boxplot'}
                    />
                  )}
                  {index === 1 && (
                    <SuiteRunTimeTrendPlot
                      suiteData={filteredSuiteData}
                      minuteMetrics={[
                        'runTimeInMinutes',
                        'triageTimeInMinutes',
                        'totalTimeInMinutes',
                        'supersededTotalTimeInMinutes',
                        'minutesUntilFirstQaeAttempt',
                        'minutesUntilTriageStarted',
                      ]}
                      countMetrics={['numberOfRunsTriagedAsBug', 'numberOfRunsTriagedAsMaintenance', 'numberOfRunsPassingOnQaeFix']}
                      title={title}
                    />
                  )}
                  {index === 2 && (
                    <PercentageTrendLine
                      suiteData={filteredSuiteData}
                      metrics={['flakePercent', 'successPercent', 'changeRate', 'deterministicTestsPercent']}
                      title={title}
                    />
                  )}
                  {index === 2 && <FlakeTrendLineByTrigger suiteData={filteredSuiteData} />}
                  {index === 2 && (
                    <PercentageBoxPlot
                      suiteData={filteredSuiteData}
                      metrics={['successPercent', 'changeRate', 'deterministicTestsPercent', 'flakePercent']}
                      title={'Suite Percentages Boxplots'}
                    />
                  )}
                  {index === 2 && (
                    <PercentageBoxPlot
                      suiteData={filteredSuiteData}
                      metrics={['numberOfRunsTriagedAsBug', 'numberOfRunsTriagedAsMaintenance', 'numberOfRunsPassingOnQaeFix', 'changeRate']}
                      title={'Change Rate Breakdown'}
                    />
                  )}
                  {index === 3 && <SuiteStatusPieChart suiteData={filteredSuiteData} />}
                </CardContent>
              </Card>
            </Grid>
          ),
        )}
      <Grid item xs={12}>
        <Accordion
          expanded={isPanelOpen}
          onChange={() => setIsPanelOpen(!isPanelOpen)}
          sx={{ bgcolor: '#2f2fc1', color: 'common.white', boxShadow: 2 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography sx={{ flexShrink: 0, fontWeight: 'bold', fontSize: '1.25rem' }}>Workflow Status Overview</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '90vh', overflow: 'auto', overflowY: 'auto', overflowX: 'scroll' }}>
            {isPanelOpen && <SuiteWorkflowStatusTable filteredSuiteData={filteredSuiteData} />}
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion
          expanded={isFlakeChartOpen}
          onChange={() => setIsFlakeChartOpen(!isFlakeChartOpen)}
          sx={{ bgcolor: '#2f2fc1', color: 'common.white', boxShadow: 2, mt: 2 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} aria-controls="panel2bh-content" id="panel2bh-header">
            <Typography sx={{ flexShrink: 0, fontWeight: 'bold', fontSize: '1.25rem' }}>Workflow Flakiness Trends</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '90vh', overflow: 'auto', overflowY: 'auto', overflowX: 'scroll' }}>
            {isFlakeChartOpen && <WorkflowFlakeChart filteredSuiteData={filteredSuiteData} />}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
