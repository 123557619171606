import '../../../css/gantt.css';
import ClientSummaryTable from './ClientSummaryTable/ClientSummaryTable';
import { Box, Typography } from '@mui/material';
import { QaTeamSelector } from './QaTeamSelector';
import { TeamCapacityPanel } from './TeamCapacityPanel';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
import { usePlanningContext } from '../../context/PlanningContext';

export default function Summary() {
  const { selectedWeek, setSelectedWeek } = usePlanningContext();

  return (
    <Box>
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">Planning Hub</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <DatePicker
              value={selectedWeek}
              onChange={(e) => setSelectedWeek(e?.startOf('week') || dayjs())}
              label="Select Week"
              format="MMM D - MMM D"
              showDaysOutsideCurrentMonth
              slotProps={{
                actionBar: {
                  actions: ['today'],
                },
                textField: {
                  InputProps: {
                    value: `${selectedWeek.format('MMM D')} - ${selectedWeek.endOf('week').format('MMM D')}`,
                  },
                },
              }}
            />
          </Box>
          <QaTeamSelector />
        </Box>
      </Box>
      <TeamCapacityPanel />
      <ClientSummaryTable />
    </Box>
  );
}
