import { Card, CardContent, Typography, Box, List, ListItem } from '@mui/material';
import { plural } from '../InvestigationBoard/helpers';
import { HowlUtilizationData } from './types';

export function CompetitionLeaderboard({ data }: { data: HowlUtilizationData }) {
  return (
    <Card sx={{ height: '100%', px: 3, py: 2, pb: { xs: 0, xl: 3 } }} variant="outlined" square>
      <CardContent sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', p: 0 }}>
        {/* Leaderboard */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Top Users Section */}
          <Box sx={{ display: 'flex' }}>
            {/* Top User By Active Test Count */}
            <Box sx={{ flex: 1, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ fontSize: { xs: 16, xl: 18 } }}>
                Top Active Test Count:
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ fontSize: '2em', mr: 1 }}>🥇</Box>
                <Box>
                  <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                    {data.topUserByTestCount.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${data.topUserByTestCount.uniqueActiveTestCount} test${plural(data.topUserByTestCount.uniqueActiveTestCount)} activated | ${
                      data.topUserByTestCount.howlUtilizations.length
                    } use${plural(data.topUserByTestCount.howlUtilizations.length)}`}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Top User By Howl Uses */}
            <Box sx={{ flex: 1, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ fontSize: { xs: 16, xl: 18 } }}>
                Top Howl Uses:
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ fontSize: '2em', mr: 1 }}>🥈</Box>
                <Box>
                  <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                    {data.topUserByUses.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${data.topUserByUses.uniqueActiveTestCount} test${plural(data.topUserByUses.uniqueActiveTestCount)} activated | ${
                      data.topUserByUses.howlUtilizations.length
                    } use${plural(data.topUserByUses.howlUtilizations.length)}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Per Pack Section */}
          <Box sx={{ display: 'flex' }}>
            {/* Top Active Tests Per Pack */}
            <Box sx={{ flex: 1, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ fontSize: { xs: 16, xl: 18 } }}>
                Top Active Tests Per Pack:
              </Typography>
              <List dense>
                {data.topUsersByTestCountByPack
                  .sort((a, b) => {
                    if (!b.team?.pack) return -1;
                    if (!a.team?.pack) return 1;
                    return a.team.pack.name.localeCompare(b.team.pack.name);
                  })
                  .map((user) => {
                    const testCount = user.uniqueActiveTestCount;
                    const useCount = user.howlUtilizations.length;
                    const emoji = testCount > 0 ? '🥉' : '🫥';
                    const topString = testCount > 0 ? `${user.name}` : 'No one has attempted to claim this prize yet!';
                    const bottomString = testCount > 0 ? `${testCount} test${plural(testCount)} activated | ${useCount} use${plural(useCount)}` : '';

                    return (
                      <ListItem disableGutters key={user.id}>
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                            {user.team?.pack?.name || 'Other'}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Box sx={{ fontSize: '2em', mr: 1, mt: -1 }}>{emoji}</Box>
                            <Box>
                              <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                                {topString}
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {bottomString}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>

            {/* Top Howl Uses Per Pack */}
            <Box sx={{ flex: 1, borderRadius: 1 }}>
              <Typography variant="h6" sx={{ fontSize: { xs: 16, xl: 18 } }}>
                Top Howl Uses Per Pack:
              </Typography>
              <List dense>
                {data.topUsersByUsesByPack
                  .sort((a, b) => {
                    if (!b.team?.pack) return -1;
                    if (!a.team?.pack) return 1;
                    return a.team.pack.name.localeCompare(b.team.pack.name);
                  })
                  .map((user) => {
                    const useCount = user.howlUtilizations.length;
                    const testCount = user.uniqueActiveTestCount;
                    const emoji = useCount > 0 ? '🎗️' : '🫥';
                    const topString = useCount > 0 ? `${user.name}` : 'No one has attempted to claim this prize yet!';
                    const bottomString = useCount > 0 ? `${testCount} test${plural(testCount)} activated | ${useCount} use${plural(useCount)}` : '';

                    return (
                      <ListItem disableGutters key={user.id}>
                        <Box sx={{ width: '100%' }}>
                          <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                            {user.team?.pack?.name || 'Other'}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Box sx={{ fontSize: '2em', mr: 1, mt: -1 }}>{emoji}</Box>
                            <Box>
                              <Typography variant="body1" sx={{ fontSize: { xs: 14, xl: 16 } }}>
                                {topString}
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {bottomString}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </Box>
        </Box>

        {/* Note */}
        <Box sx={{ height: '100%', justifyContent: 'flex-end', flexDirection: 'column', display: { xs: 'none', xl: 'flex' } }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            <strong style={{ fontWeight: 'bold', color: 'black' }}>Note:</strong> Participants can only win one prize category. Active test count
            tiebreakers are determined by whoever has more recorded uses, usage tiebreakers are determined by whoever has more active tests.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
