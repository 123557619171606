import _ from 'lodash';
import { useState } from 'react';
import dayjs from 'dayjs';

import { Box, FormControl, FormControlLabel, Slider, Switch, TextField } from '@mui/material';
import { CustomCheckbox } from '../../UtilityComponents';

/**
 *
 * @param {object} props
 * @param {import('../../InsightsExplorer/types').QAEFromLocalStorage[]} props.qaes
 * @returns {import('react').JSX.Element}
 */
export default function TierCardForm({ qaes }) {
  // warn for stale local storage rather than throwing
  if (!qaes.every((qae) => qae.position)) {
    return (
      <div className="flex flex-col justify-center items-center">
        <p className="text-red-600 font-semibold">Error: some QAEs do not have a position or start date.</p>
        <p className="italic">Please log out and back in to reset your local storage</p>
      </div>
    );
  }

  // handle qae level selection & card name state
  const allPositions = _.uniq(qaes.map((qae) => qae.position));
  const [selectedPositions, setSelectedPositions] = useState(allPositions);
  const [cardName, setCardName] = useState('');
  const [sliderValue, setSliderValue] = useState([0, 3]);

  // handle slider value change
  const handleSliderChange = (_, newValue) => {
    setCardName(`${newValue[0]} to ${newValue[1]} Months`);
    setSliderValue(newValue);
  };

  // handle position selection change
  const handlePositionChange = (position) => {
    setSelectedPositions((prevSelectedPositions) =>
      prevSelectedPositions.includes(position) ? prevSelectedPositions.filter((pos) => pos !== position) : [...prevSelectedPositions, position],
    );
  };

  // get longest tenure months
  const longestTenureQAE = _.minBy(qaes, 'startDate');
  const longestTenureInMonths = Math.ceil(dayjs().diff(dayjs(longestTenureQAE.startDate), 'month', true));

  // find max
  return (
    <div className="mt-2 flex justify-center">
      <FormControl sx={{ m: 1, width: '92%' }}>
        <Box>
          <div className="flex justify-center mb-4">
            {allPositions.map((position) => (
              <FormControlLabel
                key={position}
                control={<CustomCheckbox checked={selectedPositions.includes(position)} onChange={() => handlePositionChange(position)} />}
                label={position}
                value={selectedPositions.join(',')}
                name="positions"
              />
            ))}
          </div>
          <Slider
            getAriaLabel={() => 'Months'}
            value={[...sliderValue]}
            getAriaValueText={(value) => `${value} Months`}
            valueLabelFormat={(value) => `${value} Months`}
            valueLabelDisplay="auto"
            shiftStep={1}
            step={1}
            marks
            min={0}
            max={longestTenureInMonths}
            onChange={handleSliderChange}
          />
          <input type="hidden" name="minMonths" value={sliderValue[0]} />
          <input type="hidden" name="maxMonths" value={sliderValue[1]} />
          <div className="flex justify-between items-end">
            <FormControlLabel control={<Switch name={`includeIndividualQAEs`} value={true} />} label="Include Individual QAEs" />
            <TextField
              style={{ width: '50%' }}
              required
              margin="dense"
              id="name"
              name="cardName"
              label="Card Name"
              type="text"
              variant="standard"
              value={cardName ? cardName : '0 to 3 Months'}
              onChange={(event) => setCardName(event.target.value)}
            />
          </div>
        </Box>
      </FormControl>
    </div>
  );
}
