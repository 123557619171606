import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CartesianGrid, Legend, LegendProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import LegendForCreationOutlineMaintenanceTaskCharts from './LegendForCreationOutlineMaintenanceTaskCharts';
import CustomTooltip from './CustomTooltip';

import { stringToColor } from '../../../utils/colorUtils';
import { hslStringToRgbString } from '../../../utils/colorUtils';

import { TeamCreationOutlineMaintenanceTasksInsights, WeeklyTaskBreakdown } from '../types';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { WOLF_LOGO } from '../../../constants';
import { DataKey } from 'recharts/types/util/types';

type ChartData = {
  name: string;
  [key: string]: number | string;
};

/**
 * Builds chart data from insights
 */
const buildChartDataFromInsights = (insights: _.Dictionary<WeeklyTaskBreakdown[]>, weeks: string[], prefix: string): ChartData[] => {
  const chartData: ChartData[] = [];

  for (const week of weeks) {
    const weekObj: ChartData = { name: week };

    const maintenanceData = insights[week]?.filter((weeklyBreakdown) => weeklyBreakdown.type === 'testMaintenance');

    const sumTasks = _.sumBy(maintenanceData, (weeklyBreakdown) => parseFloat(weeklyBreakdown['total_tasks'])) || 0;

    const sumTests = _.sumBy(maintenanceData, (weeklyBreakdown) => parseFloat(weeklyBreakdown['total_steps'])) || 0;

    const prefixLabel = prefix ? `${prefix} |` : '';
    weekObj[`${prefixLabel} Maintenance - Tasks`] = sumTasks;
    weekObj[`${prefixLabel} Maintenance - Tests`] = sumTests;

    chartData.push(weekObj);
  }

  return chartData;
};

/**
 * Adds team averages to chart data
 */
const addAveragesToChartData = (chartData: ChartData[], denominator: number) => {
  for (const week of chartData) {
    for (const key of Object.keys(week)) {
      if (key === 'name') continue;
      week[`${key} | Average`] = (Number(week[key]) / denominator).toFixed(2) || 0;
    }
  }
};

/**
 * Merges weekly data
 */
const mergeWeeklyData = (data: Record<string, ChartData[]>) => {
  const mergedData: Record<string, ChartData> = {};

  // Iterate over each key in the data
  for (const key of Object.keys(data)) {
    data[key].forEach((week) => {
      const weekName = week.name;

      // If the week name is not already in mergedData, initialize it
      if (!mergedData[weekName]) {
        mergedData[weekName] = { name: weekName };
      }

      // Add the data from the current key to the corresponding week in mergedData
      for (const insight in week) {
        if (insight !== 'name') {
          mergedData[weekName][insight] = week[insight];
        }
      }
    });
  }

  // Convert the mergedData object to an array
  return Object.values(mergedData);
};

export default function WeeklyMaintenanceChart({ data }: { data: TeamCreationOutlineMaintenanceTasksInsights }) {
  if (!data) return <div>No data provided for maintenance chart</div>;

  // determine the name of the QA lead to user for sorting
  const leadName = data.team?.members?.find((member) => member.isLead)?.name ?? '';

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  // isolate maintenance data
  const maintenanceDataWeeklyBreakdowns = data.teamTasksByWeekAndType;

  // gather images for legend
  const images =
    data.team.members?.reduce((acc: Record<string, string>, member) => {
      acc[member.name] = member.avatar48 ?? WOLF_LOGO;
      return acc;
    }, {}) ?? {};
  images['Team'] = data.team.imageUrl ?? WOLF_LOGO;

  // get all unique weeks regardless of if there is data for an individual on that week
  const allWeeks = _.uniq(maintenanceDataWeeklyBreakdowns.map((weeklyBreakdown) => weeklyBreakdown['Week Number']));

  // group data by qae name
  const groupedMaintenanceData: Record<string, ChartData[]> = {};

  // sub-group data by week number & build individual chart data for each qae
  Object.entries(_.groupBy(maintenanceDataWeeklyBreakdowns, 'name')).forEach(([name, data]) => {
    const groupedByWeek = _.groupBy(data, 'Week Number');
    groupedMaintenanceData[name] = buildChartDataFromInsights(groupedByWeek, allWeeks, name);
  });

  const teamGroupedByWeek = _.groupBy(maintenanceDataWeeklyBreakdowns, 'Week Number');
  groupedMaintenanceData['Team'] = buildChartDataFromInsights(teamGroupedByWeek, allWeeks, 'Team');

  addAveragesToChartData(groupedMaintenanceData['Team'], data.team?.members?.length ?? 0);

  // merge data
  const chartData = mergeWeeklyData(groupedMaintenanceData);

  // sort by week
  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  // set visibility state with already manipulated data
  const visibleLines = paramsMap.visibleLines ? paramsMap.visibleLines.split(',') : [];
  const sessionVisibleLines = JSON.parse(sessionStorage.getItem('teamMaintenanceChartVisibleLines') || '{}');
  const isPreviousValidLines = visibleLines.length > 0 && visibleLines.some((line) => sortedChartData[0][line]);

  const [lineVisibility, setLineVisibility] = useState(
    isPreviousValidLines
      ? _.mapValues(sortedChartData[0], (_, key) => visibleLines.includes(key))
      : Object.keys(sessionVisibleLines).length > 0
      ? sessionVisibleLines
      : _.mapValues(sortedChartData[0], (_, key) => key.includes('Maintenance - Tests')),
  );

  const [lineOpacity, setLineOpacity] = useState(_.mapValues(sortedChartData[0], () => 1));

  const renderLegend = (props: LegendProps) => {
    return (
      <LegendForCreationOutlineMaintenanceTaskCharts
        payload={props.payload as unknown as (Payload & { dataKey: DataKey<string> })[]}
        leadName={leadName}
        images={images}
        visibility={lineVisibility}
        clickFunction={handleLegendClick}
        enterFunction={handleLegendEnter}
        leaveFunction={handleLegendLeave}
      />
    );
  };

  const handleLegendEnter = useCallback(
    (payload: Payload & { dataKey: DataKey<string> }) => {
      const { dataKey } = payload;

      // if the dataKey is not visible, do not change opacity
      if (!lineVisibility[dataKey as string]) return;

      setLineOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = key === dataKey ? 1.5 : 0.3;
        });
        return newOpacity;
      });
    },
    [lineVisibility],
  );

  /**
   * Handles legend item leave
   */
  const handleLegendLeave = useCallback(
    (payload: Payload & { dataKey: DataKey<string> }) => {
      const { dataKey } = payload;

      // if the dataKey is not visible, do not change opacity
      if (!lineVisibility[dataKey as string]) return;

      setLineOpacity((previousLineOpacity) => {
        const newOpacity = { ...previousLineOpacity };
        Object.keys(previousLineOpacity).forEach((key) => {
          newOpacity[key] = 1;
        });
        return newOpacity;
      });
    },
    [lineVisibility],
  );

  /**
   * Handles legend item click
   */
  const handleLegendClick = useCallback((payload: Payload & { dataKey: DataKey<string> }) => {
    const { dataKey } = payload;

    setLineVisibility((previousLineVisibility: Record<string, boolean>) => {
      const newLineVisibility = { ...previousLineVisibility, [dataKey as string]: !previousLineVisibility[dataKey as string] };
      sessionStorage.setItem('teamMaintenanceChartVisibleLines', JSON.stringify(newLineVisibility));
      return newLineVisibility;
    });
  }, []);

  useEffect(() => {
    const visibleLines = Object.keys(lineVisibility)
      .filter((lineVisibilityKey) => lineVisibility[lineVisibilityKey])
      .join(',');
    searchParams.set('visibleLines', visibleLines);
    setSearchParams(searchParams);
  }, [lineVisibility]);

  return (
    <div className="flex-col items-center justify-center border">
      <ResponsiveContainer width="100%" height={1000}>
        <LineChart height={600} data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickMargin={5} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ textAlign: 'center', width: '100%' }} content={renderLegend as unknown as React.ReactElement<LegendProps>} />
          {Object.keys(sortedChartData).length &&
            Object.keys(sortedChartData[0]).map((line, lineIdx) => {
              if (line === 'name') return null;
              return (
                <Line
                  key={lineIdx}
                  type="monotone"
                  dataKey={line}
                  dot={false}
                  stroke={hslStringToRgbString(stringToColor(line))}
                  strokeOpacity={line.includes('Team') ? 1 : lineOpacity[line]}
                  strokeWidth={line.includes('Team') ? 4 : lineOpacity[line] === 1.5 ? 3 : 1}
                  hide={!lineVisibility[line]}
                />
              );
            })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
