import _ from 'lodash';
import { useEffect, useRef, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

import { getDisplayName } from '../helpers';
import { hslStringToRgbString, stringToColor } from '../../../utils/colorUtils';
import { roundToTwo } from '../../InsightsPlayground/helpers';
import { classNames } from '../../InvestigationBoard/helpers';

export default function WeeklyInvestigationChart({ data }) {
  // Get lead name and team name to use for sorting
  const leadName = data.team.members.find((m) => m.isLead).name || '';
  const teamName = data.team.name;

  // handle state and refs for re-sizing chart
  const parentRef = useRef(null);
  const [width, setWidth] = useState(0);

  const legendRef = useRef(null);
  const [legendHeight, setLegendHeight] = useState(0);

  // get and parse search params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsMap = Object.fromEntries([...searchParams]);

  const renderLegend = (props) => {
    const { payload } = props;
    let legendGroups = _.groupBy(payload, (entry) => entry.value.split(' | ')[0]);

    // sort legend groups data
    Object.keys(legendGroups).forEach((g) => {
      legendGroups[g].sort((a, b) => {
        const keyA = a.dataKey.split(' | ')[1].trim();
        const keyB = b.dataKey.split(' | ')[1].trim();
        return keyA.localeCompare(keyB);
      });
    });

    return (
      <div className="flex" ref={legendRef}>
        <div className="w-full flex flex-wrap justify-start gap-2 p-2 text-sm">
          {Object.keys(legendGroups)
            .sort((a, b) => {
              if (a === teamName) return 1; // Put team legend card at the end
              if (b === teamName) return -1;

              if (a === leadName) return 1; // Put lead legend card second to last
              if (b === leadName) return -1;

              return a.localeCompare(b);
            })
            .map((group, groupIdx) => {
              const groupEntries = legendGroups[group];
              const displayName = getDisplayName(group);
              // determine image based on data type
              return (
                <div className="flex flex-col shadow p-2 min-w-max max-w-max" key={groupIdx}>
                  <p className="flex items-center gap-x-4">
                    <img className="rounded-full h-10 w-auto" src={images[group]} alt="avatar" />
                    <span className="font-semibold">{displayName}</span>
                  </p>
                  <ul>
                    {groupEntries.map((entry, entryIdx) => {
                      return (
                        <li key={entryIdx} className={classNames('flex justify-start my-1 truncate', !visibility[entry.value] && 'text-gray-400')}>
                          <button
                            className="flex items-center"
                            onClick={() => handleLegendClick(entry)}
                            onMouseEnter={() => handleLegendEnter(entry)}
                            onMouseLeave={() => handleLegendLeave(entry)}
                          >
                            <span
                              className="h-3.5 w-3.5 mr-2 rounded-full"
                              style={{ backgroundColor: visibility[entry.value] ? entry.color : '#ccc' }}
                            />
                            <span className="truncate">{entry.value.match(/^[^|]*\| (.*)/)[1]}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  /** @type {import('../types').QAELegendMap} */
  const qaeLegendMap = {
    numberOfTestsInvestigated: (name) => `${name} | Number of Tests Investigated`,
    numberOfSuitesInvestigated: (name) => `${name} | Number of Suites Investigated`,
    numberOfRunsInvestigated: (name) => `${name} | Number of Runs Investigated`,
    numberOfRunsPassingOnFix: (name) => `${name} | Number of Runs Passing on Fix`,
    numberOfRunsPassingOnFlake: (name) => `${name} | Number of Runs Passing on Flake`,
    numberOfRunsReportedAsBugs: (name) => `${name} | Number of Runs Reported as Bugs`,
    numberOfRunsReportedAsMaintenance: (name) => `${name} | Number of Runs Reported as Maintenance`,
    numberOfRunsWithDoNotInvestigate: (name) => `${name} | Number of Runs with Do Not Investigate`,
  };

  /** @type {import('../types').TeamLegendMap} */
  const teamLegendMap = {
    teamNumberOfTestsInvestigated: (name) => `${name} | Number of Tests Investigated`,
    teamNumberOfRunsInvestigated: (name) => `${name} | Number of Runs Investigated`,
    teamNumberOfSuitesInvestigated: (name) => `${name} | Number of Suites Investigated`,
    teamAverageTestsInvestigated: (name) => `${name} | Average Number of Tests Investigated`,
    teamAverageRunsInvestigated: (name) => `${name} | Average Number of Runs Investigated`,
    teamAverageSuitesInvestigated: (name) => `${name} | Average Number of Suites Investigated`,
    teamMedianSuitesInvestigated: (name) => `${name} | Median Number of Suites Investigated`,
    teamAverageRunsPassingOnFix: (name) => `${name} | Average Number of Runs Passing on Fix`,
    teamAverageRunsPassingOnFlake: (name) => `${name} | Average Number of Runs Passing on Flake`,
    teamAverageRunsReportedAsBugs: (name) => `${name} | Average Number of Runs Reported as Bugs`,
    teamAverageRunsReportedAsMaintenance: (name) => `${name} | Average Number of Runs Reported as Maintenance`,
    teamAverageRunsWithDoNotInvestigate: (name) => `${name} | Average Number of Runs with Do Not Investigate`,
    teamNumberOfRunsPassingOnFix: (name) => `${name} | Number of Runs Passing on Fix`,
    teamNumberOfRunsPassingOnFlake: (name) => `${name} | Number of Runs Passing on Flake`,
    teamNumberOfRunsReportedAsBugs: (name) => `${name} | Number of Runs Reported as Bugs`,
    teamNumberOfRunsReportedAsMaintenance: (name) => `${name} | Number of Runs Reported as Maintenance`,
    teamNumberOfRunsWithDoNotInvestigate: (name) => `${name} | Number of Runs with Do Not Investigate`,
    teamMaxSuitesInvestigated: (name) => `${name} | Max Number of Suites Investigated`,
    teamMinSuitesInvestigated: (name) => `${name} | Min Number of Suites Investigated`,
  };

  /**
   * @param {import('../types').InvestigationData} investigationData
   * @returns {{name: string & { [key: string]: number }}[]}
   */
  const buildInvestigationChartData = (investigationData) => {
    // init the chart data
    const chartData = /** @type {{name: string & {[key: string]: number}}[]} */ ([]);

    // get all weeks
    const allWeeks = Object.keys(investigationData.teamAggregateData.weekly);

    // iterate through each week
    for (const week of allWeeks) {
      // init the weekly chart data
      const weeklyChartData = /** @type {{name: string & {[key: string]: number}}} */ ({ name: week });

      // iterate through each team member
      for (const teamMember of investigationData.team.members) {
        const email = teamMember.email;
        const name = teamMember.name;

        const memberWeekData = investigationData.teamAggregateData[email].weekly[week];

        // iterate through each insight
        for (const insight of Object.keys(memberWeekData)) {
          weeklyChartData[qaeLegendMap[insight](name)] = roundToTwo(memberWeekData[insight]) || 0;
        }
      }

      // add team aggregate data to weekly entry
      const teamWeekData = investigationData.teamAggregateData.weekly[week];
      for (const insight of Object.keys(teamWeekData)) {
        weeklyChartData[teamLegendMap[insight](teamName)] = roundToTwo(teamWeekData[insight]) || 0;
      }

      // add weekly entry to chart data
      chartData.push(weeklyChartData);
    }

    return chartData;
  };

  const chartData = buildInvestigationChartData(data);

  const sortedChartData = chartData.sort((a, b) => {
    const [yearA, weekA] = a.name.split(' ').map(Number);
    const [yearB, weekB] = b.name.split(' ').map(Number);

    if (yearA === yearB) {
      return weekB - weekA;
    } else {
      return yearB - yearA;
    }
  });

  const images = {};
  for (const member of data.team.members) {
    images[member.name] = member.avatar48;
  }
  images[teamName] = data.team.imageUrl;

  // set state with already manipulated data
  const [visibility, setVisibility] = useState(
    paramsMap.visibleLines && paramsMap.visibleLines.split(',').length
      ? _.mapValues(sortedChartData[0], (_, k) => paramsMap.visibleLines.split(',').includes(k))
      : _.mapValues(sortedChartData[0], (_, k) => k.includes('Creation - Tests')),
  );

  const [opacity, setOpacity] = useState(_.mapValues(sortedChartData[0], () => 1));

  const handleLegendEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = key === dataKey ? 1.5 : 0.3;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendLeave = useCallback(
    (o) => {
      const { dataKey } = o;

      // if the dataKey is not visible, do not change opacity
      if (!visibility[dataKey]) return;

      setOpacity((op) => {
        const newOpacity = { ...op };
        Object.keys(op).forEach((key) => {
          newOpacity[key] = 1;
        });
        return newOpacity;
      });
    },
    [visibility],
  );

  const handleLegendClick = useCallback((o) => {
    const { dataKey } = o;

    setVisibility((vis) => {
      const newVisibility = { ...vis, [dataKey]: !vis[dataKey] };
      return newVisibility;
    });
  }, []);

  // handles chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    // cleanup
    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, [parentRef.current]);

  useEffect(() => {
    const handleLegendResize = () => {
      if (legendRef.current) {
        setLegendHeight(legendRef.current.offsetHeight);
      }
    };

    const legendResizeObserver = new ResizeObserver(handleLegendResize);
    if (legendRef.current) {
      legendResizeObserver.observe(legendRef.current);
    }

    return () => {
      if (legendRef.current) {
        legendResizeObserver.unobserve(legendRef.current);
      }
    };
  }, [legendRef.current]);

  useEffect(() => {
    const visibleLines = Object.keys(visibility)
      .filter((key) => visibility[key])
      .join(',');
    searchParams.set('visibleLines', visibleLines);
    setSearchParams(searchParams);
  }, [visibility]);

  return (
    <div className="flex flex-col items-center justify-center border" ref={parentRef}>
      <LineChart height={550 + legendHeight} width={width - 10} data={sortedChartData.reverse()} margin={{ right: 40, top: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickMargin={5} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend wrapperStyle={{ textAlign: 'center', width: '100%' }} content={renderLegend} />
        {Object.keys(sortedChartData).length &&
          Object.keys(sortedChartData[0]).map((line, lineIdx) => {
            if (line === 'name') return null;
            return (
              <Line
                key={lineIdx}
                type="monotone"
                dataKey={line}
                dot={false}
                stroke={hslStringToRgbString(stringToColor(line))}
                strokeOpacity={line.includes(teamName) ? 1 : opacity[line]}
                strokeWidth={line.includes(teamName) ? 4 : opacity[line] === 1.5 ? 3 : 1}
                hide={!visibility[line]}
              />
            );
          })}
      </LineChart>
    </div>
  );
}
