import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`Total Failures: ${data.value}`}</p>
        <h4>Failures by Client and Trigger:</h4>
        {Object.entries(data.details).map(([client, triggers]) => (
          <div key={client}>
            <h5>{client}</h5>
            {Object.entries(triggers).map(([trigger, count]) => (
              <p key={trigger}>{`${trigger}: ${count}`}</p>
            ))}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const formatXAxis = (tickItem) => {
  const hour = parseInt(tickItem);
  if (hour === 0) return '12a';
  if (hour === 12) return '12p';
  return hour < 12 ? `${hour}a` : `${hour - 12}p`;
};

const LineChartInvestigation = ({ data }) => {
  return (
    <div style={{ width: '100%', height: 400, marginBottom: 20 }}>
      <h3>{data.day}</h3>
      <ResponsiveContainer>
        <LineChart
          data={data.data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="hourNum" tickFormatter={formatXAxis} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="value" name="Failures" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartInvestigation;
