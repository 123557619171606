import { IconButton, Snackbar, SnackbarProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export default function CustomSnackbar({ open, ...props }: { open: boolean } & SnackbarProps) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <Snackbar
      open={isOpen}
      onClose={() => setIsOpen(false)}
      message={props.message}
      ContentProps={{ sx: { bgcolor: 'white', color: 'black' }, ...props.ContentProps }}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      {...props}
    />
  );
}
