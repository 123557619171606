import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import WolfLoader from '../WolfLoader/WolfLoader';
import { sendGetRequest } from '../../utils/network';
import fetchInvestigationDataForMultipleClients from './Helpers/fetchInvestigationDataForMultipleClients';
import { groupBy } from 'lodash';
import LineChartInvestigation from './LineChart/LineChartInvestigation';
import { prepareLineChartData } from './Helpers/InvestigationCapacityHelpers';

const getPreviousMonthRange = () => {
  const today = dayjs();
  const startOfWeek = today.startOf('week').subtract(4, 'week');
  const endOfWeek = today.endOf('week').subtract(1, 'week');
  return {
    startDate: startOfWeek.format('YYYY-MM-DD'),
    endDate: endOfWeek.format('YYYY-MM-DD'),
  };
};

const InvestigationSimulation = () => {
  const localActiveTeams = JSON.parse(localStorage.getItem('teams')).filter((team) => team.active);
  const previousMonth = getPreviousMonthRange();

  const [filters] = useState({
    startDate: previousMonth.startDate,
    endDate: previousMonth.endDate,
  });

  const [allClients, setAllClients] = useState([]);
  const [selectedTeamClients, setSelectedTeamClients] = useState([]);

  useEffect(() => {
    (async () => {
      const rawClients = await sendGetRequest('/clients').catch((e) => {
        throw new Error(e);
      });

      const flattenedClients = Object.entries(rawClients.data).map(([key, value]) => ({ ...value, key }));
      setAllClients(flattenedClients);
    })();
  }, []);

  useEffect(() => {
    const teamClients = allClients.filter((client) =>
      localActiveTeams.some((team) => team.email === client.qaLead.email),
    );

    setSelectedTeamClients(teamClients);
  }, [allClients]);

  const customerIds = selectedTeamClients.map((client) => client.id);

  const {
    data: suiteData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['investigationDataByClients', customerIds, previousMonth.startDate, previousMonth.endDate],
    queryFn: () => fetchInvestigationDataForMultipleClients(customerIds, filters.startDate, filters.endDate),
    enabled: !!customerIds.length,
  });

  const simulateComingWeek = (historicalData) => {
    if (!historicalData || historicalData.length === 0) {
      return []; // Return an empty array if there's no historical data
    }

    const today = dayjs();
    const nextMonday = today.startOf('week').add(1, 'week');
    
    const groupedData = groupBy(historicalData, (suite) => {
      const isPRTrigger = /pr (deploy|test)/i.test(suite.triggerName);
      const triggerName = isPRTrigger ? 'PR Trigger' : suite.triggerName;
      return `${suite.customerId}-${triggerName}`;
    });

    const simulatedData = Object.entries(groupedData).flatMap(([key, suites]) => {
      const [customerId, triggerName] = key.split('-');
      
      return [1, 2, 3, 4, 5, 6].flatMap((dayOffset) => {
        const simulatedDate = nextMonday.add(dayOffset - 1, 'day');
        const dayOfWeek = simulatedDate.day();
        
        // Filter suites that ran on the same day of the week
        const relevantSuites = suites.filter((suite) => dayjs(suite.startTime).day() === dayOfWeek);
        
        if (relevantSuites.length === 0) return []; // No data for this day of week
        
        // Group suites by their start hour to simulate multiple occurrences per day
        const groupedByHour = groupBy(relevantSuites, (suite) => dayjs(suite.startTime).hour());
        
        return Object.entries(groupedByHour).map(([hour, hourSuites]) => {
          if (!Array.isArray(hourSuites) || hourSuites.length === 0) {
            console.warn(`Invalid hourSuites for hour ${hour}:`, hourSuites);
            return null;
          }

          const averageFailures = hourSuites.reduce((sum, suite) => 
            sum + (suite.numberOfRuns - suite.numberOfRunsPassingOnFirstAttempt), 0,
          ) / hourSuites.length;
          
          const roundedFailures = Math.round(averageFailures);
          const templateSuite = hourSuites[hourSuites.length - 1];
          
          // Get the time from the template suite
          const templateTime = dayjs(templateSuite.startTime);
          
          // Combine the simulated date with the template time
          const simulatedDateTime = simulatedDate
            .hour(templateTime.hour())
            .minute(templateTime.minute())
            .second(templateTime.second());
          
          return {
            ...templateSuite,
            id: `simulated-${customerId}-${triggerName}-${simulatedDateTime.format('YYYY-MM-DD-HH-mm-ss')}`,
            startTime: simulatedDateTime.toISOString(),
            triggerName: triggerName,
            numberOfRuns: roundedFailures + templateSuite.numberOfRunsPassingOnFirstAttempt,
            numberOfRunsPassingOnFirstAttempt: templateSuite.numberOfRunsPassingOnFirstAttempt,
            numberOfRunsPassingOnSecondAttempt: Math.floor(roundedFailures * 0.6),
            numberOfRunsPassingOnThirdAttempt: Math.floor(roundedFailures * 0.3),
            numberOfRunsTriagedAsBug: Math.floor(roundedFailures * 0.1),
          };
        }).filter(Boolean); // Remove any null entries
      });
    });

    return simulatedData.flat();
  };

  if (isLoading) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const simulatedSuiteData = simulateComingWeek(suiteData);

  const lineChartData = prepareLineChartData(simulatedSuiteData, 'America/Los_Angeles');

  if (simulatedSuiteData.length === 0) {
    return <WolfLoader />;
  } else {
    return (
      <div>
        {lineChartData.map((dayData, index) => (
          <LineChartInvestigation key={index} data={dayData} />
        ))}
      </div>
    );
  }
};

export default InvestigationSimulation;
