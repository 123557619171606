import _ from 'lodash';

import { classNames } from '../../InvestigationBoard/helpers';
import { getDisplayName } from '../helpers';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { DataKey } from 'recharts/types/util/types';

type LegendForCreationOutlineMaintenanceTaskChartsProps = {
  payload: (Payload & { dataKey: DataKey<string> })[];
  leadName: string;
  images: Record<string, string>;
  visibility: Record<string, boolean>;
  clickFunction: (entry: Payload & { dataKey: DataKey<string> }) => void;
  enterFunction: (entry: Payload & { dataKey: DataKey<string> }) => void;
  leaveFunction: (entry: Payload & { dataKey: DataKey<string> }) => void;
};

export default function LegendForCreationOutlineMaintenanceTaskCharts({
  payload,
  leadName,
  images,
  visibility,
  clickFunction,
  enterFunction,
  leaveFunction,
}: LegendForCreationOutlineMaintenanceTaskChartsProps) {
  const legendGroups = _.groupBy(payload, (entry) => entry.value.split(' | ')[0]);

  return (
    <div className="flex justify-between">
      <div
        className={classNames(
          'grid justify-between gap-2 p-2 text-sm',
          Object.keys(legendGroups).length >= 6 ? 'grid-cols-6' : `grid-cols-${Object.keys(legendGroups).length}`,
        )}
      >
        {Object.keys(legendGroups)
          .sort((a, b) => {
            if (a === 'Team') return 1; // Put team legend card at the end
            if (b === 'Team') return -1;

            if (a === leadName) return 1; // Put lead legend card second to last
            if (b === leadName) return -1;

            return a.localeCompare(b);
          })
          .map((group, groupIdx) => {
            const groupEntries = legendGroups[group];
            const displayName = getDisplayName(group);
            // determine image based on data type
            return (
              <div className="flex flex-col shadow p-2" key={groupIdx}>
                <p className="flex items-center gap-x-4">
                  <img className="rounded-full h-10 w-auto" src={images[group]} alt="avatar" />
                  <span className="font-semibold">{displayName}</span>
                </p>
                <ul>
                  {groupEntries.map((entry, entryIdx) => {
                    return (
                      <li key={entryIdx} className={classNames('flex justify-start my-1 truncate', !visibility[entry.value] && 'text-gray-400')}>
                        <button
                          className="flex items-center"
                          onClick={() => clickFunction(entry)}
                          onMouseEnter={() => enterFunction(entry)}
                          onMouseLeave={() => leaveFunction(entry)}
                        >
                          <span
                            className="h-3.5 w-3.5 mr-2 rounded-full"
                            style={{ backgroundColor: visibility[entry.value] ? entry.color : '#ccc' }}
                          />
                          <span className="truncate">{entry.value.match(/^[^|]*\| (.*)/)[1]}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </div>
    </div>
  );
}
