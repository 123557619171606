import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';

import { ResponsiveContainer } from 'recharts';

import WolfLoader from '../../WolfLoader/WolfLoader';
import { WeeklyInvestigationChart } from '../charts';
import { InvestigationTasksTable, WeeklyInvestigationComparisonTable } from '../tables';

import {
  computeAverageAndMedianAssistedInvestigationTasksPerWeek,
  computeInvestigationAggregatesByWeek,
  getInvestigationInsights,
  protectFilters,
} from '../helpers';

export default function TeamInvestigationView({ filters }) {
  // protect team filters from potential stale state
  filters = protectFilters(filters);

  const {
    data: investigationData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['investigationData', filters],
    queryFn: () => getInvestigationInsights(filters),
  });

  if (isPending) return <WolfLoader />;
  if (isError) return <span>Error: {error.message}</span>;

  const aggregatedTeamInvestigationDataGroupedByWeek = computeInvestigationAggregatesByWeek(
    { primaryData: investigationData.teamInvestigationDataGroupedByWeek, assistedData: investigationData.teamAssistedInvestigationDataGroupedByWeek },
    true,
  );

  const teamAverageAssistedInvestigationDataGroupedByWeek = computeAverageAndMedianAssistedInvestigationTasksPerWeek(
    investigationData.teamAssistedInvestigationDataGroupedByWeek,
    investigationData.team.members,
  );

  const allWeeks = Object.keys(investigationData.teamAggregateData.weekly);

  return (
    <div className="min-h-full px-4 py-2 flex-col rounded-lg shadow">
      <div className="flex space-x-4 py-4 items-center justify-start">
        <div className="flex flex-col justify-center items-center pl-4">
          <img src={investigationData.team.imageUrl} alt="avatar" className="h-14 w-14 rounded-full" />
          <span className="font-semibold">{investigationData.team.name}</span>
        </div>
      </div>
      <div className="isolate flex-col border">
        {/* Team "At A Glance" Header */}
        <div className="isolate grid grid-cols-2">
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Suites Investigated: {investigationData.teamAggregateData.totals.teamNumberOfSuitesInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Suites Investigated: {(investigationData.teamAggregateData.totals.teamNumberOfSuitesInvestigated / allWeeks.length).toFixed(2)}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Failures Investigated: {investigationData.teamAggregateData.totals.teamNumberOfRunsInvestigated}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Failures Investigated: {(investigationData.teamAggregateData.totals.teamNumberOfRunsInvestigated / allWeeks.length).toFixed(2)}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b border-r">
            Team Total Bug Failures: {investigationData.teamAggregateData.totals.teamNumberOfRunsReportedAsBugs}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-b">
            Team Average Investigation Time: {_.meanBy(_.values(aggregatedTeamInvestigationDataGroupedByWeek), 'avgInvestigationTime').toFixed(2)}{' '}
            minutes
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium border-r">
            Team Suites Assisted: {_.sumBy(_.values(investigationData.teamAssistedInvestigationDataGroupedByWeek), 'length')}
          </span>
          <span className="group relative min-w-0 flex-1 overflow-hidden p-2 text-center text-sm font-medium">
            Team Average Suites Assisted: {_.meanBy(_.values(teamAverageAssistedInvestigationDataGroupedByWeek), 'mean').toFixed(2)}
          </span>
        </div>
      </div>
      {investigationData && (
        <ResponsiveContainer width="100%" height="100%">
          <WeeklyInvestigationChart data={investigationData} />
        </ResponsiveContainer>
      )}
      <div className="border mb-8 p-4 grid 3xl:grid-cols-2 6xl:grid-cols-3 grid-flow-row gap-4">
        {investigationData.team &&
          investigationData.team.members
            .filter((member) => !member.isManager)
            .sort((a, b) => {
              if (a.isLead) return 1;
              if (b.isLead) return -1;
              return a.name.localeCompare(b.name);
            })
            .map((member, memberIndex) => {
              // get weekly and total aggregates for the qae
              const qaeAggregateData = investigationData.teamAggregateData[member.email];

              // get weekly and total aggregates for the team
              const teamAggregateData = {
                weekly: investigationData.teamAggregateData.weekly,
                totals: investigationData.teamAggregateData.totals,
              };

              return (
                <div key={memberIndex} className="shadow">
                  <WeeklyInvestigationComparisonTable
                    qaeData={qaeAggregateData}
                    teamData={teamAggregateData}
                    targetQAE={member}
                    targetTeam={investigationData.team}
                  />
                </div>
              );
            })}
      </div>
      <div className="border mb-8 p-4 grid">
        <InvestigationTasksTable investigationTasks={investigationData.teamAggregateData.teamRawSuites} team={investigationData.team} />
      </div>
    </div>
  );
}
