import { Accordion, AccordionDetails, AccordionSummary, Card, List, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { plural } from '../../../InvestigationBoard/helpers';
import { CardItem } from './subComponents';
import { Milestone } from '../types';
dayjs.extend(LocalizedFormat);

export function DataCard({ milestone, requiredVelocity }: { milestone: Milestone; requiredVelocity: number }) {
  const totalTestsCompleted = milestone.subMilestones.at(-1)?.actualTests || 0;
  const remainingTests = milestone.targetTestCount - totalTestsCompleted;
  const activatedStr = `${totalTestsCompleted} Activated`;
  const remainingStr = remainingTests > 0 ? `, ${remainingTests} Remaining` : ` ✅`;

  return (
    <Card variant="elevation" sx={{ mb: '2em', mt: '1em' }}>
      <List>
        <CardItem name="Name" value={milestone.name} />
        <CardItem name="Start Date" value={dayjs(milestone.startDate).format('LL')} />
        <CardItem name="External Due Date" value={dayjs(milestone.externalDueDate).format('LL')} />
        <CardItem name="Expected Starting Test Count" value={milestone.previousMilestone?.targetTestCount || milestone.initialTestCount} />
        <CardItem name="Actual Starting Test Count" value={milestone.initialTestCount} />
        <CardItem name="Target Active Test Count" value={milestone.targetTestCount} />
        <CardItem name="Current Progress" value={activatedStr + remainingStr} />
        <CardItem name="Planned Velocity" value={`${milestone.testVelocity} Test${plural(milestone.testVelocity)} per week`} />
        <CardItem
          name="Required Velocity"
          value={requiredVelocity === Infinity ? 'N/A' : `${Math.max(requiredVelocity, 0)} Test${plural(requiredVelocity)} per week`}
        />
      </List>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle2">Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">{milestone.notes}</Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
